import { createMuiTheme } from "@material-ui/core/styles";

// mui-datatable: table theme
export const globalTableTheme = createMuiTheme({
    overrides: {
        MUIDataTableBodyCell: {
            root: {
                color: "#333333",
                backgroundColor: "#FFFFFF",
                whiteSpace: "nowrap",
                padding: "0px 0px 0px 6px"
            },
        },
        MUIDataTableHeadCell: {
            fixedHeader: {
                color: "#FFFFFF",
                backgroundColor: "#117893",
                whiteSpace: "nowrap"
            },
            sortActive: {
                color: "#FFFFFF",
                backgroundColor: "#117893",
                whiteSpace: "nowrap"
            },
            sortAction: {
                '& path': {
                    fill: 'lightgreen'
                },
            }
        },
        MuiTableCell: {
            footer: {
                color: "#000000",
                backgroundColor: "#FFFFFF"
            }
        }
    },
});

// mui-datatable: table options
export const globalTableOptions = {
    filterType: 'multiselect',
    selectableRows: 'none',
    // rowHover: true,
    // responsive: 'standard',
    // responsive: "scrollFullHeight",
    // resizableColumns: true,
    draggableColumns: {
        enabled: true
    },
    fixedHeader: true,
    tableBodyHeight: '500px',
    rowsPerPageOptions: [10, 20, 50, 100]
};
