// ---- Employment Types -----
export const employmentTypes = [
    {CODE:"Permanent", DESC:"Permanent"},
    {CODE:"Temporary", DESC:"Temporary"},
    {CODE:"Trainee", DESC:"Trainee"},
    {CODE:"Apprentice", DESC:"Apprentice"},
];

// ---- Marital Status -----
export const maritalStatus = [
    {CODE:"Unmarried", DESC:"Unmarried"},
    {CODE:"Married", DESC:"Married"},
    {CODE:"Divorcee", DESC:"Divorcee"},
    {CODE:"Not to say", DESC:"Not to say"},
];

// ---- Gender -----
export const gender = [
    {CODE:"Male", DESC:"Male"},
    {CODE:"Female", DESC:"Female"},
    {CODE:"Not to say", DESC:"Not to say"},
];

