import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { useLazyQuery } from '@apollo/client';
import { Button, FormControl, FormHelperText, Grid, IconButton, Paper, Snackbar, TextField, Tooltip } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { POPULATE_SITES_DDL, SEARCH_TOTAL_AMOUNT_SPENT } from "../../../../graphql/queries";
import { globalTableOptions, globalTableTheme } from "../../../../theme";
import Loader from "../../../../components/Loader";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginBottom: '10px'
    },
    fixedHeight: {
        height: 240,
    },
    formControl: {
        //margin: theme.spacing(1),
        display: 'flex',
        minWidth: 120,
        margin: 0
    },
}));

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AmountSpentHeader = (props) => {
    const classes = useStyles();

    const item = props.item;
    const [siteID, setSiteID] = useState(item?.siteID);
    const [entryFromDate, setEntryFromDate] = useState(moment().subtract(1, 'months'));
    const [entryToDate, setEntryToDate] = useState(moment());
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [siteError, setSiteError] = useState(false);
    const [siteErrorText, setSiteErrorText] = useState("");

    // apollo queries and mutations
    const [getEntryList, { loading, data, error }] = useLazyQuery(SEARCH_TOTAL_AMOUNT_SPENT);
    const [populateSitesDDL, { data: sitesDDL }] = useLazyQuery(POPULATE_SITES_DDL);

    // inital effect
    useEffect(() => {
        //console.log('Calling useEffect()....');
        populateSitesDDL({
            variables: {
                siteType: "%",
            }
        });

        if (item) {
            setSiteID(item.siteID);
            setEntryFromDate(item.fromDate);
            setEntryToDate(item.toDate);
        }

        getEntryList({
            variables: {
                siteID: siteID?.CODE,
                fromDate: entryFromDate.format("YYYYMMDD"),
                toDate: entryToDate.format("YYYYMMDD")
            }
        });
    }, []);

    // search entries
    const searchEntries = () => {
        //console.log('Calling searchEntries()....');
        if (!siteID) {
            setSiteError(true);
            setSiteErrorText("Please select site/plant");
            return;
        }

        getEntryList({
            variables: {
                siteID: siteID?.CODE,
                fromDate: entryFromDate?.format("YYYYMMDD") || moment().subtract(1, 'months').format("YYYYMMDD"),
                toDate: entryToDate?.format("YYYYMMDD") || moment().format("YYYYMMDD"),
            }
        });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    };

    if (error) {
        setSnackbarSeverity("error");
        setSnackbarMessage(error);
        setOpenSnackbar(true);
    };

    const tableColumns = [
        {
            name: "SITE_ID",
            label: "Site ID",
            options: {
                filter: false,
                sort: true,
                display: false
            }
        }, {
            name: "SITE_NAME",
            label: "Site Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "ITEM_NAME",
            label: "Item",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "TOTAL_AMOUNT",
            label: "Total Amount",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "ACTIONS",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const tableData = tableMeta.tableData;
                    const rowIndex = tableMeta.rowIndex;
                    // console.log('Selcted tableMeta: ', tableMeta);
                    return <>
                        <Tooltip title="View Details">
                            <IconButton
                                style={{ color: "#17A2B8" }}
                                onClick={(event) => {
                                    props.handleTabChange(event, 2, {
                                        'siteID': siteID,
                                        'itemID': tableData[rowIndex].ITEM_ID,
                                        'fromDate': entryFromDate,
                                        'toDate': entryToDate
                                    });
                                }}
                            >
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            }
        },
    ];

    let tableData = [];
    if (data) {
        //console.log('Entries: ', data.result);
        tableData = data.result;
    }

    const tableOptions = {
        ...globalTableOptions,
        downloadOptions: {
            filename: "total-amount-spent.csv"
        },
        customTableBodyFooterRender: (opts) => {
            // console.log('[Arrival] customTableBodyFooterRender:>> ', opts?.data);
            return <TableFooter>
                <TableRow style={{ whiteSpace: "nowrap",}}>
                    <TableCell
                        colSpan={2}
                        style={{
                            position: "sticky",
                            left: "0",
                            zIndex: 101,
                        }}
                    >
                        <Typography variant="subtitle2" >
                            TOTAL
                        </Typography>
                    </TableCell>
                    <TableCell colSpan={2}>
                        <Typography variant="subtitle2">
                            {"₹ " + opts?.data.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.data[3]), 0).toFixed(2)}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableFooter>;
        }
    };

    return <div style={{ position: "relative" }}>
        {/* laoder */}
        {(loading) && <Loader />}

        {/* search container */}
        <Paper variant="elevation" elevation={3} className={classes.paper}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <FormControl className={classes.formControl} error={siteError}>
                        <Autocomplete
                            style={{ marginTop: "-16px" }}
                            id="search_site"
                            autoComplete
                            fullWidth
                            options={sitesDDL?.sites || []}
                            getOptionLabel={option => option.DESC ? option.DESC : ""}
                            renderInput={(params) => (
                                <TextField required {...params} label="Site/Plant" margin="normal" />
                            )}
                            renderOption={(option, { inputValue }) => {
                                const matches = match(option.DESC, inputValue);
                                const parts = parse(option.DESC, matches);
                                return (
                                    <div>
                                        {parts.map((part, index) => (
                                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                );
                            }}
                            value={siteID || {}}
                            onChange={(event, newValue) => {
                                setSiteError(false);
                                setSiteErrorText("");
                                setSiteID(newValue);
                            }}
                        />
                        <FormHelperText>{siteErrorText}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            required
                            id="entry-from-date"
                            // placeholder="05/11/2020"
                            label="From Date"
                            value={entryFromDate}
                            onChange={date => setEntryFromDate(date)}
                            format="DD/MM/yyyy"
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            required
                            id="entry-to-date"
                            //placeholder="05/11/2020"
                            label="To Date"
                            value={entryToDate}
                            onChange={date => setEntryToDate(date)}
                            format="DD/MM/yyyy"
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={searchEntries}
                        style={{ marginTop: "10px" }}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>
        </Paper>

        {/* data table */}
        <MuiThemeProvider theme={globalTableTheme}>
            <MUIDataTable
                title={"Spend Analysis"}
                data={tableData}
                columns={tableColumns}
                options={tableOptions}
            />
        </MuiThemeProvider>

        {/* snackbar */}
        <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleSnackbarClose}
        >
            <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </div>
}


export default AmountSpentHeader;