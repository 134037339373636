import React, { useEffect, useState } from "react";

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { IconButton, Paper } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { POPULATE_EMPLOYEE_DDL, SEARCH_EMPLOYEES } from "../../../../graphql/queries";
import { LOCAL_SIGN_UP, UPDATE_USER_PROFILE } from "../../../../graphql/mutations";
import { checkNull, checkMaxLength, checkEmail, checkMobileNumber } from '../../../../util/validations';
import Loader from "../../../../components/Loader";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        margin: '10px'
    },
    formControl: {
        // margin: theme.spacing(1),
        display: 'flex',
        minWidth: 120,
        margin: 0
    },
    input: {
        display: 'none',
    },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validateUserRegistration = async (Users) => {
    let validationObjects = {};
    for (let i = 0; i < Users.length; i++) {
        let validationObject = {};

        if (Users[i].transaction === "CREATE")
            checkNull("PASSWORD", Users[i].PASSWORD, "Password is required", validationObject);

        if (Users[i].transaction === "UPDATE")
            checkNull("USER_ID", Users[i].USER_ID, "Users Id is required", validationObject);

        checkNull("FIRST_NAME", Users[i].FIRST_NAME, "First name is required", validationObject);
        checkNull("EMAIL", Users[i].EMAIL, "Email is required", validationObject);
        checkNull("MOBILE_NUMBER", Users[i].MOBILE_NUMBER, "Mobile number is required", validationObject);
        if (Object.keys(Users[i].USER_PERMISSIONS).length === 0)
            checkNull("USER_PERMISSIONS", null, "Please select user permissions", validationObject);
        if (Users[i].USER_ROLES.length === 0)
            checkNull("USER_ROLES", null, "Please select user roles", validationObject);

        Users[i].USER_PERMISSIONS = JSON.stringify(Users[i].USER_PERMISSIONS);
        Users[i].USER_ROLES = JSON.stringify(Users[i].USER_ROLES);
        checkMaxLength("FIRST_NAME", Users[i].FIRST_NAME, 100, "Length of first name should be less than or equal to 100 characters", validationObject);
        checkMaxLength("LAST_NAME", Users[i].LAST_NAME, 100, "Length of last name should be less than or equal to 100 characters", validationObject);
        checkMaxLength("EMAIL", Users[i].EMAIL, 200, "Length of email should be less than or equal to 200 characters", validationObject);
        checkMaxLength("USER_ROLES", Users[i].USER_ROLES, 200, "Length of user roles should be less than or equal to 200 characters", validationObject);
        checkMaxLength("USER_PERMISSIONS", Users[i].USER_PERMISSIONS, 100, "Length of user type should be less than or equal to 100 characters", validationObject);

        checkEmail("EMAIL", Users[i].EMAIL, "Email is not valid", validationObject);
        checkMobileNumber("MOBILE_NUMBER", Users[i].MOBILE_NUMBER, "Mobile number is not valid", validationObject);

        if (Object.keys(validationObject).length !== 0)
            validationObjects[i] = validationObject;
    }

    return validationObjects;
}

const UserForm = (props) => {
    const classes = useStyles();

    const { formDialogOpen, handleFormDialogClose, handleLocalUserOps, transaction, user } = props;
    const [userState, setUserState] = useState(null);
    const [userErrors, setUserErrors] = useState(null);
    const [saving, setSaving] = useState(false);
    const [showEmployee, setShowEmployee] = useState(false);
    const [employeeID, setEmployeeID] = useState(null);
    const [updating, setUpdating] = useState(false);

    const [LocalSignup] = useMutation(LOCAL_SIGN_UP);
    const [UpdateUserProfile] = useMutation(UPDATE_USER_PROFILE);
    const { loading: ddlLoading, error: ddlError, data: ddlData } = useQuery(POPULATE_EMPLOYEE_DDL);
    const [getEmployeeDetails, { loading: employeeLoading, data: employeeData, error: employeeError }] = useLazyQuery(SEARCH_EMPLOYEES);

    useEffect(() => {
        setEmployeeID(null);
        setShowEmployee(false);
        setUserErrors(null);
        if (user) {
            setUpdating(true);
            setUserState({
                USER_ID: user.USER_ID,
                FIRST_NAME: user.FIRST_NAME,
                LAST_NAME: user.LAST_NAME,
                EMAIL: user.EMAIL,
                MOBILE_NUMBER: user.MOBILE_NUMBER,
                PASSWORD: user.PASSWORD,
                USER_ROLES: JSON.parse(user.USER_ROLES) || [],
                USER_PERMISSIONS: JSON.parse(user.USER_PERMISSIONS) || {},
            });
        }
        else {
            setUpdating(false);
            setUserState({
                USER_ROLES: [],
                USER_PERMISSIONS: {}
            });
        }
    }, [props]);

    useEffect(() => {
        if (employeeID) {
            getEmployeeDetails({
                variables: {
                    employeeId: employeeID?.CODE
                }
            });

            if (employeeData) {
                const emp = employeeData.result[0];

                setUserState({
                    FIRST_NAME: emp?.FIRST_NAME,
                    LAST_NAME: emp?.LAST_NAME,
                    EMAIL: emp?.EMAIL,
                    MOBILE_NUMBER: emp?.MOBILE_NUMBER,
                    USER_ROLES: [],
                    USER_PERMISSIONS: {}
                });
            }
        }

    }, [employeeID, employeeData]);

    const setUserValidationErrors = (errors) => {
        for (let key in errors) {
            // set Error state;
            setUserErrors({
                FIRST_NAME: errors[key].errorFIRST_NAME,
                LAST_NAME: errors[key].errorLAST_NAME,
                EMAIL: errors[key].errorEMAIL,
                MOBILE_NUMBER: errors[key].errorMOBILE_NUMBER,
                PASSWORD: errors[key].errorPASSWORD,
                USER_ROLES: errors[key].errorUSER_ROLES,
                USER_PERMISSIONS: errors[key].errorUSER_PERMISSIONS,
            });
        }
    }

    const saveUser = async () => {
        // console.log('[UserForm] user state: ', userState);

        setSaving(true);
        const checkUserState = { ...userState, transaction: transaction };
        const validationErrors = await validateUserRegistration([{ ...checkUserState }]);

        if (Object.keys(validationErrors).length !== 0) {
            setUserValidationErrors(validationErrors);
            setSaving(false);
        }
        else {
            if (transaction === 'CREATE')
                LocalSignup({
                    variables: {
                        "user": {
                            "FIRST_NAME": userState.FIRST_NAME,
                            "LAST_NAME": userState.LAST_NAME,
                            "EMAIL": userState.EMAIL,
                            "MOBILE_NUMBER": userState.MOBILE_NUMBER,
                            "PASSWORD": userState.PASSWORD,
                            "USER_ROLES": userState.USER_ROLES,
                            "USER_PERMISSIONS": userState.USER_PERMISSIONS
                        }
                    }
                })
                    .then(resp => {
                        //const result = resp.data.result;
                        //console.log('[LocalSignup] Resp: ', result);
                        setSaving(false);
                        handleLocalUserOps();
                    })
                    .catch(error => {
                        console.log('[LocalSignup] Error:', error.message);
                        setSaving(false);
                        setUserErrors(JSON.parse(error));
                    });
            else if (transaction === 'UPDATE')
                UpdateUserProfile({
                    variables: {
                        "user": {
                            "FIRST_NAME": userState.FIRST_NAME,
                            "LAST_NAME": userState.LAST_NAME,
                            "EMAIL": userState.EMAIL,
                            "MOBILE_NUMBER": userState.MOBILE_NUMBER,
                            "USER_ROLES": userState.USER_ROLES,
                            "USER_PERMISSIONS": userState.USER_PERMISSIONS
                        }
                    }
                })
                    .then(resp => {
                        //const result = resp.data.result;
                        //console.log('[LocalSignup] Resp: ', result);
                        setSaving(false);
                        handleLocalUserOps();
                    })
                    .catch(error => {
                        console.log('[UpdateUserProfile] Error:', error.message);
                        setSaving(false);
                        setUserErrors({ EMAIL: error.message });
                    });
        }
    }

    return <Dialog open={formDialogOpen} onClose={handleFormDialogClose} TransitionComponent={Transition}>
        <div style={{ position: "relative" }}>
            {(saving) && <Loader />}
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleFormDialogClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {transaction === 'CREATE' ? "Add New User" : "Update User"}
                    </Typography>
                    <Button autoFocus color="secondary" variant="contained" onClick={saveUser}>
                        save
                </Button>
                </Toolbar>
            </AppBar>

            {!updating && <Paper variant="elevation" elevation={3} className={classes.paper}>
                <Grid container spacing={3} style={{ overflow: 'hidden' }}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            style={{ marginTop: "10px" }}
                            onClick={() => setShowEmployee(!showEmployee)}
                        >
                            {showEmployee ? "Not for now" : "Add user from employee"}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {showEmployee && <FormControl className={classes.formControl} >
                            <Autocomplete
                                style={{ marginTop: "-20px" }}
                                id="EMPLOYEE_ID"
                                autoComplete
                                fullWidth
                                loading={ddlLoading}
                                options={ddlData?.employees}
                                getOptionLabel={option => option.DESC ? option.DESC : ""}
                                renderInput={(params) => (
                                    <TextField required {...params} label="Employee" margin="normal" />
                                )}
                                renderOption={(option, { inputValue }) => {
                                    const matches = match(option.DESC, inputValue);
                                    const parts = parse(option.DESC, matches);
                                    return (
                                        <div>
                                            {parts.map((part, index) => (
                                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    );
                                }}
                                value={employeeID || {}}
                                onChange={(event, newValue) => setEmployeeID(newValue)}
                            />
                        </FormControl>}
                    </Grid>
                </Grid>
            </Paper>}


            <Paper variant="elevation" elevation={3} className={classes.paper}>
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="FIRST_NAME"
                            name="FIRST_NAME"
                            label="First name"
                            fullWidth
                            autoComplete="first-name"
                            error={userErrors?.FIRST_NAME ? true : false}
                            helperText={userErrors?.FIRST_NAME}
                            value={userState?.FIRST_NAME}
                            onChange={(event) => {
                                setUserState({ ...userState, FIRST_NAME: event.target.value });
                                if (event.target.value.trim().length !== 0)
                                    setUserErrors({ ...userErrors, FIRST_NAME: null });
                                else
                                    setUserErrors({ ...userErrors, FIRST_NAME: "First name is required" });
                            }}
                            InputLabelProps={{ shrink: userState?.FIRST_NAME && true }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="LAST_NAME"
                            name="LAST_NAME"
                            label="Last name"
                            fullWidth
                            autoComplete="LAST_NAME"
                            error={userErrors?.LAST_NAME ? true : false}
                            helperText={userErrors?.LAST_NAME}
                            value={userState?.LAST_NAME}
                            onChange={(event) => setUserState({ ...userState, LAST_NAME: event.target.value })}
                            InputLabelProps={{ shrink: userState?.LAST_NAME && true }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="EMAIL"
                            name="EMAIL"
                            label="Email"
                            required
                            fullWidth
                            disabled={transaction === 'UPDATE' ? true : false}
                            autoComplete="EMAIL"
                            error={userErrors?.EMAIL ? true : false}
                            helperText={userErrors?.EMAIL}
                            value={userState?.EMAIL}
                            onChange={(event) => {
                                setUserState({ ...userState, EMAIL: event.target.value });
                                if (event.target.value.trim().length !== 0)
                                    setUserErrors({ ...userErrors, EMAIL: null });
                                else
                                    setUserErrors({ ...userErrors, EMAIL: "Email is required" });
                            }}
                            InputLabelProps={{ shrink: userState?.EMAIL && true }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="MOBILE_NUMBER"
                            name="MOBILE_NUMBER"
                            label="Mobile no."
                            fullWidth
                            autoComplete="MOBILE_NUMBER"
                            error={userErrors?.MOBILE_NUMBER ? true : false}
                            helperText={userErrors?.MOBILE_NUMBER}
                            value={userState?.MOBILE_NUMBER}
                            onChange={(event) => {
                                setUserState({ ...userState, MOBILE_NUMBER: event.target.value });
                                if (event.target.value.trim().length !== 0)
                                    setUserErrors({ ...userErrors, MOBILE_NUMBER: null });
                                else
                                    setUserErrors({ ...userErrors, MOBILE_NUMBER: "Mobile no. is required" });
                            }}
                            InputLabelProps={{ shrink: userState?.MOBILE_NUMBER && true }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        {transaction === 'CREATE'
                            ? <TextField
                                id="PASSWORD"
                                name="PASSWORD"
                                label="Password"
                                type="password"
                                required
                                fullWidth
                                autoComplete="PASSWORD"
                                error={userErrors?.PASSWORD ? true : false}
                                helperText={userErrors?.PASSWORD}
                                value={userState?.PASSWORD}
                                onChange={(event) => {
                                    setUserState({ ...userState, PASSWORD: event.target.value });
                                    if (event.target.value.trim().length !== 0)
                                        setUserErrors({ ...userErrors, PASSWORD: null })
                                    else
                                        setUserErrors({ ...userErrors, PASSWORD: "Password is required" })
                                }}
                            />
                            : null}
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl required error={userErrors?.USER_ROLES ? true : false} component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">User Roles</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={userState?.USER_ROLES.includes('Admin')}
                                        name="admin"
                                        onChange={(event) => {
                                            let updatedRoles = [...userState?.USER_ROLES];
                                            if (event.target.checked)
                                                updatedRoles.push("Admin");
                                            else
                                                updatedRoles = updatedRoles.filter(role => role !== "Admin");
                                            setUserState({ ...userState, USER_ROLES: updatedRoles });
                                        }}
                                    />}
                                    label="Admin"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={userState?.USER_ROLES.includes('User')}
                                        name="user"
                                        onChange={(event) => {
                                            let updatedRoles = [...userState?.USER_ROLES];
                                            if (event.target.checked)
                                                updatedRoles.push("User");
                                            else
                                                updatedRoles = updatedRoles.filter(role => role !== "User");
                                            setUserState({ ...userState, USER_ROLES: updatedRoles });
                                        }}
                                    />}
                                    label="User"
                                />
                            </FormGroup>
                            <FormHelperText>{userErrors?.USER_ROLES}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <FormControl required error={userErrors?.USER_PERMISSIONS ? true : false} component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">User Permissions</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={userState?.USER_PERMISSIONS?.CREATE}
                                        name="create-permission"
                                        onChange={(event) => {
                                            const updatedPermissions = { ...userState?.USER_PERMISSIONS, CREATE: event.target.checked };
                                            setUserState({ ...userState, USER_PERMISSIONS: updatedPermissions });
                                        }}
                                    />}
                                    label="Create"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={userState?.USER_PERMISSIONS?.RETRIEVE}
                                        name="retrieve-permission"
                                        onChange={(event) => {
                                            const updatedPermissions = { ...userState?.USER_PERMISSIONS, RETRIEVE: event.target.checked };
                                            setUserState({ ...userState, USER_PERMISSIONS: updatedPermissions });
                                        }}
                                    />}
                                    label="Retrieve"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={userState?.USER_PERMISSIONS?.UPDATE}
                                        name="update-permission"
                                        onChange={(event) => {
                                            const updatedPermissions = { ...userState?.USER_PERMISSIONS, UPDATE: event.target.checked };
                                            setUserState({ ...userState, USER_PERMISSIONS: updatedPermissions });
                                        }}
                                    />}
                                    label="Update"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={userState?.USER_PERMISSIONS?.DELETE}
                                        name="delete-permission"
                                        onChange={(event) => {
                                            const updatedPermissions = { ...userState?.USER_PERMISSIONS, DELETE: event.target.checked };
                                            setUserState({ ...userState, USER_PERMISSIONS: updatedPermissions });
                                        }}
                                    />}
                                    label="Delete"
                                />
                            </FormGroup>
                            <FormHelperText>{userErrors?.USER_PERMISSIONS}</FormHelperText>
                        </FormControl>
                    </Grid>

                </Grid>
            </Paper>
        </div>
    </Dialog>
}

export default UserForm;