import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import jwt from "jsonwebtoken";
import { useMutation } from '@apollo/client';
import { CircularProgress } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import { LOCAL_SIGN_IN } from "../../graphql/mutations";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://chintamaniinfra.com/">
                Chintamani Infra
            </Link>{' '}
            {new Date().getFullYear()}
            {'. | Made with '}
            <i className="material-icons" style={{ color: "#ff1e7b", fontSize: 16 }}>favorite</i>
            {` by `}
            <Link style={{ color: "#ff1e7b" }} href="https://www.discriminant.in/">Discriminant Technologies, Pune</Link>
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/featured/?construction,architecture)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const SignIn = (props) => {
    const classes = useStyles();

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState("");

    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorText, setPasswordErrorText] = useState("");

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [appError, setAppError] = useState("");
    const [loading, setLoading] = React.useState(false);

    const [LocalSignin] = useMutation(LOCAL_SIGN_IN);

    // check if user is already logged in
    useEffect(() => {
        const authToken = localStorage.getItem('AUTH_TOKEN');
        // console.log('authToken::', authToken);
        if (authToken) {
            const decodedToken = jwt.decode(authToken, { complete: true });
            // console.log('decodedToken::', decodedToken);
            if (decodedToken) {
                // send a request to server to validate the token
                // ...
                props.history.push("/home");
            }
            else {
                props.history.push("/signin");
            }
        }
    }, [props.history]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    };

    const validateUserSignin = async () => {
        let invalidCredentials = false;
        if (typeof email === 'undefined' || email === null || email.trim().length === 0) {
            setEmailErrorText("Email is required");
            setEmailError(true);
            invalidCredentials = true;
        } else {
            setEmailErrorText("");
            setEmailError(false);
        }
        if (typeof password === 'undefined' || password === null || password.trim().length === 0) {
            setPasswordErrorText("Password is required");
            setPasswordError(true);
            invalidCredentials = true;
        } else {
            setPasswordErrorText("");
            setPasswordError(false);
        }

        return invalidCredentials;
    }

    // function to handle sign in
    const handleSignIn = async (event) => {
        event.preventDefault();
        setLoading(true);
        const invalidCredentials = await validateUserSignin();

        // console.log('invalidCredentials: ', invalidCredentials);
        // console.log('emailError: ', emailError);
        // console.log('passwordError: ', passwordError);

        if (!invalidCredentials && !emailError && !passwordError) {
            const basicToken = window.btoa(email + ":" + password);
            LocalSignin({
                context: {
                    headers: {
                        "Authorization": `Basic ${basicToken}`
                    }
                }
            })
                .then(resp => {
                    const result = resp.data.result;
                    //console.log('Signin Resp:', result);
                    if (JSON.parse(result.USER_ROLES).includes("Admin")) {
                        localStorage.setItem('AUTH_TOKEN', result.AUTH_TOKEN);
                        localStorage.setItem('EMAIL', result.EMAIL);
                        localStorage.setItem('FIRST_NAME', result.FIRST_NAME);
                        localStorage.setItem('LAST_NAME', result.LAST_NAME);
                        localStorage.setItem('MOBILE_NUMBER', result.MOBILE_NUMBER);
                        setLoading(false);
                        props.history.push("/home");
                    }
                    else {
                        setOpenSnackbar(true);
                        setAppError("You are not authorized to access admin portal.");
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.log('Signin Error:', error.message);
                    setLoading(false);
                    if (error.message.includes('email')) {
                        setEmailError(true);
                        setEmailErrorText(error.message);
                    }
                    else if (error.message.includes('password')) {
                        setPasswordError(true);
                        setPasswordErrorText(error.message);
                    }
                    else {
                        setOpenSnackbar(true);
                        setAppError(error.message);
                    }
                })
        }
        else
            setLoading(false);
    };

    return (
        <>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleSnackbarClose}
            >
                <Alert severity="error" onClose={handleSnackbarClose}>
                    {appError}
                </Alert>
            </Snackbar>

            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <form className={classes.form} >
                            <TextField
                                disabled={loading}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                error={emailError}
                                helperText={emailError ? emailErrorText : ""}
                                value={email}
                                onChange={(event) => {
                                    event.preventDefault();
                                    setEmail(event.target.value);
                                    if (event.target.value.trim().length === 0) {
                                        setEmailErrorText("Email is required");
                                        setEmailError(true);
                                    }
                                    else {
                                        setEmailErrorText("");
                                        setEmailError(false);
                                    }
                                }}
                            />
                            <TextField
                                disabled={loading}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                error={passwordError}
                                helperText={passwordError ? passwordErrorText : ""}
                                value={password}
                                onChange={(event) => {
                                    event.preventDefault();
                                    setPassword(event.target.value);
                                    if (event.target.value.trim().length === 0) {
                                        setPasswordErrorText("Password is required");
                                        setPasswordError(true);
                                    }
                                    else {
                                        setPasswordErrorText("");
                                        setPasswordError(false);
                                    }
                                }}
                            />

                            <div className={classes.wrapper}>
                                <Button
                                    disabled={loading}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={handleSignIn}
                                >
                                    Sign In
                                </Button>
                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                            {/* <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2">
                                        Forgot password?
                                </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="#" variant="body2">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid> */}
                            <Box mt={5}>
                                <Copyright />
                            </Box>
                        </form>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default withRouter(SignIn);