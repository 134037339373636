import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { CardMedia, IconButton, Paper, Tooltip } from "@material-ui/core";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { green } from "@material-ui/core/colors";

import { imgbb } from "../../util/axios";
import Loader from "../Loader";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        margin: '10px'
    },
    formControl: {
        // margin: theme.spacing(1),
        display: 'flex',
        minWidth: 120,
        margin: 0
    },
    input: {
        display: 'none',
    },
    button: {
        margin: theme.spacing(1),
    },
    media: {
        height: 140,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
    }
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const ImgbbUpload = (props) => {
    const classes = useStyles();

    const { openDocumentsDialog, handleCloseDocumentsDialog, transaction, docs, uploadDocuments } = props;
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        setDocuments(docs);
    }, [docs]);

    const uploadImagetoImgBB = async (imageFile, index) => {
        try {
            setUploading(true);

            const formData = new FormData();
            formData.append('image', imageFile);

            const result = await imgbb({
                method: "POST",
                data: formData
            });

            //console.log("ImgBB Resp: ", result);

            const updatedDocuments = documents.map((document, idx) => {
                if (index === idx)
                    return { ...document, URL: result.data.data.display_url };
                else
                    return document;
            });

            setDocuments(updatedDocuments);

            setUploading(false);
            setUploadSuccess(true);
        } catch (error) {
            console.log("ImgBB error:", error);
            setUploading(false);
            setUploadSuccess(false);
        };
    }


    return <Dialog open={openDocumentsDialog} onClose={handleCloseDocumentsDialog} TransitionComponent={Transition}>
        <div style={{ position: "relative" }}>
            {(uploading) && <Loader />}
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleCloseDocumentsDialog} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {transaction === 'CREATE' ? "Add Documents" : "Update Documents"}
                    </Typography>
                    <Button
                        autoFocus
                        color="secondary"
                        variant="contained"
                        onClick={() => uploadDocuments(documents)}
                    >
                        save
                    </Button>
                </Toolbar>
            </AppBar>

            <Paper variant="elevation" elevation={3} className={classes.paper}>
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={12}>
                        <Typography variant="button">Documents</Typography>
                        <Tooltip title="Add New">
                            <IconButton
                                onClick={() => {
                                    setDocuments([...documents, {
                                        "TYPE": "",
                                        "URL": ""
                                    }])
                                }}
                            >
                                <AddBoxIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    {/* loop through all the documents */}
                    {
                        documents?.map((document, index) => {
                            return <Grid key={index} item xs={12} sm={6}>
                                <Grid item xs={12} sm={12} style={{ display: "flex" }}>
                                    <TextField
                                        id={`doc_${index}`}
                                        name={`docname_${index}`}
                                        label="Type of document"
                                        required
                                        fullWidth
                                        value={document?.TYPE}
                                        InputLabelProps={{ shrink: document?.TYPE && true }}
                                        onChange={event => {
                                            const updatedDocuments = documents.map((document, idx) => {
                                                if (index === idx)
                                                    return { ...document, TYPE: event.target.value };
                                                else
                                                    return document;
                                            });
                                            setDocuments(updatedDocuments);
                                        }}
                                    />
                                    <input
                                        accept="image/*"
                                        id={`DOCUMENTS_${index}`}
                                        type="file"
                                        className={classes.input}
                                        onChange={(event) => {
                                            setUploadSuccess(false);
                                            uploadImagetoImgBB(event.target.files[0], index);
                                        }}
                                    />

                                    <label htmlFor={`DOCUMENTS_${index}`}>
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <AttachFileIcon />
                                        </IconButton>
                                    </label>
                                </Grid>
                                <Grid key={index} item xs={12} sm={12}>

                                    <CardMedia
                                        className={classes.media}
                                        image={document?.URL}
                                        title={document?.TYPE}
                                    />
                                </Grid>
                            </Grid>
                        })
                    }
                </Grid>
            </Paper>
        </div>
    </Dialog>
}

export default ImgbbUpload;