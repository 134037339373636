import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Snackbar, TextField, Tooltip, Typography } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import DeleteIcon from '@material-ui/icons/Delete';
import { MuiThemeProvider } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';

import { SEARCH_USER_PROFILES } from "../../../../graphql/queries";
import { LOCAL_PASSWORD_RESET, DELETE_USER_ACCOUNT } from "../../../../graphql/mutations";
import { globalTableOptions, globalTableTheme } from "../../../../theme";
import Loader from "../../../../components/Loader";
import UserForm from "../UserForm";
import { checkEmail, checkNull } from "../../../../util/validations";

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const validateResetPassword = async (user) => {
    let validationObject = {};

    checkNull("EMAIL", user.EMAIL, "Email is required", validationObject);
    checkNull("PASSWORD", user.PASSWORD, "Password is required", validationObject);

    checkEmail("EMAIL", user.EMAIL, "Email is not valid", validationObject);

    return validationObject;
}


const UsersList = () => {

    // apollo queries and mutations
    const [getUsersList, { loading, data, error, refetch: refetchUsersList }] = useLazyQuery(SEARCH_USER_PROFILES);
    const [ResetPassword] = useMutation(LOCAL_PASSWORD_RESET);
    const [DeleteUserAccount] = useMutation(DELETE_USER_ACCOUNT);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const [transaction, setTransaction] = useState("");
    const [user, setUser] = useState(null);
    const [userEmail, setUserEmail] = useState(null);

    const [formDialogOpen, setFormDialogOpen] = useState(false);
    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [passResetState, setPassResetState] = useState(null);
    const [passResetErrors, setPassResetErrors] = useState(null);

    const [updating, setUpdating] = useState(false);

    // inital effect
    useEffect(() => {
        //console.log('Calling useEffect()....');
        getUsersList();
    }, [getUsersList]);


    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    };

    const handleFormDialogClose = () => {
        setFormDialogOpen(false);
    };

    const handleResetPasswordDialogClose = () => {
        setResetPasswordDialogOpen(false);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    if (error) {
        setSnackbarSeverity("error");
        setSnackbarMessage(error);
        setOpenSnackbar(true);
    };

    const handleLocalUserOps = () => {
        setFormDialogOpen(false);
        //setUser(null);
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        setSnackbarMessage(`User ${transaction === 'CREATE' ? 'added' : 'updated'} successfully!`);
        refetchUsersList();
    };

    const setPasswordValidationErrors = (error) => {
        // set Error state;
        setPassResetErrors({
            EMAIL: error.errorEMAIL,
            PASSWORD: error.errorPASSWORD
        });
    }

    const resetUserPassword = async () => {
        setUpdating(true);

        const checkPasswordState = { ...passResetState };
        const validationErrors = await validateResetPassword(checkPasswordState);

        if (Object.keys(validationErrors).length !== 0) {
            setPasswordValidationErrors(validationErrors);
            setUpdating(false);
        }
        else {
            if (passResetState.PASSWORD !== passResetState.CONFIRM_PASSWORD) {
                setPassResetErrors({ ...passResetErrors, CONFIRM_PASSWORD: "Password does not match" });
                setUpdating(false);
            }
            else
                ResetPassword({
                    variables: {
                        email: passResetState.EMAIL,
                        password: passResetState.PASSWORD
                    }
                })
                    .then(resp => {
                        const result = resp.data.result;
                        console.log('[RESET PASSWORD] Resp: ', result);
                        setResetPasswordDialogOpen(false);
                        setSnackbarSeverity("success");
                        setOpenSnackbar(true);
                        setSnackbarMessage("Password changed successfully!");
                        setUpdating(false);
                    })
                    .catch(error => {
                        console.log('[RESET PASSWORD] Error:', error.message);
                        setSnackbarSeverity("error");
                        setOpenSnackbar(true);
                        setSnackbarMessage(error.message);
                        setUpdating(false);
                    });
        }
    };


    const deleteUserAccount = () => {
        setUpdating(true);
        DeleteUserAccount({
            variables: {
                email: userEmail
            }
        })
            .then(resp => {
                // const result = resp.data.result;
                // console.log('[DELETE USER] Resp: ', result);
                setDeleteDialogOpen(false);
                setSnackbarSeverity("success");
                setOpenSnackbar(true);
                setSnackbarMessage("User account removed successfully!");
                refetchUsersList();
                setUpdating(false);
            })
            .catch(error => {
                console.log('[DELETE USER] Error:', error.message);
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
                setSnackbarMessage(error.message);
                setUpdating(false);
            });
    };


    const tableColumns = [
        {
            name: "USER_ID",
            label: "User ID",
            options: {
                filter: false,
                sort: true,
                display: false
            }
        }, {
            name: "FIRST_NAME",
            label: "First Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "LAST_NAME",
            label: "Last Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "EMAIL",
            label: "Email",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "MOBILE_NUMBER",
            label: "Mobile No.",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "USER_ROLES",
            label: "Roles",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => <Typography variant="body2">
                    {JSON.parse(value).join(', ')}
                </Typography>
            }
        }, {
            name: "USER_PERMISSIONS",
            label: "Permissions",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const perm = JSON.parse(value);
                    return <Typography variant="body2">
                        {Object.keys(perm).filter(key => perm[key] === true).join(', ')}
                    </Typography>
                }
            }
        }, {
            name: "ACTIONS",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const tableData = data?.result;
                    const rowIndex = tableMeta.rowIndex;
                    // console.log('Selcted tableMeta: ', tableMeta);
                    return <>
                        <Tooltip title="Reset Password">
                            <IconButton
                                style={{ color: "#17A2B8" }}
                                onClick={() => {
                                    setPassResetState({ EMAIL: tableData[rowIndex].EMAIL });
                                    setResetPasswordDialogOpen(true);
                                }}
                            >
                                <VpnKeyIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <IconButton
                                style={{ color: "#343a40" }}
                                onClick={() => {
                                    setUser(tableData[rowIndex]);
                                    setTransaction('UPDATE');
                                    setFormDialogOpen(true);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton
                                style={{ color: "#dc3545" }}
                                onClick={() => {
                                    setUserEmail(tableData[rowIndex].EMAIL);
                                    setDeleteDialogOpen(true);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            }
        },
    ];

    let tableData = [];
    if (data) {
        //console.log('Users list:', data.result);
        tableData = data.result;
    }

    const tableOptions = {
        ...globalTableOptions,
        downloadOptions: {
            filename: "app-user-list.csv"
        },
    };

    return <div style={{ position: "relative" }}>
        {/* loader */}
        {(loading) && <Loader />}

        {/* add new employee */}
        <Tooltip title="Add New">
            <Fab
                size="small"
                //color="#4caf50" 
                aria-label="add"
                onClick={() => {
                    setUser(null);
                    setTransaction('CREATE');
                    setFormDialogOpen(true);
                }}
                style={{ float: "right", marginTop: "12px", marginRight: "12px", color: "#FFFFFF", backgroundColor: "#4caf50" }}
            >
                <AddIcon />
            </Fab>
        </Tooltip>

        {/* data table */}
        <MuiThemeProvider theme={globalTableTheme}>
            <MUIDataTable
                title={"Application Users"}
                data={tableData}
                columns={tableColumns}
                options={tableOptions}
            />
        </MuiThemeProvider>

        {/* snackbar */}
        <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleSnackbarClose}
        >
            <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
                {snackbarMessage}
            </Alert>
        </Snackbar>

        {/* create/update form */}
        {<UserForm
            formDialogOpen={formDialogOpen}
            handleFormDialogClose={handleFormDialogClose}
            handleLocalUserOps={handleLocalUserOps}
            transaction={transaction}
            user={user}
        />}

        {/* reset password action dialog */}
        <Dialog
            open={resetPasswordDialogOpen}
            onClose={handleResetPasswordDialogClose}
            aria-labelledby="reset-password"
            aria-describedby="reset-password-dialog-description"
        >
            <div style={{ position: "relative" }}>
                {(updating) && <Loader />}
                <DialogTitle id="reset-password-dialog-title">{"Reset Password"}</DialogTitle>
                <DialogContent style={{ overflow: "hidden" }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="EMAIL"
                                name="EMAIL"
                                label="Email"
                                required
                                disabled
                                fullWidth
                                autoComplete="EMAIL"
                                error={passResetErrors?.EMAIL ? true : false}
                                helperText={passResetErrors?.EMAIL}
                                value={passResetState?.EMAIL}
                                onChange={(event) => {
                                    setPassResetState({ ...passResetState, EMAIL: event.target.value });
                                    if (event.target.value.trim().length !== 0)
                                        setPassResetErrors({ ...passResetErrors, EMAIL: null })
                                    else
                                        setPassResetErrors({ ...passResetErrors, EMAIL: "Email is required" })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="PASSWORD"
                                name="PASSWORD"
                                label="Password"
                                type="password"
                                fullWidth
                                autoComplete="PASSWORD"
                                error={passResetErrors?.PASSWORD ? true : false}
                                helperText={passResetErrors?.PASSWORD}
                                value={passResetState?.PASSWORD}
                                onChange={(event) => {
                                    setPassResetState({ ...passResetState, PASSWORD: event.target.value });
                                    if (event.target.value.trim().length !== 0)
                                        setPassResetErrors({ ...passResetErrors, PASSWORD: null })
                                    else
                                        setPassResetErrors({ ...passResetErrors, PASSWORD: "Password is required" })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                id="CONFIRM_PASSWORD"
                                name="CONFIRM_PASSWORD"
                                label="Confirm Password"
                                type="password"
                                fullWidth
                                autoComplete="CONFIRM_PASSWORD"
                                error={passResetErrors?.CONFIRM_PASSWORD ? true : false}
                                helperText={passResetErrors?.CONFIRM_PASSWORD}
                                value={passResetState?.CONFIRM_PASSWORD}
                                onChange={(event) => {
                                    setPassResetState({ ...passResetState, CONFIRM_PASSWORD: event.target.value });
                                    if (event.target.value.trim().length !== 0)
                                        setPassResetErrors({ ...passResetErrors, CONFIRM_PASSWORD: null })
                                    else
                                        setPassResetErrors({ ...passResetErrors, CONFIRM_PASSWORD: "Please confirm password" })
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleResetPasswordDialogClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={resetUserPassword} variant="contained" color="secondary" autoFocus>
                        Reset
                    </Button>
                </DialogActions>
            </div>
        </Dialog>

        {/* delete action dialog */}
        <Dialog
            open={deleteDialogOpen}
            onClose={handleDeleteDialogClose}
            aria-labelledby="delete-user"
            aria-describedby="delete-dialog-description"
        >
            <div style={{ position: "relative" }}>
                {(updating) && <Loader />}
                <DialogTitle id="delete-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent style={{ overflow: "hidden" }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Typography>The user will be deleted.</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={deleteUserAccount} variant="contained" color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </div>
        </Dialog>

    </div >
}

export default UsersList;