import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { useMutation, useQuery } from '@apollo/client';
import { CardMedia, CircularProgress, FormControl, FormControlLabel, FormHelperText, IconButton, Paper, Switch } from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { POPULATE_MATERIAL_TYPES_DDL, POPULATE_UOM_DDL } from "../../../../graphql/queries";
import { MATERIALS_CRUD } from "../../../../graphql/mutations";
import { checkNull, checkMaxLength } from '../../../../util/validations';
import { green } from "@material-ui/core/colors";
import Loader from "../../../../components/Loader";
import { imgbb } from "../../../../util/axios";


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        margin: '10px'
    },
    formControl: {
        // margin: theme.spacing(1),
        display: 'flex',
        minWidth: 120,
        margin: 0
    },
    input: {
        display: 'none',
    },
    button: {
        margin: theme.spacing(1),
    },
    media: {
        height: 140,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
    },
    uploadSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validateMaterialsCRUDData = async (Materials) => {
    let validationObjects = {};
    for (let i = 0; i < Materials.length; i++) {
        let validationObject = {};

        if (Materials[i].transaction !== "CREATE")
            checkNull("MATERIAL_ID", Materials[i].MATERIAL_ID, "Material Id is required", validationObject);

        checkNull("MATERIAL_NAME", Materials[i].MATERIAL_NAME, "Material Name is required", validationObject);
        checkNull("UOM_ID", Materials[i].UOM_ID?.CODE, "Please select unit of measurement", validationObject);
        checkNull("IS_ACTIVE", Materials[i].IS_ACTIVE, "Please select active/inactive", validationObject);
        checkNull("TRACK_USAGE", Materials[i].TRACK_USAGE, "Please select to track usage or not", validationObject);
        checkNull("MATERIAL_TYPE", Materials[i].MATERIAL_TYPE?.CODE, "Please select type of material", validationObject);

        checkMaxLength("MATERIAL_ID", Materials[i].MATERIAL_ID, 48, "Length of Material Id should be less than or equal to 48 characters", validationObject);
        checkMaxLength("MATERIAL_NAME", Materials[i].MATERIAL_NAME, 100, "Length of Material Name should be less than or equal to 100 characters", validationObject);
        checkMaxLength("MATERIAL_DESC", Materials[i].MATERIAL_DESC, 500, "Length of Material Description should be less than or equal to 500 characters", validationObject);
        checkMaxLength("MATERIAL_IMAGE", Materials[i].MATERIAL_IMAGE, 200, "Length of Material Image URL should be less than or equal to 200 characters", validationObject);
        checkMaxLength("UOM_ID", Materials[i].UOM_ID?.CODE, 48, "Length of UOM Id should be less than or equal to 48 characters", validationObject);
        checkMaxLength("IS_ACTIVE", Materials[i].IS_ACTIVE, 1, "Length of Active/Inactive should be equal to 1 character", validationObject);
        checkMaxLength("TRACK_USAGE", Materials[i].TRACK_USAGE, 1, "Length of Usage Tracking should be equal to 1 character", validationObject);
        checkMaxLength("MATERIAL_TYPE", Materials[i].MATERIAL_TYPE?.CODE, 48, "Length of Material Type should be less than or equal to 48 characters", validationObject);

        if (Object.keys(validationObject).length !== 0)
            validationObjects[i] = validationObject;
    }
    return validationObjects;
}

const MaterialForm = (props) => {
    const classes = useStyles();

    const { formDialogOpen, handleFormDialogClose, handleMaterialsCRUD, transaction, material } = props;
    const [materialState, setMaterialState] = useState(null);
    const [materialErrors, setMaterialErrors] = useState(null);
    const [saving, setSaving] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const { loading: materialTypesLoading, error: materialTypesError, data: materialTypes } = useQuery(POPULATE_MATERIAL_TYPES_DDL);
    const { loading: uomLoading, error: uomError, data: uom } = useQuery(POPULATE_UOM_DDL);
    const [MaterialsCRUD] = useMutation(MATERIALS_CRUD);

    const uploadSuccessClass = clsx({
        [classes.uploadSuccess]: uploadSuccess,
    });

    useEffect(() => {
        setUploadSuccess(false);
        setMaterialErrors(null);
        if (material) {
            setMaterialState({
                transaction: transaction,
                MATERIAL_ID: material.MATERIAL_ID,
                MATERIAL_NAME: material.MATERIAL_NAME,
                // MATERIAL_TYPE: material.MATERIAL_TYPE,
                MATERIAL_TYPE: materialTypes.materialTypes.filter(type => type.CODE === material.MATERIAL_TYPE)[0] || {},
                MATERIAL_DESC: material.MATERIAL_DESC,
                MATERIAL_IMAGE: material.MATERIAL_IMAGE,
                // UOM_ID: material.UOM_ID,
                UOM_ID: uom.uom.filter(uom => uom.CODE === material.UOM_ID)[0] || {},
                IS_ACTIVE: material.IS_ACTIVE,
                TRACK_USAGE: material.TRACK_USAGE,
            });
        }
        else {
            setMaterialState({
                transaction: transaction,
                IS_ACTIVE: "Y",
                TRACK_USAGE: "N",
            });
        }
    }, [props]);

    const uploadImagetoImgBB = async (imageFile) => {
        try {
            setUploading(true);
            // const url = 'https://api.imgbb.com/1/upload';
            const formData = new FormData();
            formData.append('image', imageFile);

            const result = await imgbb({
                method: "POST",
                data: formData
            });

            //console.log("ImgBB Resp: ", result);
            setMaterialState({ ...materialState, MATERIAL_IMAGE: result.data.data.display_url });
            setUploading(false);
            setUploadSuccess(true);
        } catch (error) {
            console.log("ImgBB error:", error);
            setUploading(false);
            setUploadSuccess(false);
        };
    }

    const setMaterialValidationErrors = (errors) => {
        for (let key in errors) {
            // set Error state;
            setMaterialErrors({
                MATERIAL_ID: errors[key].errorMATERIAL_ID,
                MATERIAL_NAME: errors[key].errorMATERIAL_NAME,
                MATERIAL_DESC: errors[key].errorMATERIAL_DESC,
                MATERIAL_TYPE: errors[key].errorMATERIAL_TYPE,
                MATERIAL_IMAGE: errors[key].errorMATERIAL_IMAGE,
                UOM_ID: errors[key].errorUOM_ID,
                IS_ACTIVE: errors[key].errorIS_ACTIVE,
                TRACK_USAGE: errors[key].errorTRACK_USAGE,
            });
        }
    }

    const saveMaterial = async () => {
        // console.log('[MaterialForm] material state: ', materialState);
        setSaving(true);
        const validationErrors = await validateMaterialsCRUDData([materialState]);
        if (Object.keys(validationErrors).length !== 0) {
            setMaterialValidationErrors(validationErrors);
            setSaving(false);
        }
        else
            MaterialsCRUD({
                variables: {
                    "transaction": materialState.transaction,
                    "materials": [{
                        "MATERIAL_ID": materialState.MATERIAL_ID,
                        "MATERIAL_NAME": materialState.MATERIAL_NAME,
                        "MATERIAL_DESC": materialState.MATERIAL_DESC,
                        "MATERIAL_IMAGE": materialState.MATERIAL_IMAGE,
                        "UOM_ID": materialState.UOM_ID.CODE,
                        "IS_ACTIVE": materialState.IS_ACTIVE,
                        "TRACK_USAGE": materialState.TRACK_USAGE,
                        "MATERIAL_TYPE": materialState.MATERIAL_TYPE.CODE
                    }]
                }
            })
                .then(resp => {
                    //const result = resp.data.result;
                    //console.log('[MaterialsCRUD] Resp: ', result);
                    // setMaterialState({});
                    setSaving(false);
                    handleMaterialsCRUD();
                })
                .catch(error => {
                    console.log('[MaterialsCRUD] Error:', error.message);
                    setSaving(false);
                    setMaterialValidationErrors(JSON.parse(error));
                });
    }

    return <Dialog open={formDialogOpen} onClose={handleFormDialogClose} TransitionComponent={Transition}>
        <div style={{ position: "relative" }}>
            {(saving) && <Loader />}
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleFormDialogClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {transaction === 'CREATE' ? "Add New Material" : "Update Material"}
                    </Typography>
                    <Button autoFocus color="secondary" variant="contained" onClick={saveMaterial}>
                        save
                </Button>
                </Toolbar>
            </AppBar>

            <Paper variant="elevation" elevation={3} className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="MATERIAL_NAME"
                            name="MATERIAL_NAME"
                            label="Material name"
                            fullWidth
                            autoComplete="material-name"
                            error={materialErrors?.MATERIAL_NAME ? true : false}
                            helperText={materialErrors?.MATERIAL_NAME}
                            value={materialState?.MATERIAL_NAME}
                            onChange={(event) => {
                                setMaterialState({ ...materialState, MATERIAL_NAME: event.target.value });
                                if (event.target.value.trim().length !== 0)
                                    setMaterialErrors({ ...materialErrors, MATERIAL_NAME: null })
                                else
                                    setMaterialErrors({ ...materialErrors, MATERIAL_NAME: "Material Name is required" })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="MATERIAL_DESC"
                            name="MATERIAL_DESC"
                            fullWidth
                            label="Description"
                            autoComplete="material-description"
                            value={materialState?.MATERIAL_DESC}
                            onChange={(event) => setMaterialState({ ...materialState, MATERIAL_DESC: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} error={materialErrors?.MATERIAL_TYPE ? true : false}>
                            <Autocomplete
                                style={{ marginTop: "-16px" }}
                                id="MATERIAL_TYPE"
                                autoComplete
                                fullWidth
                                loading={materialTypesLoading}
                                options={materialTypes?.materialTypes}
                                getOptionLabel={option => option.DESC ? option.DESC : ""}
                                renderInput={(params) => (
                                    <TextField required {...params} label="Material Type" margin="normal" />
                                )}
                                renderOption={(option, { inputValue }) => {
                                    const matches = match(option.DESC, inputValue);
                                    const parts = parse(option.DESC, matches);
                                    return (
                                        <div>
                                            {parts.map((part, index) => (
                                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    );
                                }}
                                value={materialState?.MATERIAL_TYPE || {}}
                                onChange={(event, newValue) => {
                                    setMaterialState({ ...materialState, MATERIAL_TYPE: newValue });
                                    if (newValue)
                                        setMaterialErrors({ ...materialErrors, MATERIAL_TYPE: null })
                                    else
                                        setMaterialErrors({ ...materialErrors, MATERIAL_TYPE: "Material Type is required" })
                                }}
                            />
                            <FormHelperText>{materialErrors?.MATERIAL_TYPE}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} error={materialErrors?.UOM_ID ? true : false}>
                            <Autocomplete
                                style={{ marginTop: "-16px" }}
                                id="UOM_ID"
                                autoComplete
                                fullWidth
                                loading={uomLoading}
                                options={uom?.uom}
                                getOptionLabel={option => option.DESC ? option.DESC : ""}
                                renderInput={(params) => (
                                    <TextField required {...params} label="Unit of Measurement" margin="normal" />
                                )}
                                renderOption={(option, { inputValue }) => {
                                    const matches = match(option.DESC, inputValue);
                                    const parts = parse(option.DESC, matches);
                                    return (
                                        <div>
                                            {parts.map((part, index) => (
                                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    );
                                }}
                                value={materialState?.UOM_ID || {}}
                                onChange={(event, newValue) => {
                                    setMaterialState({ ...materialState, UOM_ID: newValue });
                                    if (newValue)
                                        setMaterialErrors({ ...materialErrors, UOM_ID: null })
                                    else
                                        setMaterialErrors({ ...materialErrors, UOM_ID: "Unit of Measurement is required" })
                                }}
                            />
                            <FormHelperText>{materialErrors?.UOM_ID}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="imageName"
                            name="ImageName"
                            label="Image"
                            required
                            fullWidth
                            disabled
                            value={materialState?.MATERIAL_IMAGE}
                            error={materialErrors?.MATERIAL_IMAGE ? true : false}
                            helperText={materialErrors?.MATERIAL_IMAGE}
                            InputLabelProps={{ shrink: materialState?.MATERIAL_IMAGE && true }}
                        />

                        <input
                            accept="image/*"
                            id="MATERIAL_IMAGE"
                            type="file"
                            className={classes.input}
                            onChange={(event) => {
                                setUploadSuccess(false);
                                uploadImagetoImgBB(event.target.files[0]);
                            }}
                        />

                        <label htmlFor="MATERIAL_IMAGE">
                            <div className={classes.wrapper}>
                                <Fab variant="extended" component="span" size="medium" className={uploadSuccessClass}>
                                    {uploadSuccess ? <CheckIcon className={classes.extendedIcon} /> : <PhotoCamera className={classes.extendedIcon} />}
                                    {uploadSuccess ? "Success" : "Browse"}
                                </Fab>
                                {uploading && <CircularProgress size={44} className={classes.fabProgress} />}
                            </div>
                        </label>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControlLabel
                            label={materialState?.IS_ACTIVE === 'Y' ? "Active" : "Inactive"}
                            control={
                                <Switch
                                    checked={materialState?.IS_ACTIVE === "Y" ? true : false}
                                    name="IS_ACTIVE"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    onChange={(event) => setMaterialState({ ...materialState, IS_ACTIVE: event.target.checked ? "Y" : "N" })}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControlLabel
                            label="Track usage"
                            control={
                                <Switch
                                    checked={materialState?.TRACK_USAGE === "Y" ? true : false}
                                    name="TRACK_USAGE"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    onChange={(event) => setMaterialState({ ...materialState, TRACK_USAGE: event.target.checked ? "Y" : "N" })}
                                />
                            }
                        />
                    </Grid>

                    {materialState?.MATERIAL_IMAGE && <Grid item xs={12} sm={6}>
                        <CardMedia
                            className={classes.media}
                            image={materialState?.MATERIAL_IMAGE}
                            title="Material Image"
                        />
                    </Grid>
                    }

                </Grid>
            </Paper>
        </div>
    </Dialog>
}

export default MaterialForm;