import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 450,
    },
    icon: {
        color: 'white',
    },
    title: {
        color: 'white',
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));

const ImageGrid = ({ imageDialogOpen, handleImageDialogClose, images }) => {
    const classes = useStyles();

    return (
        < Dialog fullscreen="true" open={imageDialogOpen} onClose={handleImageDialogClose} aria-labelledby="Images" >
            <DialogTitle id="image-dialog">Images</DialogTitle>
            <DialogContent dividers>
                <div className={classes.root}>
                    <GridList cellHeight={180} className={classes.gridList}>
                        {images?.map((image, index) => (
                            <GridListTile key={index}>
                                <img src={image.URL} alt={image.TYPE} />
                                <GridListTileBar
                                    title={image.TYPE}
                                    classes={{
                                        root: classes.titleBar,
                                        title: classes.title,
                                    }}
                                    actionIcon={
                                        <Tooltip title="View">
                                            <a href={image.URL} download rel="noreferrer noopener" target="_blank">
                                                <IconButton aria-label={`info about ${image.TYPE}`} className={classes.icon}>
                                                    <OpenInNewIcon />
                                                </IconButton>
                                            </a>
                                        </Tooltip>
                                    }
                                />
                            </GridListTile>
                        ))}
                    </GridList>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleImageDialogClose} color="primary">
                    Close
              </Button>
            </DialogActions>
        </Dialog >
    );
}

export default ImageGrid;