import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import Home from './containers/Home';
import SignIn from "./containers/SignIn";

function App() {
  return (
      <Switch>
        <Route exact path="/">
          <Redirect to="/signin" />
        </Route>
        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
      </Switch>
  );
}

export default App;
