import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useQuery } from '@apollo/client';
import moment from "moment";
import { CircularProgress } from '@material-ui/core';

import { LOAD_DASHBOARD } from '../../graphql/queries';
import Activities from './components/Activities';
import Amount from './components/Amount';
import Loader from "../../components/Loader";
// import Chart from './Chart';
// import Deposits from './Deposits';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    wrapper: {
        // margin: theme.spacing(1),
        position: 'relative'
    },
}));


const Dashboard = () => {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    const { loading, error, data } = useQuery(LOAD_DASHBOARD, {
        variables: {
            "siteID": "d42e5964-a2d5-40bd-8688-6972ed39ce3a",
            "fronDate": moment().startOf('month').format("YYYYMMDD"),
            "toDate": moment().format("YYYYMMDD")
        },
        pollInterval: 60000
    });

    return <Grid container spacing={3}>
        {/* Chart */}
        <Grid item xs={12} md={8} lg={9}>
            <Paper className={fixedHeightPaper}>
                {/* <Chart /> */}
                <h4>Coming soon...</h4>
            </Paper>
        </Grid>

        {/* Total Amount Spent */}
        <Grid item xs={12} md={4} lg={3}>
            <Paper className={fixedHeightPaper}>
                <div className={classes.wrapper}>
                    <Amount amount={data?.amount || []} />
                    {loading && <Loader />}
                </div>
            </Paper>
        </Grid>

        {/* Recent Activities */}
        <Grid item xs={12}>
            <Paper className={classes.paper}>
                <div className={classes.wrapper}>
                    <Activities activities={data?.activities || []} />
                    {loading && <Loader />}
                </div>
            </Paper>
        </Grid>
    </Grid>
}

export default Dashboard;