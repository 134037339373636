import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Snackbar, Tooltip } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { MuiThemeProvider } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { SEARCH_EMPLOYEE_PAYMENTS } from "../../../../graphql/queries";
import { EMPLOYEE_PAYMENTS_CRUD } from "../../../../graphql/mutations";
import { globalTableOptions, globalTableTheme } from "../../../../theme";
import Loader from "../../../../components/Loader";
import PaymentForm from "../PaymentForm";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginBottom: '10px'
    },
    fixedHeight: {
        height: 240,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PaymentsList = () => {
    const classes = useStyles();

    // apollo queries and mutations
    const [getPaymentList, { loading, data, error, refetch: refetchPaymentList }] = useLazyQuery(SEARCH_EMPLOYEE_PAYMENTS);
    const [EmployeePaymentsCRUD] = useMutation(EMPLOYEE_PAYMENTS_CRUD);

    const [paymentFromDate, setPaymentFromDate] = React.useState(moment().subtract(1, 'months'));
    const [paymentToDate, setPaymentToDate] = React.useState(moment());
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [formDialogOpen, setFormDialogOpen] = useState(false);
    const [transaction, setTransaction] = useState("");
    const [payment, setPayment] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [paymentID, setPaymentID] = useState("");
    const [deleting, setDeleting] = useState(false);

    // inital effect
    useEffect(() => {
        //console.log('Calling useEffect()....');
        getPaymentList({
            variables: {
                paymentFromDate: paymentFromDate.format("YYYYMMDD"),
                paymentToDate: paymentToDate.format("YYYYMMDD")
            }
        });
    }, []);

    // search employee payments
    const searchEmployeePayments = () => {
        //console.log('Calling searchEmployeePayments()....');
        getPaymentList({
            variables: {
                paymentFromDate: paymentFromDate?.format("YYYYMMDD") || moment().subtract(1, 'months').format("YYYYMMDD"),
                paymentToDate: paymentToDate?.format("YYYYMMDD") || moment().format("YYYYMMDD"),
            }
        });
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    };

    const handleFormDialogClose = () => {
        setFormDialogOpen(false);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    if (error) {
        setSnackbarSeverity("error");
        setSnackbarMessage(error);
        setOpenSnackbar(true);
    };

    const handleEmployeePaymentsCRUD = () => {
        setFormDialogOpen(false);
        //setPayment(null);
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        setSnackbarMessage(`Payment ${transaction === 'CREATE' ? 'added' : 'updated'} successfully!`);
        refetchPaymentList();
    };

    const deleteEmployeePayment = () => {
        setDeleting(true);
        EmployeePaymentsCRUD({
            variables: {
                "transaction": "LOGICAL_DELETE",
                "payments": [{
                    "PAYMENT_ID": paymentID,
                }]
            }
        })
            .then(resp => {
                // const result = resp.data.result;
                // console.log('[DELETE PAYMENT] Resp: ', result);
                setDeleteDialogOpen(false);
                setSnackbarSeverity("success");
                setOpenSnackbar(true);
                setSnackbarMessage("Payment removed successfully!");
                refetchPaymentList();
                setDeleting(false);
            })
            .catch(error => {
                console.log('[DELETE PAYMENT] Error:', error.message);
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
                setSnackbarMessage(JSON.parse(error.message)["0"].errorPAYMENT_ID);
                setDeleting(false);
            });
    };

    const tableColumns = [
        {
            name: "PAYMENT_ID",
            label: "Payment ID",
            options: {
                filter: false,
                sort: true,
                display: false
            }
        }, {
            name: "PAY_MONTH_FRMT",
            label: "Pay Month",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "PAYMENT_DATE_FRMT",
            label: "Payment Date",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "PAYMENT_TYPE_DESC",
            label: "Payment Type",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "PAYMENT_MODE_DESC",
            label: "Payment Mode",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "EMPLOYEE_NAME",
            label: "Employee Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "TOTAL_AMOUNT",
            label: "Total Amount",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "LEAVES",
            label: "Leaves",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "DEDUCTIONS",
            label: "Deductions",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "PAID_AMOUNT",
            label: "Paid Amount",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "BALANCE_AMOUNT",
            label: "Balance Amount",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "REMARKS",
            label: "Remarks",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "ACTIONS",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    //const tableData = tableMeta.tableData;
                    const tableData = data?.result;
                    const rowIndex = tableMeta.rowIndex;
                    // console.log('Selcted tableMeta: ', tableMeta);
                    return <>
                        <Tooltip title="Edit">
                            <IconButton
                                style={{ color: "#343a40" }}
                                onClick={() => {
                                    setPayment(tableData[rowIndex]);
                                    setTransaction('UPDATE');
                                    setFormDialogOpen(true);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton
                                style={{ color: "#dc3545" }}
                                onClick={() => {
                                    setPaymentID(tableData[rowIndex].PAYMENT_ID);
                                    setDeleteDialogOpen(true);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            }
        },
    ];

    let tableData = [];
    if (data) {
        //console.log('Payments list:', data.result);
        tableData = data.result;
    }

    const tableOptions = {
        ...globalTableOptions,
        downloadOptions: {
            filename: "employee-payment-list.csv"
        },
        customTableBodyFooterRender: (opts) => {
            // console.log('[Payment] customTableBodyFooterRender:>> ', opts?.data);
            return <TableFooter>
                <TableRow style={{ whiteSpace: "nowrap",}}>
                    <TableCell colSpan={4} >
                    </TableCell>
                    <TableCell
                        style={{
                            position: "sticky",
                            left: "0",
                            zIndex: 101,
                        }}
                    >
                        <Typography variant="subtitle2" >
                            TOTAL
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="subtitle2">
                            {"₹ " + opts?.data.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.data[6]), 0).toFixed(2)}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="subtitle2">
                            {opts?.data.reduce((accumulator, currentValue) => accumulator + parseFloat((currentValue.data[7] === null || currentValue.data[7] === 'NA') ? 0 : currentValue.data[7]), 0).toFixed(2)}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="subtitle2">
                            {"₹ " + opts?.data.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.data[8] === null ? 0 : currentValue.data[8]), 0).toFixed(2)}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="subtitle2">
                            {"₹ " + opts?.data.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.data[9] === null ? 0 : currentValue.data[9]), 0).toFixed(2)}
                        </Typography>
                    </TableCell>
                    <TableCell colSpan={3}>
                        <Typography variant="subtitle2">
                            {"₹ " + opts?.data.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.data[10] === null ? 0 : currentValue.data[10]), 0).toFixed(2)}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableFooter>;
        }        
    };

    return <div style={{ position: "relative" }}>
        {/* loader */}
        {(loading) && <Loader />}

        {/* search container */}
        <Paper variant="elevation" elevation={3} className={classes.paper}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            required
                            id="payment-from-date"
                            // placeholder="05/11/2020"
                            label="From Date"
                            value={paymentFromDate}
                            onChange={date => setPaymentFromDate(date)}
                            format="DD/MM/yyyy"
                        />
                    </MuiPickersUtilsProvider></Grid>
                <Grid item xs={12} md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            required
                            id="payment-to-date"
                            //placeholder="05/11/2020"
                            label="To Date"
                            value={paymentToDate}
                            onChange={date => setPaymentToDate(date)}
                            format="DD/MM/yyyy"
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={searchEmployeePayments}
                        style={{ marginTop: "10px" }}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>
        </Paper>

        {/* add new employee */}
        <Tooltip title="Add New">
            <Fab
                size="small"
                //color="#4caf50" 
                aria-label="add"
                onClick={() => {
                    setPayment(null);
                    setTransaction('CREATE');
                    setFormDialogOpen(true);
                }}
                style={{ float: "right", marginTop: "12px", marginRight: "12px", color: "#FFFFFF", backgroundColor: "#4caf50" }}
            >
                <AddIcon />
            </Fab>
        </Tooltip>

        {/* data table */}
        <MuiThemeProvider theme={globalTableTheme}>
            <MUIDataTable
                title={"Employee Payments"}
                data={tableData}
                columns={tableColumns}
                options={tableOptions}
            />
        </MuiThemeProvider>

        {/* snackbar */}
        <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleSnackbarClose}
        >
            <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
                {snackbarMessage}
            </Alert>
        </Snackbar>

        {/* create/update form */}
        {<PaymentForm
            formDialogOpen={formDialogOpen}
            handleFormDialogClose={handleFormDialogClose}
            handleEmployeePaymentsCRUD={handleEmployeePaymentsCRUD}
            transaction={transaction}
            payment={payment}
        />}

        {/* delete action dialog */}
        <Dialog
            open={deleteDialogOpen}
            onClose={handleDeleteDialogClose}
            aria-labelledby="delete-employee"
            aria-describedby="delete-dialog-description"
        >
            <div style={{ position: "relative" }}>
                {(deleting) && <Loader />}

                <DialogTitle id="delete-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        The employee payment will be deleted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={deleteEmployeePayment} variant="contained" color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </div>
        </Dialog>

    </div>
}

export default PaymentsList;