import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { Chip, Popover, Tooltip } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import { Switch, Route, useRouteMatch, Redirect, withRouter } from "react-router-dom";
import { useMutation, useQuery } from '@apollo/client';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Dashboard from '../Dashboard';
import Sites from '../Sites';
import { mainMenu, expensesMenu, employeeMenu, analysisMenu, mastersMenu } from './components/Menu';
import Parties from '../Parties';
import Materials from '../Materials';
import Machinery from '../Machinery';
import MaterialUsageTypes from '../MaterialUsageTypes';
import MaterialArrivals from '../MaterialArrivals';
import MaterialDispatches from '../MaterialDispatches';
import MaterialUsages from '../MaterialUsages';
import Fuel from '../Fuel';
import LabourExpenses from '../Labour';
import MachineryMaintenance from '../MachineryMaintenance';
import Expenses from '../Expenses';
import Accounting from '../Accounting';
import Employees from '../Employees';

import { LOCAL_SIGN_OUT } from "../../graphql/mutations";
import Analysis from '../Analysis';
import Loader from '../../components/Loader';
import UOM from '../UOM';
import { SEARCH_NOTIFICATIONS, SEARCH_NOTIFICATION_DETAILS } from '../../graphql/queries';
import Users from '../Users';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://chintamaniinfra.com/">
        Chintamani Infra
          </Link>{' '}
      {new Date().getFullYear()}
      {'. | Made with '}
      <i className="material-icons" style={{ color: "#ff1e7b", fontSize: 16 }}>favorite</i>
      {` by `}
      <Link style={{ color: "#ff1e7b" }} href="https://www.discriminant.in/">Discriminant Technologies, Pune</Link>
    </Typography>
  );
}


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    width: 'inherit',
    // justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    background: "#117893",
    //zIndex: theme.zIndex.drawer + 1,
    zIndex: 1300,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    overflow: "auto",
    height: "100%",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    overflowY: "auto",
    height: "100%",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    // height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const Home = (props) => {
  const classes = useStyles();
  let match = useRouteMatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openNotifications = Boolean(anchorEl);

  const [localSignout] = useMutation(LOCAL_SIGN_OUT);
  const { data: notifications } = useQuery(SEARCH_NOTIFICATIONS, { pollInterval: 60000 });
  const { data: notificationDetails } = useQuery(SEARCH_NOTIFICATION_DETAILS, { pollInterval: 60000 });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const showNotifications = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const hideNotifications = () => {
    setAnchorEl(null);
  };

  const handleSignout = () => {
    setLoading(true);
    localSignout()
      .then(resp => {
        console.log('Signout.');
        localStorage.removeItem('AUTH_TOKEN');
        localStorage.removeItem('EMAIL');
        localStorage.removeItem('FIRST_NAME');
        localStorage.removeItem('LAST_NAME');
        localStorage.removeItem('MOBILE_NUMBER');
        setLoading(false);
        props.history.push("/signin");
      }).catch(error => {
        console.log('Signout Error:', error.message);
        localStorage.removeItem('AUTH_TOKEN');
        localStorage.removeItem('EMAIL');
        localStorage.removeItem('FIRST_NAME');
        localStorage.removeItem('LAST_NAME');
        localStorage.removeItem('MOBILE_NUMBER');
        setLoading(false);
        props.history.push("/signin");
      });
  };

  return (
    <div className={classes.root}>
      {loading && <Loader />}

      <CssBaseline />

      <AppBar position="fixed" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          
          <Tooltip title="Expand Drawer">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <img src="https://chintamaniinfra.com/ci_logo.jpg" style={{ width: "75px", maxHeight: "50px", paddingRight: "10px" }} />
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Chintamani Infra Admin Portal
          </Typography>

          <Tooltip title="Notifications">
            <IconButton color="inherit" onClick={showNotifications}>
              <Badge badgeContent={notificationDetails?.result.length} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Tooltip>

          <Tooltip title="Sign out">
            <IconButton
              color="inherit"
              onClick={handleSignout}
            >
              <PowerSettingsNewIcon />
            </IconButton>
          </Tooltip>

        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <Chip
            icon={<MenuIcon />}
            label="Menu"
            color="primary"
            variant="outlined"
            style={{width: 'inherit'}}
          />
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainMenu(match, notifications)}</List>
        <Divider />
        <List>{expensesMenu(match, notifications)}</List>
        <Divider />
        <List>{analysisMenu(match)}</List>
        <Divider />
        <List>{employeeMenu(match)}</List>
        <Divider />
        <List>{mastersMenu(match)}</List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>

          <Switch>
            {/* main menu */}
            <Route exact path={`/home`}>
              <Redirect to={`${match.path}/dashboard`} />
            </Route>
            <Route exact path={`${match.path}/dashboard`}>
              <Dashboard />
            </Route>
            <Route exact path={`${match.path}/material-arrivals`}>
              <MaterialArrivals />
            </Route>
            <Route exact path={`${match.path}/material-dispatches`}>
              <MaterialDispatches />
            </Route>
            <Route exact path={`${match.path}/material-usages`}>
              <MaterialUsages />
            </Route>
            <Route exact path={`${match.path}/fuel`}>
              <Fuel />
            </Route>

            {/* expenses */}
            <Route exact path={`${match.path}/labour`}>
              <LabourExpenses />
            </Route>
            <Route exact path={`${match.path}/maintenance`}>
              <MachineryMaintenance />
            </Route>
            <Route exact path={`${match.path}/expenses`}>
              <Expenses />
            </Route>
            <Route exact path={`${match.path}/accounting`}>
              <Accounting />
            </Route>

            {/* employees */}
            <Route exact path={`${match.path}/employees`}>
              <Employees />
            </Route>

            {/* analysis */}
            <Route exact path={`${match.path}/analysis`}>
              <Analysis />
            </Route>

            {/* masters */}
            <Route exact path={`${match.path}/sites`}>
              <Sites />
            </Route>
            <Route exact path={`${match.path}/parties`}>
              <Parties />
            </Route>
            <Route exact path={`${match.path}/materials`}>
              <Materials />
            </Route>
            <Route exact path={`${match.path}/machinery`}>
              <Machinery />
            </Route>
            <Route exact path={`${match.path}/usage-types`}>
              <MaterialUsageTypes />
            </Route>
            <Route exact path={`${match.path}/uom`}>
              <UOM />
            </Route>
            <Route exact path={`${match.path}/app-users`}>
              <Users />
            </Route>
          </Switch>

          <Box pt={4}>
            <Copyright />
          </Box>

        </Container>
      </main>

      <Popover
        id="notifications"
        open={openNotifications}
        anchorEl={anchorEl}
        onClose={hideNotifications}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ top: '15px' }}
      >
        <List style={{
          width: '100%',
          maxWidth: '36ch',
        }}>
          {notificationDetails?.result.length > 0
            ? notificationDetails?.result.map((notification, index) => (
              <div key={index} >
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={notification.TITLE}
                    secondary={
                      <>
                        <Typography variant="body2" component="p" gutterBottom >
                          {notification.MESSAGE}
                        </Typography>
                        <Typography variant="caption" color="primary">
                          {notification.CREATE_DATE}
                          {"  "}
                          {notification.CREATE_TIME}
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
                <Divider variant="middle" component="li" />
              </div>
            ))
            : <ListItem alignItems="flex-start">
              <ListItemText secondary="No new notifications" />
            </ListItem>
          }
        </List>
      </Popover>

    </div>
  );
}


export default withRouter(Home);