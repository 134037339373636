import React from "react";
import { CircularProgress } from "@material-ui/core";
import './Loader.css';


// const Loader = () => {
//     return <div style={{
//         position: 'absolute',
//         zIndex: 1100,
//         top: 0,
//         left: 0,
//         width: '100%',
//         height: '100%',
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         background: 'rgba(255,255,255,0.8)'
//     }}>
//         <div class="outer">
//             <div class="ldio-7bfvs2czilc">
//                 <div></div>
//                 {/* <div></div>
//                 <div></div>
//                 <div></div> */}
//             </div>
//         </div >
//     </div>
// }


// const Loader = () => {
//     return <div class="outer">
//         <div class="ldio-7bfvs2czilc">
//             <div></div>
//             <div></div>
//             <div></div>
//             <div></div>
//         </div>
//     </div >
// }

const Loader = () => {
    return <div style={{
        position: 'absolute',
        zIndex: 1200,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255,255,255,0.8)'
    }}>
        <CircularProgress color="secondary" />
    </div>
}

export default Loader;
