import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useLazyQuery, useMutation } from '@apollo/client';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar, Tooltip } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { MuiThemeProvider } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { SEARCH_MACHINERY } from "../../../../graphql/queries";
import { MACHINERY_CRUD } from "../../../../graphql/mutations";
import { globalTableOptions, globalTableTheme } from "../../../../theme";
import Loader from "../../../../components/Loader";
import MachineryForm from "../MachineryForm";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MachineryList = () => {
    const [getMachineryList, { loading, data, error, refetch: refetchMachineryList }] = useLazyQuery(SEARCH_MACHINERY);
    const [MachineryCRUD] = useMutation(MACHINERY_CRUD);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [formDialogOpen, setFormDialogOpen] = useState(false);
    const [transaction, setTransaction] = useState("");
    const [machinery, setMachinery] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [machineryID, setMachineryID] = useState("");
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        getMachineryList();
    }, [getMachineryList]);

    const handleFormDialogClose = () => {
        setFormDialogOpen(false);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    if (error) {
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        setSnackbarMessage(error);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    };

    const handleMachineryCRUD = () => {
        setFormDialogOpen(false);
        //setMachinery(null);
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        setSnackbarMessage(`Machinery ${transaction === 'CREATE' ? 'added' : 'updated'} successfully!`);
        refetchMachineryList();
    };

    const deleteMachinery = () => {
        setDeleting(true);
        MachineryCRUD({
            variables: {
                "transaction": "LOGICAL_DELETE",
                "machinery": [{
                    "MACHINERY_ID": machineryID,
                }]
            }
        })
            .then(resp => {
                // const result = resp.data.result;
                // console.log('[DELETE MACHINERY] Resp: ', result);
                setDeleteDialogOpen(false);
                setSnackbarSeverity("success");
                setOpenSnackbar(true);
                setSnackbarMessage("Machinery removed successfully!");
                refetchMachineryList();
                setDeleting(false);
            })
            .catch(error => {
                console.log('[DELETE MACHINERY] Error:', error.message);
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
                setSnackbarMessage(JSON.parse(error.message)["0"].errorMACHINERY_ID);
                setDeleting(false);
            });
    };

    const tableColumns = [
        {
            name: "MACHINERY_ID",
            label: "Machinery ID",
            options: {
                filter: false,
                sort: true,
                display: false
            }
        }, {
            name: "MACHINERY_NAME",
            label: "Machinery Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "MACHINERY_DESC",
            label: "Machinery Description",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "MILEAGE_RANGE_FROM",
            label: "Mileage Range From",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "MILEAGE_RANGE_TO",
            label: "Mileage Range To",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "MILEAGE_UOM_NAME",
            label: "Mileage UOM",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "IS_ACTIVE",
            label: "Active",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "MACHINERY_IMAGE",
            label: "Machinery Image",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => <img alt="MACHINERY" src={value} style={{ height: "50px", width: "50px" }} />
            }
        },
        {
            name: "ACTIONS",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    //const tableData = tableMeta.tableData;
                    const tableData = data?.result;
                    const rowIndex = tableMeta.rowIndex;
                    return <>
                        <Tooltip title="Edit">
                            <IconButton
                                style={{ color: "#343a40" }}
                                onClick={() => {
                                    setMachinery(tableData[rowIndex]);
                                    setTransaction('UPDATE');
                                    setFormDialogOpen(true);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton
                                style={{ color: "#dc3545" }}
                                onClick={() => {
                                    setMachineryID(tableData[rowIndex].MACHINERY_ID);
                                    setDeleteDialogOpen(true);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            }
        },
    ];

    let tableData = [];
    if (data) {
        //console.log('Machinery list:', data.result);
        tableData = data.result;
    }

    const tableOptions = {
        ...globalTableOptions,
        downloadOptions: {
            filename: "machinery-list.csv"
        },
    };

    return <div style={{ position: "relative" }}>
        {(loading) && <Loader />}

        {/* add new machinery */}
        <Tooltip title="Add New">
            <Fab
                size="small"
                //color="#4caf50" 
                aria-label="add"
                onClick={() => {
                    setMachinery(null);
                    setTransaction('CREATE');
                    setFormDialogOpen(true);
                }}
                style={{ float: "right", marginTop: "12px", marginRight: "12px", color: "#FFFFFF", backgroundColor: "#4caf50" }}
            >
                <AddIcon />
            </Fab>
        </Tooltip>

        {/* data table */}
        <MuiThemeProvider theme={globalTableTheme}>
            <MUIDataTable
                title={"Machinery"}
                data={tableData}
                columns={tableColumns}
                options={tableOptions}
            />
        </MuiThemeProvider>

        {/* snackbar */}
        <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleSnackbarClose}
        >
            <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
                {snackbarMessage}
            </Alert>
        </Snackbar>

        {/* create/update form */}
        <MachineryForm
            formDialogOpen={formDialogOpen}
            handleFormDialogClose={handleFormDialogClose}
            handleMachineryCRUD={handleMachineryCRUD}
            transaction={transaction}
            machinery={machinery}
        />

        {/* delete action dialog */}
        <Dialog
            open={deleteDialogOpen}
            onClose={handleDeleteDialogClose}
            aria-labelledby="delete-machinery"
            aria-describedby="delete-dialog-description"
        >
            <div style={{ position: "relative" }}>
                {(deleting) && <Loader />}

                <DialogTitle id="delete-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        The machinery will be deleted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={deleteMachinery} variant="contained" color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    </div>

}

export default MachineryList;