import { gql } from '@apollo/client';

// local sign-in
export const LOCAL_SIGN_IN = gql`
  mutation LocalSignin {
    result: localSignin {
        FIRST_NAME
        LAST_NAME
        EMAIL
        MOBILE_NUMBER
        USER_ROLES
        AUTH_TOKEN
    }
  }
`;

// local sign-out
export const LOCAL_SIGN_OUT = gql`
  mutation LocalSignout {
    localSignout
  }
`;

// Users: Sign-up
export const LOCAL_SIGN_UP = gql`
  mutation LocalSignup($user: UserIp) {
    localSignup(user: $user) {
      USER_ID
      FIRST_NAME
      LAST_NAME
      EMAIL
      MOBILE_NUMBER
    }
  }
`;


// local password reset
export const LOCAL_PASSWORD_RESET = gql`
  mutation ResetPassword($email: String!, $password: String!) {
    resetPassword(EMAIL: $email, PASSWORD: $password)
  }
`;

// delete user account
export const DELETE_USER_ACCOUNT = gql`
  mutation DeleteUserAccount($email: String!) {
    deleteUserAccount(EMAIL: $email)
  }
`;

// update user profile
export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile($user: UserIp) {
    updateUserProfile(user: $user) 
  }
`;


// update material arrival challan rates
export const UPDATE_MATERIAL_ARRIVAL_CHALLAN_RATES = gql`
  mutation UpdateMaterialArrivalChallanRates(
    $rates: [MaterialArrivalChallanRatesIp!]!
  ) {
    result: UpdateMaterialArrivalChallanRates(rates: $rates)
  }
`;


// update material arrival challan status
export const UPDATE_MATERIAL_ARRIVAL_CHALLAN_STATUS = gql`
  mutation UpdateMaterialArrivalChallanStatus(
    $status: [MaterialArrivalChallanStatusIp!]!
  ) {
    result: UpdateMaterialArrivalChallanStatus(status: $status)
  }
`;

// update material dispatch challan rates
export const UPDATE_MATERIAL_DISPATCH_CHALLAN_RATES = gql`
  mutation UpdateMaterialDispatchChallanRates(
    $rates: [MaterialDispatchChallanRatesIp!]!
  ) {
    result: UpdateMaterialDispatchChallanRates(rates: $rates)
  }
`;

// update material dispatch challan status
export const UPDATE_MATERIAL_DISPATCH_CHALLAN_STATUS = gql`
  mutation UpdateMaterialDispatchChallanStatus(
    $status: [MaterialDispatchChallanStatusIp!]!
  ) {
    result: UpdateMaterialDispatchChallanStatus(status: $status)
  }
`;

// update material usage rates
export const UPDATE_MATERIAL_USAGE_RATES = gql`
  mutation UpdateMaterialUsageRates($rates: [MaterialUsageRatesIp!]!) {
    result: UpdateMaterialUsageRates(rates: $rates)
  }
`;

// update material usage status
export const UPDATE_MATERIAL_USAGE_STATUS = gql`
  mutation UpdateMaterialUsageStatus($status: [MaterialUsageStatusIp!]!) {
    result: UpdateMaterialUsageStatus(status: $status)
  }
`;

// update fuel usage status
export const UPDATE_FUEL_USAGE_STATUS = gql`
  mutation UpdateFuelUsageStatus($status: [FuelUsageStatusIp!]!) {
    result: UpdateFuelUsageStatus(status: $status)
  }
`;

// update fuel purchase status
export const UPDATE_FUEL_PURCHASE_STATUS = gql`
  mutation UpdateFuelPurchaseStatus($status: [FuelPurchaseStatusIp!]!) {
    result: UpdateFuelPurchaseStatus(status: $status)
  }
`;

// update labour expenses status
export const UPDATE_LABOUR_EXPENSES_STATUS = gql`
  mutation UpdateLabourExpensesStatus($status: [LabourExpensesStatusIp!]!) {
    result: UpdateLabourExpensesStatus(status: $status)
  }
`;

// update labour expenses total amount
export const UPDATE_LABOUR_EXPENSES_AMOUNT = gql`
  mutation UpdateLabourExpensesTotalAmount($amount: [LabourTotalAmountIp!]!) {
    result: UpdateLabourExpensesTotalAmount(amount: $amount)
  }
`;

// update machinery maintenance status
export const UPDATE_MACHINERY_MAINTENANCE_STATUS = gql`
  mutation UpdateMachineryMaintenanceStatus($status: [MachineryMaintenanceStatusIp!]!) {
    result: UpdateMachineryMaintenanceStatus(status: $status)
  }
`;

// update other expenses status
export const UPDATE_OTHER_EXPENSES_STATUS = gql`
  mutation UpdateOtherExpensesStatus($status: [OtherExpensesStatusIp!]!) {
    result: UpdateOtherExpensesStatus(status: $status)
  }
`;

// Sites: Create, Update, Delete
export const SITES_CRUD = gql`
  mutation SitesCRUD($transaction: TransactionTypes!, $sites: [SitesIp!]!) {
    result: SitesCRUD(transaction: $transaction, sites: $sites)
  }
`;

// Parties: Create, Update, Delete
export const PARTY_CRUD = gql`
  mutation PartiesCRUD($transaction: TransactionTypes!, $parties: [PartiesIp!]!) {
    result: PartiesCRUD(transaction: $transaction, parties: $parties)
  }
`;

// Materials: Create, Update, Delete
export const MATERIALS_CRUD = gql`
  mutation MaterialsCRUD($transaction: TransactionTypes!, $materials: [MaterialsIp!]!) {
    result: MaterialsCRUD(transaction: $transaction, materials: $materials)
  }
`;

// Machinery: Create, Update, Delete
export const MACHINERY_CRUD = gql`
  mutation MachineryCRUD($transaction: TransactionTypes!, $machinery: [MachineryIp!]!) {
    result: MachineryCRUD(transaction: $transaction, machinery: $machinery)
  }
`;

// Material Usage Types: Create, Update, Delete
export const MATERIAL_USAGE_TYPES_CRUD = gql`
  mutation MaterialUsageTypesCRUD($transaction: TransactionTypes!, $types: [MaterialUsageTypesIp!]!) {
    result: MaterialUsageTypesCRUD(transaction: $transaction, types: $types)
  }
`;

// Employees: Create, Update, Delete
export const EMPLOYEES_CRUD = gql`
  mutation EmployeesCRUD($transaction: TransactionTypes!, $employees: [EmployeesIp!]!) {
    result: EmployeesCRUD(transaction: $transaction, employees: $employees)
  }
`;

// UOM: Create, Update, Delete
export const UOM_CRUD = gql`
  mutation UOMCRUD($transaction: TransactionTypes!, $uom: [UOMIp!]!) {
    result: UOMCRUD(transaction: $transaction, uom: $uom)
  }
`;

// Employee Payments: Create, Update, Delete
export const EMPLOYEE_PAYMENTS_CRUD = gql`
  mutation EmployeePaymentsCRUD($transaction: TransactionTypes!, $payments: [EmployeePaymentsIp!]!) {
    result: EmployeePaymentsCRUD(transaction: $transaction, payments: $payments)
  }
`;

// Notifications: Delete
export const DELETE_SEEN_NOTIFICATIONS = gql`
  mutation DeleteSeenNotifications($entryType: String!){
    deleteSeenNotifications(
      ENTRY_TYPE: $entryType
    )
  }
`;