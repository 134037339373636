import axios from "axios";

import { getAPI } from "../api";

export const deleteSeenNotifications = async (entryType) => {
    const token = localStorage.getItem('AUTH_TOKEN');
    const api = await getAPI();

    axios({
        url: api,
        method: 'post',
        headers: {
            "Authorization": token ? `Bearer ${token}` : "",
        },
        data: {
            query: `
                mutation DeleteSeenNotifications($entryType: String!){
                    deleteSeenNotifications(
                    ENTRY_TYPE: $entryType
                    )
                }
            `,
            variables: {
                entryType: entryType
            }
        },
    })
        // .then(resp => console.log('[notifications] delete: ', resp))
        .catch(error => console.log('[notifications] delete error: ', error));
}