import { gql } from '@apollo/client';

// search sites/plants
export const SEARCH_SITES = gql`
  query SearchSites($siteID: String, $siteName: String, $siteType: String) {
    result: searchSites(
      SITE_ID: $siteID
      SITE_NAME: $siteName
      SITE_TYPE: $siteType
    ) {
      SITE_ID
      SITE_NAME
      SITE_TYPE
      SITE_DESC
      SITE_IMAGE
      DISPLAY_ORDER
      IS_ACTIVE
    }
  }  
`;

// search total amount spent per site
export const SEARCH_TOTAL_AMOUNT_SPENT = gql`
  query SearchAmountSpentPerSite(
    $siteID: String
    $fromDate: String
    $toDate: String
  ) {
    result: searchAmountSpentPerSite(
      SITE_ID: $siteID
      FROM_DATE: $fromDate
      TO_DATE: $toDate
    ) {
      SITE_ID
      SITE_NAME
      ITEM_ID
      ITEM_NAME
      TOTAL_AMOUNT
    }
  }
`;

// search details of total amount spent per site
export const SEARCH_TOTAL_AMOUNT_SPENT_DETAILS = gql`
  query SearchDetailsOfAmountSpentPerSite(
    $siteID: String
    $itemID: String
    $fromDate: String
    $toDate: String
  ) {
    result: searchDetailsOfAmountSpentPerSite(
      SITE_ID: $siteID
      ITEM_ID: $itemID
      FROM_DATE: $fromDate
      TO_DATE: $toDate
    ) {
      SITE_ID
      SITE_NAME
      ITEM_ID
      ITEM_NAME
      ITEM_QUANTITY
      UOM_ID
      UOM_NAME
      ITEM_RATE
      TOTAL_AMOUNT
      EXECUTION_DATE
      EXECUTION_DATE_FRMT
      ITEM_SECTION
    }
  }
`;

// search  parties/suppliers/customers
export const SEARCH_PARTIES = gql`
  query SearchParties($partyID: String, $partyName: String) {
    result: searchParties(PARTY_ID: $partyID, PARTY_NAME: $partyName) {
      PARTY_ID
      PARTY_NAME
      PARTY_TYPE
      PRIMARY_CONTACT
      PARTY_EMAIL
      PARTY_CONTACT_NOS
      ADDRESS
      IS_ACTIVE
    }
  }
`;

// search materials
export const SEARCH_MATERIALS = gql`
  query SearchMaterials(
    $materialID: String
    $materialName: String
    $materialType: String
    $isActive: String
  ) {
    result: searchMaterials(
      MATERIAL_ID: $materialID
      MATERIAL_NAME: $materialName
      MATERIAL_TYPE: $materialType
      IS_ACTIVE: $isActive
    ) {
      MATERIAL_ID
      MATERIAL_NAME
      MATERIAL_DESC
      MATERIAL_IMAGE
      UOM_ID
      UOM_NAME
      IS_ACTIVE
      TRACK_USAGE
      MATERIAL_TYPE
      MATERIAL_TYPE_DESC
    }
  }
`;

// search machinery
export const SEARCH_MACHINERY = gql`
  query SearchMachinery(
    $machineryID: String
    $macineryName: String
    $machineryDesc: String
  ) {
    result: searchMachinery(
      MACHINERY_ID: $machineryID
      MACHINERY_NAME: $macineryName
      MACHINERY_DESC: $machineryDesc
    ) {
      MACHINERY_ID
      MACHINERY_NAME
      MACHINERY_DESC
      MACHINERY_IMAGE
      MILEAGE_RANGE_FROM
      MILEAGE_RANGE_TO
      MILEAGE_UOM
      MILEAGE_UOM_NAME
      IS_ACTIVE
    }
  }
`;

// search material usage types
export const SEARCH_MATERIAL_USAGE_TYPES = gql`
  query SearchMaterialUsageTypes(
    $typeID: String
    $typeDesc: String
    $materialID: String
  ) {
    result: searchMaterialUsageTypes(
      USAGE_TYPE_ID: $typeID
      USAGE_TYPE_DESC: $typeDesc
      MATERIAL_ID: $materialID
    ) {
      USAGE_TYPE_ID
      USAGE_TYPE_DESC
      MATERIAL_ID
      MATERIAL_NAME
    }
  }
`;

// search material arrivals
export const SEARCH_MATERIAL_ARRIVALS = gql`
  query SearchMaterialArrivals(
    $arrivalID: String
    $siteID: String
    $materialType: String
    $materialTypeID: String
    $vehicleNo: String
    $partyID: String
    $challanNo: String
    $status: String
    $createdBy: String
    $arrivalFromDate: String
    $arrivalToDate: String
  ) {
    result: searchMaterialArrivals(
      ARRIVAL_ID: $arrivalID
      SITE_ID: $siteID
      MATERIAL_TYPE: $materialType
      MATERIAL_ID: $materialTypeID
      VEHICLE_NO: $vehicleNo
      PARTY_ID: $partyID
      CHALLAN_NO: $challanNo
      STATUS: $status
      CREATED_BY: $createdBy
      ARRIVAL_FROM_DATE: $arrivalFromDate
      ARRIVAL_TO_DATE: $arrivalToDate
    ) {
      ARRIVAL_ID
      ARRIVAL_DATE
      ARRIVAL_DATE_FRMT
      ARRIVAL_TIME
      ARRIVAL_TIME_FRMT
      SITE_ID
      SITE_NAME
      VEHICLE_NO
      MATERIAL_TYPE
      MATERIAL_TYPE_DESC
      MATERIAL_ID
      MATERIAL_NAME
      QUANTITY
      UOM_ID
      UOM_NAME
      PARTY_ID
      PARTY_NAME
      CHALLAN_NO
      STATUS
      RATE
      TOTAL_AMOUNT
      REMARKS
      USER_NAME
      IMAGES
    }
  }
`;


// search material dispatches
export const SEARCH_MATERIAL_DISPATCHES = gql`
  query SearchMaterialDispatches(
    $dispatchID: String
    $siteID: String
    $materialType: String
    $materialID: String
    $vehicleNo: String
    $partyID: String
    $challanNo: String
    $status: String
    $createdBy: String
    $dispatchFromDate: String
    $dispatchToDate: String
  ) {
    result: searchMaterialDispatches(
      DISPATCH_ID: $dispatchID
      SITE_ID: $siteID
      MATERIAL_TYPE: $materialType
      MATERIAL_ID: $materialID
      VEHICLE_NO: $vehicleNo
      PARTY_ID: $partyID
      CHALLAN_NO: $challanNo
      STATUS: $status
      CREATED_BY: $createdBy
      DISPATCH_FROM_DATE: $dispatchFromDate
      DISPATCH_TO_DATE: $dispatchToDate
    ) {
      DISPATCH_ID
      DISPATCH_DATE
      DISPATCH_DATE_FRMT
      DISPATCH_TIME
      DISPATCH_TIME_FRMT
      SITE_ID
      SITE_NAME
      VEHICLE_NO
      MATERIAL_ID
      MATERIAL_NAME
      QUANTITY
      UOM_ID
      UOM_NAME
      PARTY_ID
      PARTY_NAME
      CHALLAN_NO
      STATUS
      RATE
      TOTAL_AMOUNT
      USER_NAME
      MATERIAL_TYPE
      MATERIAL_TYPE_DESC
      REMARKS
      IMAGES
    }
  }
`;


// search material usages
export const SEARCH_MATERIAL_USAGES = gql`
  query SearchMaterialUsages(
    $usageId: String
    $siteId: String
    $materialType: String
    $materialId: String
    $usageTypeId: String
    $status: String
    $createdBy: String
    $usageFromDate: String
    $usageToDate: String
  ) {
    result: searchMaterialUsages(
      USAGE_ID: $usageId
      SITE_ID: $siteId
      MATERIAL_TYPE: $materialType
      MATERIAL_ID: $materialId
      USAGE_TYPE_ID: $usageTypeId
      STATUS: $status
      CREATED_BY: $createdBy
      USAGE_FROM_DATE: $usageFromDate
      USAGE_TO_DATE: $usageToDate
    ) {
      USAGE_ID
      USAGE_DATE
      USAGE_DATE_FRMT
      USAGE_TIME
      USAGE_TIME_FRMT
      SITE_ID
      SITE_NAME
      MATERIAL_ID
      MATERIAL_NAME
      USAGE_TYPE_ID
      USAGE_TYPE_DESC
      QUANTITY
      UOM_ID
      UOM_NAME
      STATUS
      RATE
      TOTAL_AMOUNT      
      USER_NAME
      MATERIAL_TYPE
      MATERIAL_TYPE_DESC
      REMARKS
      IMAGES
    }
  }
`;


// search fuel usages
export const SEARCH_FUEL_USAGES = gql`
  query SearchFuelUsages(
    $usageId: String
    $siteId: String
    $machineryId: String
    $fuelType: String
    $status: String
    $createdBy: String
    $usageFromDate: String
    $usageToDate: String
  ) {
    result: searchFuelUsages(
      USAGE_ID: $usageId
      SITE_ID: $siteId
      MACHINERY_ID: $machineryId
      FUEL_TYPE: $fuelType
      STATUS: $status
      CREATED_BY: $createdBy
      USAGE_FROM_DATE: $usageFromDate
      USAGE_TO_DATE: $usageToDate
    ) {
      USAGE_ID
      USAGE_DATE
      USAGE_DATE_FRMT
      USAGE_TIME
      USAGE_TIME_FRMT
      SITE_ID
      SITE_NAME
      MACHINERY_ID
      MACHINERY_NAME
      FUEL_TYPE
      QUANTITY
      UOM_ID
      UOM_NAME
      METER_READING
      READING_UOM_ID
      READING_UOM_NAME
      STATUS
      REMARKS
      USER_NAME
      IMAGES
      PRV_QUANTITY
      PRV_UOM
      PRV_METER_READING
      PRV_READING_UOM
      CURRENT_MILEAGE
      MILEAGE_STATUS
    }
  }
`;

// search fuel purchases
export const SEARCH_FUEL_PURCHASES = gql`
  query SearchFuelPurchases(
    $purchaseId: String
    $siteId: String
    $fuelType: String
    $status: String
    $createdBy: String
    $purchaseFromDate: String
    $purchaseToDate: String
  ) {
    result: searchFuelPurchases(
      PURCHASE_ID: $purchaseId
      SITE_ID: $siteId
      FUEL_TYPE: $fuelType
      STATUS: $status
      CREATED_BY: $createdBy
      PURCHASE_FROM_DATE: $purchaseFromDate
      PURCHASE_TO_DATE: $purchaseToDate
    ) {
      PURCHASE_ID
      PURCHASE_DATE
      PURCHASE_DATE_FRMT
      PURCHASE_TIME
      PURCHASE_TIME_FRMT
      SITE_ID
      SITE_NAME
      FUEL_TYPE
      QUANTITY
      UOM_ID
      UOM_NAME
      RATE
      TOTAL_AMOUNT
      STATUS
      REMARKS
      USER_NAME
      IMAGES
    }
  }
`;


// search labour expenses
export const SEARCH_LABOUR_EXPENSES = gql`
  query SearchLabourExpenses(
    $expenseId: String
    $siteId: String
    $labourTypeId: String
    $mukadam: String
    $status: String
    $createdBy: String
    $expensesFromDate: String
    $expensesToDate: String
  ) {
    result: searchLabourExpenses(
      EXPENSE_ID: $expenseId
      SITE_ID: $siteId
      LABOUR_TYPE_ID: $labourTypeId
      MUKADAM: $mukadam
      STATUS: $status
      CREATED_BY: $createdBy
      EXPENSES_FROM_DATE: $expensesFromDate
      EXPENSES_TO_DATE: $expensesToDate
    ) {
      EXPENSE_ID
      EXPENSE_DATE
      EXPENSE_DATE_FRMT
      EXPENSE_TIME
      EXPENSE_TIME_FRMT
      SITE_ID
      SITE_NAME
      LABOUR_TYPE_ID
      LABOUR_TYPE_NAME
      MUKADAM
      MUKADAM_NAME
      LABOUR_COUNT
      PER_DAY_RATE
      TOTAL_AMOUNT
      UOM_ID
      UOM_NAME
      PAID_AMOUNT
      BALANCE_AMOUNT
      STATUS
      USER_NAME
      REMARKS
    }
  }
`;


// search machinery maintenance
export const SEARCH_MACHINERY_MAINTENANCE = gql`
  query SearchMachineryMaintenance(
    $maintenanceId: String
    $siteId: String
    $machineryId: String
    $status: String
    $createdBy: String
    $maintenanceFromDate: String
    $maintenanceToDate: String
  ) {
    result: searchMachineryMaintenance(
      MAINTENANCE_ID: $maintenanceId
      SITE_ID: $siteId
      MACHINERY_ID: $machineryId
      STATUS: $status
      CREATED_BY: $createdBy
      MAINTENANCE_FROM_DATE: $maintenanceFromDate
      MAINTENANCE_TO_DATE: $maintenanceToDate
    ) {
      MAINTENANCE_ID
      MAINTENANCE_DATE
      MAINTENANCE_DATE_FRMT
      MAINTENANCE_TIME
      MAINTENANCE_TIME_FRMT
      SITE_ID
      SITE_NAME
      MACHINERY_ID
      MACHINERY_NAME
      PART_NAME
      NO_OF_PARTS
      AMOUNT
      STATUS
      USER_NAME
      REMARKS
      IMAGES
    }
  }
`;


// search other expenses
export const SEARCH_OTHER_EXPENSES = gql`
  query SearchOtherExpenses(
    $expenseId: String
    $siteId: String
    $status: String
    $createdBy: String
    $expenseFromDate: String
    $expenseToDate: String
  ) {
    result: searchOtherExpenses(
      EXPENSE_ID: $expenseId
      SITE_ID: $siteId
      STATUS: $status
      CREATED_BY: $createdBy
      EXPENSE_FROM_DATE: $expenseFromDate
      EXPENSE_TO_DATE: $expenseToDate
    ) {
      EXPENSE_ID
      EXPENSE_DATE
      EXPENSE_DATE_FRMT
      EXPENSE_TIME
      EXPENSE_TIME_FRMT
      SITE_ID
      SITE_NAME
      EXPENSE_TYPE
      QUANTITY
      AMOUNT
      STATUS
      USER_NAME
      REMARKS
      IMAGES
    }
  }
`;


// search account entries
export const SEARCH_ACCOUNT_ENTRIES = gql`
  query SearchAccountEntries(
    $entryId: String
    $siteId: String
    $entryType: String
    $createdBy: String
    $entryFromDate: String
    $entryToDate: String
  ) {
    result: searchAccountEntries(
      ENTRY_ID: $entryId
      SITE_ID: $siteId
      ENTRY_TYPE: $entryType
      CREATED_BY: $createdBy
      ENTRY_FROM_DATE: $entryFromDate
      ENTRY_TO_DATE: $entryToDate
    ) {
      ENTRY_ID
      ENTRY_DATE
      ENTRY_DATE_FRMT
      SITE_ID
      SITE_NAME
      ENTRY_TYPE
      ENTRY_TYPE_DESC
      DESCRIPTION
      AMOUNT
      USER_NAME
      REMARKS
      IMAGES
    }
  }
`

// search fdr entries
export const SEARCH_FDR_ENTRIES = gql`
  query SearchFDREntries(
    $fdrId: String
    $siteId: String
    $nameOfWork: String
    $createdBy: String
    $fdrFromDate: String
    $fdrToDate: String
  ) {
    result: searchFDREntries(
      FDR_ID: $fdrId
      SITE_ID: $siteId
      NAME_OF_WORK: $nameOfWork
      CREATED_BY: $createdBy
      FDR_FROM_DATE: $fdrFromDate
      FDR_TO_DATE: $fdrToDate
    ) {
      FDR_ID
      FDR_DATE
      FDR_DATE_FRMT
      SITE_ID
      SITE_NAME
      NAME_OF_WORK
      AMOUNT
      MATURITY_DATE
      MATURITY_DATE_FRMT
      REMARKS
      IMAGES
    }
  }
`;


// search employees
export const SEARCH_EMPLOYEES = gql`
  query SearchEmployees(
    $employeeId: String
    $employeeName: String
    $email: String
    $mobileNumber: String
    $employeeStatus: String
    $employmentType: String
  ) {
    result: searchEmployees(
      EMPLOYEE_ID: $employeeId
      EMPLOYEE_NAME: $employeeName
      EMAIL: $email
      MOBILE_NUMBER: $mobileNumber
      EMPLOYEE_STATUS: $employeeStatus
      EMPLOYMENT_TYPE: $employmentType
    ) {
      EMPLOYEE_ID
      FIRST_NAME
      LAST_NAME
      HIRE_DATE
      HIRE_DATE_FRMT
      EMPLOYEE_STATUS
      EMPLOYMENT_TYPE
      EMPLOYEE_ROLE
      EMPLOYEE_ROLE_DESC
      SEATING_LOCATION
      SEATING_LOCATION_DESC
      EXPERIENCE_IN_YEARS
      SALARY
      EMAIL
      MOBILE_NUMBER
      BIRTH_DATE
      BIRTH_DATE_FRMT
      MARITAL_STATUS
      PERMANENT_ADDRESS
      CORRESPONDANCE_ADDRESS
      GENDER
      ENTITLED_LEAVES
      RELIEVING_DATE
      RELIEVING_DATE_FRMT
      PHOTO_URL
      DOCUMENTS
      CREATE_DATE_FRMT
      CREATED_BY
    }
  }
`;


// search employee payments
export const SEARCH_EMPLOYEE_PAYMENTS = gql`
  query SearchEmployeePayments(
    $paymentId: String
    $payMonth: String
    $employeeId: String
    $employeeName: String
    $paymentFromDate: String
    $paymentToDate: String
  ) {
    result: searchEmployeePayments(
      PAYMENT_ID: $paymentId
      PAY_MONTH: $payMonth
      EMPLOYEE_ID: $employeeId
      EMPLOYEE_NAME: $employeeName
      PAYMENT_FROM_DATE: $paymentFromDate
      PAYMENT_TO_DATE: $paymentToDate
    ) {
      PAYMENT_ID
      PAY_MONTH
      PAY_MONTH_FRMT
      PAYMENT_DATE
      PAYMENT_DATE_FRMT
      PAYMENT_TYPE
      PAYMENT_TYPE_DESC
      PAYMENT_MODE
      PAYMENT_MODE_DESC
      EMPLOYEE_ID
      EMPLOYEE_NAME
      TOTAL_AMOUNT
      LEAVES
      DEDUCTIONS
      PAID_AMOUNT
      BALANCE_AMOUNT
      REMARKS
      CREATE_DATE_FRMT
      CREATED_BY
    }
  }
`;

// search uom
export const SEARCH_UOM = gql`
  query SearchUOM(
    $uomID: String
    $uomName: String
    $uomDesc: String
  ) {
    result: searchUOM(
      UOM_ID: $uomID
      UOM_NAME: $uomName
      UOM_DESC: $uomDesc
    ) {
      UOM_ID
      UOM_NAME
      UOM_DESC
      UQC
    }
  }
`;

// search notifications
export const SEARCH_NOTIFICATIONS = gql`
  query SearchNotifications {
    result: searchNotifications {
      ENTRY_TYPE
      TOTAL_NOTIFICATIONS
    }
  }
`;

// search notification details
export const SEARCH_NOTIFICATION_DETAILS = gql`
  query SearchNotificationDetails{
    result: searchNotificationDetails {
      SITE_ID
      ENTRY_TYPE
      TITLE
      MESSAGE
      CREATED_BY
      CREATE_DATE
      CREATE_TIME
    }
  }
`;

// load dashboard
export const LOAD_DASHBOARD = gql`
  query LoadDashboard(
    $siteID: String
    $fronDate: String
    $toDate: String
  ) {
    amount: searchTotalAmountSpent(
      SITE_ID: $siteID
      FROM_DATE: $fronDate
      TO_DATE: $toDate
    ) {
      SITE_ID
      SITE_NAME
      TOTAL_AMOUNT
    }
    
    activities: searchRecentOperations {
      ENTRY_ID
      ENTRY_DATE
      ENTRY_TYPE
      SITE_ID
      SITE_NAME
      ITEM_NAME
      ITEM_QUANTITY
      ITEM_RATE
      TOTAL_AMOUNT
      CREATED_BY
      CREATE_DATE
      CREATE_TIME
    }  
  }
`;

// search application user profiles
export const SEARCH_USER_PROFILES = gql`
  query SearchUserProfiles(
    $userID: String
    $firstName: String
    $lastName: String
    $email: String
    $mobileNumber: String
  ) {
    result: searchUserProfiles(
      USER_ID: $userID
      FIRST_NAME: $firstName
      LAST_NAME: $lastName
      EMAIL: $email
      MOBILE_NUMBER: $mobileNumber
    ) {
      USER_ID
      FIRST_NAME
      LAST_NAME
      EMAIL
      MOBILE_NUMBER
      USER_ROLES
      USER_PERMISSIONS
    }
  }
`;

// search material stock
export const SEARCH_MATERIAL_STOCK = gql`
  query SearchMaterialStock($siteID: String, $materialID: String) {
    result: searchMaterialStock(SITE_ID: $siteID, MATERIAL_ID: $materialID) {
      SITE_ID
      SITE_NAME
      MATERIAL_TYPE
      MATERIAL_TYPE_DESC
      MATERIAL_ID
      MATERIAL_NAME
      REMAINING_QUANTITY
      UOM_NAME
    }
  }
`;


// ------------------ Drop-downs ----------------------------
// populate material arrival ddls
export const POPULATE_MATERIAL_ARRIVAL_DDL = gql`
  query PopulateMaterialArrivalDDL (
    $statusType:String
  ){
    sites: populateDDL(ddlName: SITE_TYPES, paraArray: []) {
      CODE
      DESC
    }
    parties: populateDDL(ddlName: PARTIES, paraArray: []) {
      CODE
      DESC
    }
    material_type: populateDDL(ddlName: MATERIAL_TYPES, paraArray: []) {
      CODE
      DESC
    }
    status: populateDDL(ddlName: STATUS_TYPES, paraArray: [$statusType]) {
      CODE
      DESC
    }
  }
`;


// populate site types ddl
export const POPULATE_SITE_TYPES_DDL = gql`
  query PopulateSiteTypesDDL {
    types: populateDDL(ddlName: SITE_TYPES, paraArray: []) {
      CODE
      DESC
    }
  }
`;

// populate status ddl
export const POPULATE_STATUS_DDL = gql`
  query PopulateStatusDDL (
    $statusType:String
  ){
    status: populateDDL(ddlName: STATUS_TYPES, paraArray: [$statusType]) {
      CODE
      DESC
    }
  }
`;

// populate sites ddl
export const POPULATE_SITES_DDL = gql`
  query PopulateSitesDDL (
    $siteType:String
  ){
    sites: populateDDL(ddlName: SITES, paraArray: [$siteType]) {
      CODE
      DESC
    }
  }
`;

// populate material types ddl
export const POPULATE_MATERIAL_TYPES_DDL = gql`
  query PopulateMaterialTypesDDL {
    materialTypes: populateDDL(ddlName: MATERIAL_TYPES, paraArray: []) {
      CODE
      DESC
    }
  }
`;

// populate uom ddl
export const POPULATE_UOM_DDL = gql`
  query PopulateUOMDDL {
    uom: populateDDL(ddlName: UOM, paraArray: []) {
      CODE
      DESC
    }
  }
`;

// populate materials ddl
export const POPULATE_MATERIALS_DDL = gql`
  query PopulateMaterialsDDL (
    $materialType:String
  ){
    materials: populateDDL(ddlName: MATERIALS, paraArray: [$materialType]) {
      CODE
      DESC
    }
  }
`;


// populate employee payments ddls
export const POPULATE_EMPLOYEE_PAYMENTS_DDL = gql`
  query PopulateEmployeePaymentsDDL {
    employees: populateDDL(ddlName: EMPLOYEES, paraArray: []) {
      CODE
      DESC
    }
    paymentTypes: populateDDL(ddlName: PAYMENT_TYPES, paraArray: []) {
      CODE
      DESC
    }
    paymentModes: populateDDL(ddlName: PAYMENT_MODES, paraArray: []) {
      CODE
      DESC
    }
  }
`;

// populate employee ddl
export const POPULATE_EMPLOYEE_DDL = gql`
  query PopulateEmployeeDDL {
    employees: populateDDL(ddlName: EMPLOYEES, paraArray: []) {
      CODE
      DESC
    }
  }
`;
