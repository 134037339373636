import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useLazyQuery, useMutation } from '@apollo/client';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemText, Snackbar, TableCell, TableRow, Tooltip } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreIcon from '@material-ui/icons/More';
import { MuiThemeProvider } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';

import { SEARCH_PARTIES } from "../../../../graphql/queries";
import { PARTY_CRUD } from "../../../../graphql/mutations";
import { globalTableOptions, globalTableTheme } from "../../../../theme";
import Loader from "../../../../components/Loader";
import PartyForm from "../PartyForm";

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PartyList = () => {
    const [getPartyList, { loading, data, error, refetch: refetchPartyList }] = useLazyQuery(SEARCH_PARTIES);
    const [PartiesCRUD] = useMutation(PARTY_CRUD);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [formDialogOpen, setFormDialogOpen] = useState(false);
    const [transaction, setTransaction] = useState("");
    const [party, setParty] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [partyID, setPartyID] = useState("");
    const [deleting, setDeleting] = useState(false);
    const [viewDialogOpen, setViewDialogOpen] = useState(false);

    useEffect(() => {
        getPartyList();
    }, [getPartyList]);

    const handleFormDialogClose = () => {
        setFormDialogOpen(false);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    const handleViewDialogClose = () => {
        setViewDialogOpen(false);
    };

    if (error) {
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        setSnackbarMessage(error);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    };

    const handlePartyCRUD = () => {
        setFormDialogOpen(false);
        //setParty(null);
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        setSnackbarMessage(`Party ${transaction === 'CREATE' ? 'added' : 'updated'} successfully!`);
        refetchPartyList();
    };

    const deleteParty = () => {
        setDeleting(true);
        PartiesCRUD({
            variables: {
                "transaction": "LOGICAL_DELETE",
                "parties": [{
                    "PARTY_ID": partyID,
                }]
            }
        })
            .then(resp => {
                // const result = resp.data.result;
                // console.log('[DELETE Party] Resp: ', result);
                setDeleteDialogOpen(false);
                setSnackbarSeverity("success");
                setOpenSnackbar(true);
                setSnackbarMessage("Party removed successfully!");
                refetchPartyList();
                setDeleting(false);
            })
            .catch(error => {
                console.log('[DELETE Party] Error:', error.message);
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
                setSnackbarMessage(JSON.parse(error.message)["0"].errorPARTY_ID);
                setDeleting(false);
            });
    };

    const tableColumns = [
        {
            name: "PARTY_ID",
            label: "Party ID",
            options: {
                filter: false,
                sort: true,
                display: false
            }
        }, {
            name: "PARTY_TYPE",
            label: "Party Type",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "PARTY_NAME",
            label: "Party Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "PARTY_EMAIL",
            label: "Party Email",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "PARTY_CONTACT_NOS",
            label: "Contact Nos",
            options: {
                filter: false,
                viewColumns: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const rowData = JSON.parse(value);
                    return <>
                        <Typography variant="inherit">Work: {rowData.WORK_PHONE}</Typography> <br />
                        <Typography variant="inherit">Mobile: {rowData.MOBILE}</Typography>
                    </>
                }
            }
        },
        {
            name: "IS_ACTIVE",
            label: "Active",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "ACTIONS",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    //const tableData = tableMeta.tableData;
                    const tableData = data?.result;
                    const rowIndex = tableMeta.rowIndex;
                    return <>
                        <Tooltip title="Edit">
                            <IconButton
                                style={{ color: "#343a40" }}
                                onClick={() => {
                                    setParty(tableData[rowIndex]);
                                    setTransaction('UPDATE');
                                    setFormDialogOpen(true);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton
                                style={{ color: "#dc3545" }}
                                onClick={() => {
                                    setPartyID(tableData[rowIndex].PARTY_ID);
                                    setDeleteDialogOpen(true);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View More">
                            <IconButton
                                style={{ color: "#28a745" }}
                                onClick={() => {
                                    setParty(tableData[rowIndex]);
                                    setViewDialogOpen(true);
                                }}
                            >
                                <MoreIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            }
        },
    ];

    let tableData = [];
    if (data) {
        // console.log('data', data.result);
        tableData = data.result;
    }

    const tableOptions = {
        ...globalTableOptions,
        downloadOptions: {
            filename: "party-list.csv"
        },
    };

    return <div style={{ position: "relative" }}>
        {(loading) && <Loader />}

        {/* add new party */}
        <Tooltip title="Add New">
            <Fab
                size="small"
                //color="#4caf50" 
                aria-label="add"
                onClick={() => {
                    setParty(null);
                    setTransaction('CREATE');
                    setFormDialogOpen(true);
                }}
                style={{ float: "right", marginTop: "12px", marginRight: "12px", color: "#FFFFFF", backgroundColor: "#4caf50" }}
            >
                <AddIcon />
            </Fab>
        </Tooltip>

        {/* data table */}
        <MuiThemeProvider theme={globalTableTheme}>
            <MUIDataTable
                title={"Parties"}
                data={tableData}
                columns={tableColumns}
                options={tableOptions}
            // components={components}
            />
        </MuiThemeProvider>

        {/* snackbar */}
        <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleSnackbarClose}
        >
            <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
                {snackbarMessage}
            </Alert>
        </Snackbar>

        {/* create/update form */}
        <PartyForm
            formDialogOpen={formDialogOpen}
            handleFormDialogClose={handleFormDialogClose}
            handlePartyCRUD={handlePartyCRUD}
            transaction={transaction}
            party={party}
        />

        {/* delete action dialog */}
        <Dialog
            open={deleteDialogOpen}
            onClose={handleDeleteDialogClose}
            aria-labelledby="delete-party"
            aria-describedby="delete-dialog-description"
        >
            <div style={{ position: "relative" }}>
                {(deleting) && <Loader />}
                <DialogTitle id="delete-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        The party will be deleted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={deleteParty} variant="contained" color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </div>
        </Dialog>

        {/* view dialog */}
        {viewDialogOpen && <Dialog
            open={viewDialogOpen}
            onClose={handleViewDialogClose}
            aria-labelledby="view-party"
            aria-describedby="view-dialog-description"
        >
            <DialogTitle id="view-dialog-title">{"More details"}</DialogTitle>
            <DialogContent>
                <List component="div" role="list">
                    <ListItem divider role="listitem">
                        <ListItemText primary={
                            party && (<div>
                                <div>
                                    {"Name: " + JSON.parse(party.PRIMARY_CONTACT)?.SALUTATION + " " + JSON.parse(party.PRIMARY_CONTACT)?.FIRST_NAME + " " + JSON.parse(party.PRIMARY_CONTACT)?.LAST_NAME}
                                </div>
                                <div>
                                    {"Designation: " + JSON.parse(party.PRIMARY_CONTACT)?.DESIGNATION}
                                </div>
                                <div>
                                    {"Department: " + JSON.parse(party.PRIMARY_CONTACT)?.DEPARTMENT}
                                </div>
                            </div>
                            )
                        } secondary="Primary Contact" />
                    </ListItem>
                    <ListItem divider role="listitem">
                        <ListItemText primary={
                            party && (<div>
                                <div>
                                    {"Street: " + JSON.parse(party.ADDRESS)?.STREET}
                                </div>
                                <div>
                                    {"City: " + JSON.parse(party.ADDRESS)?.CITY}
                                </div>
                                <div>
                                    {"State/UT: " + JSON.parse(party.ADDRESS)?.STATE_UNION_TERRITORY}
                                </div>
                                <div>
                                    {"Country: " + JSON.parse(party.ADDRESS)?.COUNTRY + "-" + JSON.parse(party.ADDRESS)?.ZIP_POSTAL_CODE}
                                </div>
                            </div>
                            )
                        } secondary="Address" />
                    </ListItem>
                </List>
            </DialogContent>
        </Dialog>}

    </div>
}

export default PartyList;