import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { useLazyQuery } from '@apollo/client';
import { Avatar, Button, Chip, FormControl, FormHelperText, Grid, Paper, Snackbar, TextField, Typography } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { POPULATE_SITES_DDL, SEARCH_MATERIAL_STOCK } from "../../../../graphql/queries";
import { globalTableOptions, globalTableTheme } from "../../../../theme";
import Loader from "../../../../components/Loader";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginBottom: '10px'
    },
    fixedHeight: {
        height: 240,
    },
    formControl: {
        //margin: theme.spacing(1),
        display: 'flex',
        minWidth: 120,
        margin: 0
    },
}));

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MaterialStock = () => {
    const classes = useStyles();

    const [siteID, setSiteID] = useState();
    const [siteError, setSiteError] = useState(false);
    const [siteErrorText, setSiteErrorText] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");

    // apollo queries and mutations
    const [getStockList, { loading, data, error }] = useLazyQuery(SEARCH_MATERIAL_STOCK);
    const [populateSitesDDL, { data: sitesDDL }] = useLazyQuery(POPULATE_SITES_DDL);

    // inital effect
    useEffect(() => {
        //console.log('Calling useEffect()....');
        populateSitesDDL({
            variables: {
                siteType: "PLANT",
            }
        });

        getStockList({
            variables: {
                siteID: siteID?.CODE
            }
        });
    }, []);

    // search entries
    const searchEntries = () => {
        //console.log('Calling searchEntries()....');
        if (!siteID) {
            setSiteError(true);
            setSiteErrorText("Please select site/plant");
            return;
        }

        getStockList({
            variables: {
                siteID: siteID?.CODE
            }
        });
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    };

    if (error) {
        setSnackbarSeverity("error");
        setSnackbarMessage(error);
        setOpenSnackbar(true);
    };

    const tableColumns = [
        {
            name: "SITE_ID",
            label: "Site ID",
            options: {
                filter: false,
                sort: true,
                display: false
            }
        }, {
            name: "SITE_NAME",
            label: "Site Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "MATERIAL_TYPE_DESC",
            label: "Material Type",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "MATERIAL_NAME",
            label: "Material Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "REMAINING_QUANTITY",
            label: "Stock",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => <Avatar variant="square" style={{ width: "100%", fontSize: "medium", color: "#FFFFFF", backgroundColor: value < 100 ? "#dc3545" : value < 500 ? "#ffc107" : "#28a745" }} >{value} </Avatar>
            }
        }, {
            name: "UOM_NAME",
            label: "UOM",
            options: {
                filter: false,
                sort: true,
            }
        },
    ];

    let tableData = [];
    if (data) {
        //console.log('Entries: ', data.result);
        tableData = data.result;
    }

    const tableOptions = {
        ...globalTableOptions,
        downloadOptions: {
            filename: "material-stock.csv"
        },
    };

    return <div style={{ position: "relative" }}>
        {/* laoder */}
        {(loading) && <Loader />}

        {/* search container */}
        <Paper variant="elevation" elevation={3} className={classes.paper}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <FormControl className={classes.formControl} error={siteError}>
                        <Autocomplete
                            style={{ marginTop: "-16px" }}
                            id="search_site"
                            autoComplete
                            fullWidth
                            options={sitesDDL?.sites || []}
                            getOptionLabel={option => option.DESC ? option.DESC : ""}
                            renderInput={(params) => (
                                <TextField required {...params} label="Site/Plant" margin="normal" />
                            )}
                            renderOption={(option, { inputValue }) => {
                                const matches = match(option.DESC, inputValue);
                                const parts = parse(option.DESC, matches);
                                return (
                                    <div>
                                        {parts.map((part, index) => (
                                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                );
                            }}
                            value={siteID || {}}
                            onChange={(event, newValue) => {
                                setSiteError(false);
                                setSiteErrorText("");
                                setSiteID(newValue);
                            }}
                        />
                        <FormHelperText>{siteErrorText}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={searchEntries}
                        style={{ marginTop: "10px" }}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>
        </Paper>

        {/* data table */}
        <MuiThemeProvider theme={globalTableTheme}>
            <MUIDataTable
                title={"Stock"}
                data={tableData}
                columns={tableColumns}
                options={tableOptions}
            />
        </MuiThemeProvider>

        {/* snackbar */}
        <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleSnackbarClose}
        >
            <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </div>
}


export default MaterialStock;