import React, { useEffect, useState } from "react";
import { useMutation } from '@apollo/client';
import { FormControlLabel, IconButton, Paper, Switch } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { PARTY_CRUD } from "../../../../graphql/mutations";
import { checkNull, checkMaxLength, checkEmail, checkNumber, checkMobileNumber } from '../../../../util/validations';
import Loader from "../../../../components/Loader";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        margin: '10px'
    },
    formControl: {
        // margin: theme.spacing(1),
        display: 'flex',
        minWidth: 120,
        margin: 0
    },
    input: {
        display: 'none',
    },
    button: {
        margin: theme.spacing(1),
    },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validatePartiesCRUDData = async (Parties) => {
    let validationObjects = {};
    for (let i = 0; i < Parties.length; i++) {
        let validationObject = {};

        if (Parties[i].transaction !== "CREATE")
            checkNull("PARTY_ID", Parties[i].PARTY_ID, "Party Id is required", validationObject);

        checkNull("PARTY_NAME", Parties[i].PARTY_NAME, "Party Name is required", validationObject);
        checkNull("IS_ACTIVE", Parties[i].IS_ACTIVE, "Please select active/inactive", validationObject);

        checkMaxLength("IS_ACTIVE", Parties[i].IS_ACTIVE, 1, "Length of Active/Inactive should be equal to 1 character", validationObject);
        checkMaxLength("PARTY_ID", Parties[i].PARTY_ID, 48, "Length of Party Id should be less than or equal to 48 characters", validationObject);
        checkMaxLength("PARTY_NAME", Parties[i].PARTY_NAME, 100, "Length of Party Name should be less than or equal to 100 characters", validationObject);
        checkMaxLength("PARTY_TYPE", Parties[i].PARTY_TYPE, 48, "Length of Party Type should be less than or equal to 48 characters", validationObject);
        checkMaxLength("PARTY_EMAIL", Parties[i].PARTY_EMAIL, 200, "Length of Party Email should be less than or equal to 200 characters", validationObject);

        checkMaxLength("SALUTATION", Parties[i].SALUTATION, 10, "Length of Salutation should be less than or equal to 10 characters", validationObject);
        checkMaxLength("FIRST_NAME", Parties[i].FIRST_NAME, 50, "Length of First Name should be less than or equal to 50 characters", validationObject);
        checkMaxLength("LAST_NAME", Parties[i].LAST_NAME, 50, "Length of Last Name should be less than or equal to 50 characters", validationObject);
        checkMaxLength("DESIGNATION", Parties[i].DESIGNATION, 50, "Length of Designation should be less than or equal to 50 characters", validationObject);
        checkMaxLength("DEPARTMENT", Parties[i].DEPARTMENT, 50, "Length of Last Name should be less than or equal to 50 characters", validationObject);

        checkMaxLength("WORK_PHONE", Parties[i].WORK_PHONE, 30, "Length of Work Phone should be less than or equal to 30 characters", validationObject);
        checkMaxLength("MOBILE", Parties[i].MOBILE, 15, "Length of Mobile No. should be less than or equal to 15 characters", validationObject);

        checkMaxLength("STREET", Parties[i].STREET, 100, "Length of Building/Street/Area should be less than or equal to 100 characters", validationObject);
        checkMaxLength("CITY", Parties[i].CITY, 50, "Length of City should be less than or equal to 50 characters", validationObject);
        checkMaxLength("STATE_UNION_TERRITORY", Parties[i].STATE_UNION_TERRITORY, 50, "Length of State/UT should be less than or equal to 50 characters", validationObject);
        checkMaxLength("COUNTRY", Parties[i].COUNTRY, 50, "Length of Country should be less than or equal to 50 characters", validationObject);
        checkMaxLength("ZIP_POSTAL_CODE", Parties[i].ZIP_POSTAL_CODE, 6, "Length of Zip should be less than or equal to 6 characters", validationObject);

        checkEmail("EMAIL", Parties[i].PARTY_EMAIL, "Party Email is not valid", validationObject);
        checkMobileNumber("MOBILE", Parties[i].MOBILE, "Mobile no. is not valid", validationObject);
        checkNumber("ZIP_POSTAL_CODE", Parties[i].ZIP_POSTAL_CODE, "Zip should be a number", validationObject);

        if (Object.keys(validationObject).length !== 0)
            validationObjects[i] = validationObject;
    }
    return validationObjects;
}

const PartyForm = (props) => {
    const classes = useStyles();

    const { formDialogOpen, handleFormDialogClose, handlePartyCRUD, transaction, party } = props;
    const [partyState, setPartyState] = useState(null);
    const [partyErrors, setPartyErrors] = useState(null);
    const [saving, setSaving] = useState(false);

    const [PartiesCRUD] = useMutation(PARTY_CRUD);

    useEffect(() => {
        setPartyErrors(null);
        if (party) {
            const primaryContact = JSON.parse(party.PRIMARY_CONTACT);
            const partyContacts = JSON.parse(party.PARTY_CONTACT_NOS);
            const partyAddress = JSON.parse(party.ADDRESS);

            setPartyState({
                transaction: transaction,
                PARTY_ID: party.PARTY_ID,
                PARTY_NAME: party.PARTY_NAME,
                PARTY_TYPE: party.PARTY_TYPE,
                PARTY_EMAIL: party.PARTY_EMAIL,

                SALUTATION: primaryContact.SALUTATION,
                FIRST_NAME: primaryContact.FIRST_NAME,
                LAST_NAME: primaryContact.LAST_NAME,
                DESIGNATION: primaryContact.DESIGNATION,
                DEPARTMENT: primaryContact.DEPARTMENT,

                WORK_PHONE: partyContacts.WORK_PHONE,
                MOBILE: partyContacts.MOBILE,

                STREET: partyAddress.STREET,
                CITY: partyAddress.CITY,
                STATE_UNION_TERRITORY: partyAddress.STATE_UNION_TERRITORY,
                COUNTRY: partyAddress.COUNTRY,
                ZIP_POSTAL_CODE: partyAddress.ZIP_POSTAL_CODE,

                IS_ACTIVE: party.IS_ACTIVE,
            });
        }
        else {
            setPartyState({
                transaction: transaction,
                IS_ACTIVE: "Y",
            });
        }
    }, [props]);

    const setPartyValidationErrors = (errors) => {
        for (let key in errors) {
            // set Error state;
            setPartyErrors({
                PARTY_ID: errors[key].errorPARTY_ID,
                PARTY_NAME: errors[key].errorPARTY_NAME,
                PARTY_TYPE: errors[key].errorPARTY_TYPE,
                PARTY_EMAIL: errors[key].errorPARTY_EMAIL,

                SALUTATION: errors[key].errorSALUTATION,
                FIRST_NAME: errors[key].errorFIRST_NAME,
                LAST_NAME: errors[key].errorLAST_NAME,
                DESIGNATION: errors[key].errorDESIGNATION,
                DEPARTMENT: errors[key].errorDEPARTMENT,

                WORK_PHONE: errors[key].errorWORK_PHONE,
                MOBILE: errors[key].errorMOBILE,

                STREET: errors[key].errorSTREET,
                CITY: errors[key].errorCITY,
                STATE_UNION_TERRITORY: errors[key].errorSTATE_UNION_TERRITORY,
                COUNTRY: errors[key].errorCOUNTRY,
                ZIP_POSTAL_CODE: errors[key].errorZIP_POSTAL_CODE,

                IS_ACTIVE: errors[key].errorIS_ACTIVE,
            });
        }
    }

    const saveParty = async () => {
        setSaving(true);
        const validationErrors = await validatePartiesCRUDData([partyState]);

        if (Object.keys(validationErrors).length !== 0) {
            setPartyValidationErrors(validationErrors);
            setSaving(false);
        }
        else
            PartiesCRUD({
                variables: {
                    "transaction": partyState.transaction,
                    "parties": [{
                        "PARTY_ID": partyState.PARTY_ID,
                        "PARTY_NAME": partyState.PARTY_NAME,
                        "IS_ACTIVE": partyState.IS_ACTIVE,
                        "PARTY_TYPE": partyState.PARTY_TYPE || "",
                        "PRIMARY_CONTACT": {
                            "SALUTATION": partyState.SALUTATION || "",
                            "FIRST_NAME": partyState.FIRST_NAME || "",
                            "LAST_NAME": partyState.LAST_NAME || "",
                            "DESIGNATION": partyState.DESIGNATION || "",
                            "DEPARTMENT": partyState.DEPARTMENT || ""
                        },
                        "PARTY_EMAIL": partyState.PARTY_EMAIL || "",
                        "PARTY_CONTACT_NOS": {
                            "WORK_PHONE": partyState.WORK_PHONE || "",
                            "MOBILE": partyState.MOBILE || ""
                        },
                        "ADDRESS": {
                            "STREET": partyState.STREET || "",
                            "CITY": partyState.CITY || "",
                            "STATE_UNION_TERRITORY": partyState.STATE_UNION_TERRITORY || "",
                            "COUNTRY": partyState.COUNTRY || "",
                            "ZIP_POSTAL_CODE": partyState.ZIP_POSTAL_CODE || ""
                        }
                    }]
                }
            })
                .then(resp => {
                    //const result = resp.data.result;
                    //console.log('[PartiesCRUD] Resp: ', result);
                    // setPartyState({});
                    setSaving(false);
                    handlePartyCRUD();
                })
                .catch(error => {
                    console.log('[PartiesCRUD] Error:', error.message);
                    setSaving(false);
                    setPartyValidationErrors(JSON.parse(error));
                });
    }

    return <Dialog open={formDialogOpen} onClose={handleFormDialogClose} TransitionComponent={Transition}>
        <div style={{ position: "relative" }}>
            {saving && <Loader />}
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleFormDialogClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {transaction === 'CREATE' ? "Add New Party" : "Update Party"}
                    </Typography>
                    <Button autoFocus color="secondary" variant="contained" onClick={saveParty}>
                        save
                </Button>
                </Toolbar>
            </AppBar>

            <Paper variant="elevation" elevation={3} className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="PARTY_NAME"
                            name="PARTY_NAME"
                            label="Party name"
                            fullWidth
                            autoComplete="party-name"
                            error={partyErrors?.PARTY_NAME ? true : false}
                            helperText={partyErrors?.PARTY_NAME}
                            value={partyState?.PARTY_NAME}
                            onChange={(event) => {
                                setPartyState({ ...partyState, PARTY_NAME: event.target.value });
                                if (event.target.value.trim().length !== 0)
                                    setPartyErrors({ ...partyErrors, PARTY_NAME: null })
                                else
                                    setPartyErrors({ ...partyErrors, PARTY_NAME: "Party Name is required" })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="PARTY_TYPE"
                            name="PARTY_TYPE"
                            label="Party type"
                            fullWidth
                            autoComplete="party-type"
                            error={partyErrors?.PARTY_TYPE ? true : false}
                            helperText={partyErrors?.PARTY_TYPE}
                            value={partyState?.PARTY_TYPE}
                            onChange={(event) => {
                                setPartyState({ ...partyState, PARTY_TYPE: event.target.value });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="PARTY_EMAIL"
                            name="PARTY_EMAIL"
                            fullWidth
                            label="Email"
                            autoComplete="email"
                            error={partyErrors?.PARTY_EMAIL ? true : false}
                            helperText={partyErrors?.PARTY_EMAIL}
                            value={partyState?.PARTY_EMAIL}
                            onChange={(event) => setPartyState({ ...partyState, PARTY_EMAIL: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="FIRST_NAME"
                            name="FIRST_NAME"
                            fullWidth
                            label="First Name"
                            autoComplete="first-name"
                            error={partyErrors?.FIRST_NAME ? true : false}
                            helperText={partyErrors?.FIRST_NAME}
                            value={partyState?.FIRST_NAME}
                            onChange={(event) => setPartyState({ ...partyState, FIRST_NAME: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="LAST_NAME"
                            name="LAST_NAME"
                            fullWidth
                            label="Last Name"
                            autoComplete="last-name"
                            error={partyErrors?.LAST_NAME ? true : false}
                            helperText={partyErrors?.LAST_NAME}
                            value={partyState?.LAST_NAME}
                            onChange={(event) => setPartyState({ ...partyState, LAST_NAME: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="DESIGNATION"
                            name="DESIGNATION"
                            fullWidth
                            label="Designation"
                            autoComplete="designation"
                            error={partyErrors?.DESIGNATION ? true : false}
                            helperText={partyErrors?.DESIGNATION}
                            value={partyState?.DESIGNATION}
                            onChange={(event) => setPartyState({ ...partyState, DESIGNATION: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="DEPARTMENT"
                            name="DEPARTMENT"
                            fullWidth
                            label="Department"
                            autoComplete="department"
                            error={partyErrors?.DEPARTMENT ? true : false}
                            helperText={partyErrors?.DEPARTMENT}
                            value={partyState?.DEPARTMENT}
                            onChange={(event) => setPartyState({ ...partyState, DEPARTMENT: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="WORK_PHONE"
                            name="WORK_PHONE"
                            fullWidth
                            label="Work Phone"
                            autoComplete="work-phone"
                            error={partyErrors?.WORK_PHONE ? true : false}
                            helperText={partyErrors?.WORK_PHONE}
                            value={partyState?.WORK_PHONE}
                            onChange={(event) => setPartyState({ ...partyState, WORK_PHONE: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="MOBILE"
                            name="MOBILE"
                            fullWidth
                            label="Mobile No."
                            autoComplete="mobile"
                            error={partyErrors?.MOBILE ? true : false}
                            helperText={partyErrors?.MOBILE}
                            value={partyState?.MOBILE}
                            onChange={(event) => setPartyState({ ...partyState, MOBILE: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="STREET"
                            name="STREET"
                            fullWidth
                            label="Building/Street/Area"
                            autoComplete="street"
                            error={partyErrors?.STREET ? true : false}
                            helperText={partyErrors?.STREET}
                            value={partyState?.STREET}
                            onChange={(event) => setPartyState({ ...partyState, STREET: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="CITY"
                            name="CITY"
                            defaultValue="Pune"
                            fullWidth
                            label="City"
                            autoComplete="city"
                            error={partyErrors?.CITY ? true : false}
                            helperText={partyErrors?.CITY}
                            value={partyState?.CITY}
                            onChange={(event) => setPartyState({ ...partyState, CITY: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="STATE_UNION_TERRITORY"
                            name="STATE_UNION_TERRITORY"
                            defaultValue="Maharashtra"
                            fullWidth
                            label="State/UT"
                            autoComplete="state-union-territory"
                            error={partyErrors?.STATE_UNION_TERRITORY ? true : false}
                            helperText={partyErrors?.STATE_UNION_TERRITORY}
                            value={partyState?.STATE_UNION_TERRITORY}
                            onChange={(event) => setPartyState({ ...partyState, STATE_UNION_TERRITORY: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="COUNTRY"
                            name="COUNTRY"
                            defaultValue="India"
                            fullWidth
                            label="Country"
                            autoComplete="country"
                            error={partyErrors?.COUNTRY ? true : false}
                            helperText={partyErrors?.COUNTRY}
                            value={partyState?.COUNTRY}
                            onChange={(event) => setPartyState({ ...partyState, COUNTRY: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="ZIP_POSTAL_CODE"
                            name="ZIP_POSTAL_CODE"
                            fullWidth
                            label="Zip"
                            autoComplete="zip"
                            error={partyErrors?.ZIP_POSTAL_CODE ? true : false}
                            helperText={partyErrors?.ZIP_POSTAL_CODE}
                            value={partyState?.ZIP_POSTAL_CODE}
                            onChange={(event) => setPartyState({ ...partyState, ZIP_POSTAL_CODE: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            label={partyState?.IS_ACTIVE === 'Y' ? "Active" : "Inactive"}
                            control={
                                <Switch
                                    checked={partyState?.IS_ACTIVE === "Y" ? true : false}
                                    name="IS_ACTIVE"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    onChange={(event) => setPartyState({ ...partyState, IS_ACTIVE: event.target.checked ? "Y" : "N" })}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </Paper>
        </div>
    </Dialog>
}

export default PartyForm;