import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router-dom';

import { isValidSession } from "../../util/auth";
import LabourExpensesList from './components/LabourExpensesList';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const LabourExpenses = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const ref = useRef(null);
    let history = useHistory();

    useEffect(() => {
        isValidSession()
            .then(result => {
                if (result)
                    ref.current.scrollIntoView({ behavior: 'smooth' });
                else
                    history.push("/signin");
            })
            .catch(error => {
                console.log('[Material Arrival] Valid session error: ', error);
                history.push("/signin");
            })
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div ref={ref} className={classes.root}>
            <Tabs 
                value={value} 
                aria-label="sites" 
                textColor="primary"
                onChange={handleChange} 
            >
                <Tab label="List of Labour Expenses" {...a11yProps(0)} />
                {/* <Tab label="Add/Update" {...a11yProps(1)} /> */}
            </Tabs>
            
            <TabPanel value={value} index={0}>
                <LabourExpensesList />
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
                Add/Update a Material Arrivals ..
            </TabPanel> */}
        </div>
    );
}


export default LabourExpenses;