import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useLazyQuery, useMutation } from '@apollo/client';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar, Tooltip } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { MuiThemeProvider } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { SEARCH_MATERIALS } from "../../../../graphql/queries";
import { MATERIALS_CRUD } from "../../../../graphql/mutations";
import { globalTableOptions, globalTableTheme } from "../../../../theme";
import Loader from "../../../../components/Loader";
import MaterialForm from "../MaterialForm";

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MaterialList = () => {
    const [getMaterialsList, { loading, data, error, refetch: refetchMaterialsList }] = useLazyQuery(SEARCH_MATERIALS);
    const [MaterialsCRUD] = useMutation(MATERIALS_CRUD);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [formDialogOpen, setFormDialogOpen] = useState(false);
    const [transaction, setTransaction] = useState("");
    const [material, setMaterial] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [materialID, setMaterialID] = useState("");
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        getMaterialsList();
    }, [getMaterialsList]);

    const handleFormDialogClose = () => {
        setFormDialogOpen(false);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    if (error) {
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        setSnackbarMessage(error);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    };

    const handleMaterialsCRUD = () => {
        setFormDialogOpen(false);
        //setMaterial(null);
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        setSnackbarMessage(`Material ${transaction === 'CREATE' ? 'added' : 'updated'} successfully!`);
        refetchMaterialsList();
    };

    const deleteMaterial = () => {
        setDeleting(true);
        MaterialsCRUD({
            variables: {
                "transaction": "LOGICAL_DELETE",
                "materials": [{
                    "MATERIAL_ID": materialID,
                }]
            }
        })
            .then(resp => {
                // const result = resp.data.result;
                // console.log('[DELETE MATERIAL] Resp: ', result);
                setDeleteDialogOpen(false);
                setSnackbarSeverity("success");
                setOpenSnackbar(true);
                setSnackbarMessage("Material removed successfully!");
                refetchMaterialsList();
                setDeleting(false);
            })
            .catch(error => {
                console.log('[DELETE MATERIAL] Error:', error.message);
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
                setSnackbarMessage(JSON.parse(error.message)["0"].errorMATERIAL_ID);
                setDeleting(false);
            });
    };

    const tableColumns = [
        {
            name: "MATERIAL_ID",
            label: "Material ID",
            options: {
                filter: false,
                sort: true,
                display: false
            }
        }, {
            name: "MATERIAL_TYPE_DESC",
            label: "Material Type",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "MATERIAL_NAME",
            label: "Material Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "MATERIAL_DESC",
            label: "Material Description",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "UOM_NAME",
            label: "UOM",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "IS_ACTIVE",
            label: "Active",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "TRACK_USAGE",
            label: "Usage Tracking",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "MATERIAL_IMAGE",
            label: "Material Image",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => <img alt="MATERIAL" src={value} style={{ height: "50px", width: "50px" }} />
            }
        },
        {
            name: "ACTIONS",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    //const tableData = tableMeta.tableData;
                    const tableData = data?.result;
                    const rowIndex = tableMeta.rowIndex;
                    return <>
                        <Tooltip title="Edit">
                            <IconButton
                                style={{ color: "#343a40" }}
                                onClick={() => {
                                    setMaterial(tableData[rowIndex]);
                                    setTransaction('UPDATE');
                                    setFormDialogOpen(true);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton
                                style={{ color: "#dc3545" }}
                                onClick={() => {
                                    setMaterialID(tableData[rowIndex].MATERIAL_ID);
                                    setDeleteDialogOpen(true);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            }
        },
    ];

    let tableData = [];
    if (data) {
        //console.log('Material list:', data.result);
        tableData = data.result;
    }

    const tableOptions = {
        ...globalTableOptions,
        downloadOptions: {
            filename: "material-list.csv"
        },
    };

    return <div style={{ position: "relative" }}>
        {(loading) && <Loader />}

        {/* add new material */}
        <Tooltip title="Add New">
            <Fab
                size="small"
                //color="#4caf50" 
                aria-label="add"
                onClick={() => {
                    setMaterial(null);
                    setTransaction('CREATE');
                    setFormDialogOpen(true);
                }}
                style={{ float: "right", marginTop: "12px", marginRight: "12px", color: "#FFFFFF", backgroundColor: "#4caf50" }}
            >
                <AddIcon />
            </Fab>
        </Tooltip>

        {/* data table */}
        <MuiThemeProvider theme={globalTableTheme}>
            <MUIDataTable
                title={"Materials"}
                data={tableData}
                columns={tableColumns}
                options={tableOptions}
            />
        </MuiThemeProvider>

        {/* snackbar */}
        <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleSnackbarClose}
        >
            <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
                {snackbarMessage}
            </Alert>
        </Snackbar>

        {/* create/update form */}
        <MaterialForm
            formDialogOpen={formDialogOpen}
            handleFormDialogClose={handleFormDialogClose}
            handleMaterialsCRUD={handleMaterialsCRUD}
            transaction={transaction}
            material={material}
        />

        {/* delete action dialog */}
        <Dialog
            open={deleteDialogOpen}
            onClose={handleDeleteDialogClose}
            aria-labelledby="delete-material"
            aria-describedby="delete-dialog-description"
        >
            <div style={{ position: "relative" }}>
                {(deleting) && <Loader />}
                <DialogTitle id="delete-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        The material will be deleted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={deleteMaterial} variant="contained" color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </div>
        </Dialog>

    </div>
}

export default MaterialList;