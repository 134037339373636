import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Snackbar, TextField, Tooltip } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from "@material-ui/lab";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import { MuiThemeProvider } from "@material-ui/core/styles";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { POPULATE_STATUS_DDL, SEARCH_MATERIAL_USAGES } from "../../../../graphql/queries";
import { UPDATE_MATERIAL_USAGE_RATES, UPDATE_MATERIAL_USAGE_STATUS } from "../../../../graphql/mutations";
import { globalTableOptions, globalTableTheme } from "../../../../theme";
import Status from "../../../../components/Status";
import Loader from "../../../../components/Loader";
import { deleteSeenNotifications } from "../../../../util/notifications";
import ImageGrid from "../../../../components/Images/ImagesGrid";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginBottom: '10px'
    },
    fixedHeight: {
        height: 240,
    },
    formControl: {
        //margin: theme.spacing(1),
        display: 'flex',
        minWidth: 120,
        margin: 0
    },
}));


const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MaterialUsagesList = () => {
    const classes = useStyles();

    const [usageFromDate, setUsageFromDate] = React.useState(moment().subtract(1, 'months'));
    const [usageToDate, setUsageToDate] = React.useState(moment());
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [updateType, setUpdateType] = useState("");
    const [challanRate, setChallanRate] = useState(0);
    const [usageStatus, setUsageStatus] = useState();
    const [usageId, setUsageId] = useState(0);
    const [rateError, setRateError] = useState(false);
    const [rateErrorText, setRateErrorText] = useState("");
    const [statusError, setStatusError] = useState(false);
    const [statusErrorText, setStatusErrorText] = useState("");
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [searchChallanStatus, setSearchChallanStatus] = useState();

    // apollo queries and mutations
    const [getUsageList, { loading, data, error, refetch: refetchUsageList }] = useLazyQuery(SEARCH_MATERIAL_USAGES);
    const [populateStatusDDL, { data: statusDDL }] = useLazyQuery(POPULATE_STATUS_DDL);

    const [UpdateMaterialUsageRates] = useMutation(UPDATE_MATERIAL_USAGE_RATES);
    const [UpdateMaterialUsageStatus] = useMutation(UPDATE_MATERIAL_USAGE_STATUS);

    // inital effect
    useEffect(() => {
        //console.log('Calling useEffect()....');
        getUsageList({
            variables: {
                usageFromDate: usageFromDate.format("YYYYMMDD"),
                usageToDate: usageToDate.format("YYYYMMDD")
            }
        });
        populateStatusDDL({
            variables: {
                statusType: "ALL",
            }
        });
        deleteSeenNotifications("MAT_USG");
    }, []);

    // search material usagees
    const searchMaterialUsagees = () => {
        //console.log('Calling searchMaterialUsagees()....');
        getUsageList({
            variables: {
                usageFromDate: usageFromDate?.format("YYYYMMDD") || moment().subtract(1, 'months').format("YYYYMMDD"),
                usageToDate: usageToDate?.format("YYYYMMDD") || moment().format("YYYYMMDD"),
                status: searchChallanStatus?.CODE || "%"
            }
        });
    }

    const handleUpdateDialogClose = () => {
        setUpdateDialogOpen(false);
    };

    const handleImageDialogClose = () => {
        setImageDialogOpen(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    };

    // Update status depending upon the update type
    const handleDialogUpdate = () => {
        if (updateType === "Rate") {
            if (typeof challanRate === 'undefined' || challanRate === null || challanRate.trim().length === 0) {
                setRateError(true);
                setRateErrorText("Rate is required");
            }
            else if (isNaN(challanRate)) {
                setRateError(true);
                setRateErrorText("Rate should be a number");
            }
            else if (challanRate.length > 12) {
                setRateError(true);
                setRateErrorText("Length of Rate should be less than or equal to 12 digits");
            }
            else {
                setShowLoading(true);
                UpdateMaterialUsageRates({
                    variables: {
                        "rates": [{
                            "USAGE_ID": usageId,
                            "RATE": challanRate
                        }]
                    }
                })
                    .then(resp => {
                        // const result = resp.data.result;
                        // console.log('Rate update resp: ', result);
                        setSnackbarSeverity("success");
                        setOpenSnackbar(true);
                        setSnackbarMessage("Rate updated successfully!");
                        setUpdateDialogOpen(false);
                        refetchUsageList();
                        setShowLoading(false);
                    })
                    .catch(error => {
                        console.log('Rate update error:', error.message);
                        setSnackbarSeverity("error");
                        setOpenSnackbar(true);
                        setSnackbarMessage(JSON.parse(error.message)["0"].errorRATE);
                        setShowLoading(false);
                    });
            }
        }
        else if (updateType === "Status") {
            if (typeof usageStatus === 'undefined' || usageStatus === null || usageStatus.trim().length === 0) {
                setStatusError(true);
                setStatusErrorText("Status is required");
            }
            else if (usageStatus.length > 48) {
                setStatusError(true);
                setStatusErrorText("Length of Status should be less than or equal to 48 characters");
            }
            else {
                setShowLoading(true);
                UpdateMaterialUsageStatus({
                    variables: {
                        "status": [{
                            "USAGE_ID": usageId,
                            "STATUS": usageStatus
                        }]
                    }
                })
                    .then(resp => {
                        // const result = resp.data.result;
                        //console.log('Status update resp: ', result);
                        setSnackbarSeverity("success");
                        setOpenSnackbar(true);
                        setSnackbarMessage("Status updated successfully!");
                        setUpdateDialogOpen(false);
                        refetchUsageList();
                        setShowLoading(false);
                    })
                    .catch(error => {
                        console.log('Status update error:', error.message);
                        setSnackbarSeverity("error");
                        setOpenSnackbar(true);
                        setSnackbarMessage(error.message);
                        setShowLoading(false);
                    });
            }
        }
    };

    if (error) {
        setSnackbarSeverity("error");
        setSnackbarMessage(error);
        setOpenSnackbar(true);
    };

    const tableColumns = [
        {
            name: "USAGE_ID",
            label: "Usage ID",
            options: {
                filter: false,
                sort: true,
                display: false
            }
        }, {
            name: "STATUS",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        left: "0",
                        zIndex: 100
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        left: 0,
                        zIndex: 101
                    }
                }),
                customBodyRender: (value, tableMeta, updateValue) => <Status status={value} />
            }
        }, {
            name: "USAGE_DATE_FRMT",
            label: "Usage Date",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "USAGE_TIME_FRMT",
            label: "Usage Time",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "SITE_NAME",
            label: "Site/Plant",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "MATERIAL_TYPE_DESC",
            label: "Material Type",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "MATERIAL_NAME",
            label: "Material Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "USAGE_TYPE_DESC",
            label: "Usage",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "QUANTITY",
            label: "Quantity",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "UOM_NAME",
            label: "UOM",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "RATE",
            label: "Rate",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "TOTAL_AMOUNT",
            label: "Total Amount",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "REMARKS",
            label: "Remarks",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "USER_NAME",
            label: "User",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "ACTIONS",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    //const tableData = tableMeta.tableData;
                    const tableData = data?.result;
                    const rowIndex = tableMeta.rowIndex;
                    // console.log('Selcted tableMeta: ', tableMeta);
                    return <>
                        <Tooltip title="View Images">
                            <IconButton
                                style={{ color: "#17A2B8" }}
                                onClick={() => {
                                    setImages(JSON.parse(tableData[rowIndex].IMAGES));
                                    setImageDialogOpen(true);
                                }}
                            >
                                <PhotoLibraryIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Update Rate">
                            <IconButton
                                style={{ color: "#28a745" }}
                                onClick={() => {
                                    setUsageId(tableData[rowIndex].USAGE_ID);
                                    setChallanRate(tableData[rowIndex].RATE);
                                    setUpdateType("Rate");
                                    setUpdateDialogOpen(true);
                                }}
                            >
                                <AccountBalanceWalletIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Update Status">
                            <IconButton
                                style={{ color: "#dc3545" }}
                                onClick={() => {
                                    setUsageId(tableData[rowIndex].USAGE_ID);
                                    setUsageStatus(tableData[rowIndex].STATUS);
                                    setUpdateType("Status");
                                    setUpdateDialogOpen(true);
                                }}
                            >
                                <ThumbsUpDownIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            }
        },
    ];

    let tableData = [];
    if (data) {
        // console.log('Usage list: ', data.result);
        tableData = data.result;
    }

    const tableOptions = {
        ...globalTableOptions,
        downloadOptions: {
            filename: "material-usage-list.csv"
        },
        customTableBodyFooterRender: (opts) => {
            // console.log('[Arrival] customTableBodyFooterRender:>> ', opts?.data);
            return <TableFooter>
                <TableRow  style={{ whiteSpace: "nowrap",}}>
                    <TableCell colSpan={6} >
                    </TableCell>
                    <TableCell
                        style={{
                            position: "sticky",
                            left: "0",
                            zIndex: 101,
                        }}
                    >
                        <Typography variant="subtitle2" >
                            TOTAL
                        </Typography>
                    </TableCell>
                    <TableCell colSpan={2}>
                        <Typography variant="subtitle2">
                            {opts?.data.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.data[8]), 0).toFixed(2)}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="subtitle2">
                            {"Avg. " + (opts?.data.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.data[10] === null ? 0 : currentValue.data[10]), 0) / opts.data?.length).toFixed(2)}
                        </Typography>
                    </TableCell>
                    <TableCell colSpan={4}>
                        <Typography variant="subtitle2">
                            {"₹ " + opts?.data.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.data[11] === null ? 0 : currentValue.data[11]), 0).toFixed(2)}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableFooter>;
        }
    };

    return <div style={{ position: "relative" }}>
        {/* laoder */}
        {(loading) && <Loader />}

        {/* search container */}
        <Paper variant="elevation" elevation={3} className={classes.paper}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                    <FormControl className={classes.formControl} >
                        <Autocomplete
                            style={{ marginTop: "-16px" }}
                            id="search_status"
                            autoComplete
                            fullWidth
                            options={statusDDL?.status || []}
                            getOptionLabel={option => option.DESC ? option.DESC : ""}
                            renderInput={(params) => (
                                <TextField {...params} label="Status" margin="normal" />
                            )}
                            renderOption={(option, { inputValue }) => {
                                const matches = match(option.DESC, inputValue);
                                const parts = parse(option.DESC, matches);
                                return (
                                    <div>
                                        {parts.map((part, index) => (
                                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                );
                            }}
                            value={searchChallanStatus || {}}
                            onChange={(event, newValue) => setSearchChallanStatus(newValue)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            required
                            id="usage-from-date"
                            // placeholder="05/11/2020"
                            label="From Date"
                            value={usageFromDate}
                            onChange={date => setUsageFromDate(date)}
                            format="DD/MM/yyyy"
                        />
                    </MuiPickersUtilsProvider></Grid>
                <Grid item xs={12} md={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            required
                            id="usage-to-date"
                            //placeholder="05/11/2020"
                            label="To Date"
                            value={usageToDate}
                            onChange={date => setUsageToDate(date)}
                            format="DD/MM/yyyy"
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={searchMaterialUsagees}
                        style={{ marginTop: "10px" }}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>
        </Paper>

        {/* data table */}
        <MuiThemeProvider theme={globalTableTheme}>
            <MUIDataTable
                title={"Material Usages"}
                data={tableData}
                columns={tableColumns}
                options={tableOptions}
            />
        </MuiThemeProvider>

        {/* snackbar */}
        <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleSnackbarClose}
        >
            <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
                {snackbarMessage}
            </Alert>
        </Snackbar>

        {/* View Images Dialog */}
        <ImageGrid
            imageDialogOpen={imageDialogOpen}
            handleImageDialogClose={handleImageDialogClose}
            images={images}
        />

        {/* Update Dialog - Rate & Status*/}
        <Dialog open={updateDialogOpen} onClose={handleUpdateDialogClose} aria-labelledby="Update">
            <div style={{ position: "relative" }}>
                {(showLoading) && <Loader />}
                <DialogTitle id="update-dialog">Update {updateType}</DialogTitle>
                <DialogContent dividers>
                    {
                        updateType === "Rate" ?
                            <TextField
                                required
                                autoFocus
                                margin="dense"
                                id="rate"
                                label="Enter Rate"
                                type="text"
                                fullWidth
                                error={rateError}
                                helperText={rateError && rateErrorText}
                                value={challanRate}
                                onChange={(event) => {
                                    setRateError(false);
                                    setRateErrorText("");
                                    setChallanRate(event.target.value);
                                }}
                            /> :
                            <FormControl className={classes.formControl} error={statusError}>
                                <InputLabel id="status-input-label">Select Status</InputLabel>
                                <Select
                                    labelId="status-label"
                                    id="status-select"
                                    value={usageStatus}
                                    onChange={event => {
                                        // console.log('Selected status:::', event.target.value);
                                        setStatusError(false);
                                        setStatusErrorText("");
                                        setUsageStatus(event.target.value);
                                    }}
                                >
                                    {
                                        statusDDL?.status.map((stat, index) => <MenuItem key={index} value={stat.CODE}>{stat.DESC}</MenuItem>)
                                    }
                                </Select>
                                <FormHelperText>{statusErrorText}</FormHelperText>
                            </FormControl>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpdateDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDialogUpdate} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </div>
        </Dialog>

    </div>
}

export default MaterialUsagesList;