import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { green, lightBlue, pink } from "@material-ui/core/colors";
import { Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    status_new: {
        backgroundColor: lightBlue[500],
        color: "#FFFFFF"
    },
    status_accepted: {
        backgroundColor: green[500],
        color: "#FFFFFF"
    },
    status_rejected: {
        backgroundColor: pink[500],
        color: "#FFFFFF"
    },
}));


const Status = ({ status }) => {
    const classes = useStyles();

    const statusCssClass = "status_" + status.toLowerCase();
    return <Chip label={status} size="small" className={classes[statusCssClass]} />
}

export default Status;