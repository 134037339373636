import React, { useEffect, useState } from "react";

import { useMutation, useQuery } from '@apollo/client';
import { FormControl, FormHelperText, IconButton, Paper } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";

import { POPULATE_EMPLOYEE_PAYMENTS_DDL } from "../../../../graphql/queries";
import { EMPLOYEE_PAYMENTS_CRUD } from "../../../../graphql/mutations";
import { checkNull, checkMaxLength, checkDate, checkNumber } from '../../../../util/validations';
import Loader from "../../../../components/Loader";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        margin: '10px'
    },
    formControl: {
        // margin: theme.spacing(1),
        display: 'flex',
        minWidth: 120,
        margin: 0
    },
    input: {
        display: 'none',
    },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validatePaymentsCRUDData = async (Payments) => {
    let validationObjects = {};
    for (let i = 0; i < Payments.length; i++) {
        let validationObject = {};

        if (Payments[i].transaction !== "CREATE")
            checkNull("PAYMENT_ID", Payments[i].PAYMENT_ID, "Payments Id is required", validationObject);

        checkNull("PAY_MONTH", Payments[i].PAY_MONTH, "Please select pay month", validationObject);
        checkNull("PAYMENT_DATE", Payments[i].PAYMENT_DATE, "Payments date is required", validationObject);
        checkNull("PAYMENT_TYPE", Payments[i].PAYMENT_TYPE?.CODE, "Please select payment type", validationObject);
        checkNull("PAYMENT_MODE", Payments[i].PAYMENT_MODE?.CODE, "Please select payment mode", validationObject);
        checkNull("EMPLOYEE_ID", Payments[i].EMPLOYEE_ID?.CODE, "Please select employee", validationObject);
        checkNull("TOTAL_AMOUNT", Payments[i].TOTAL_AMOUNT, "Total amount is required", validationObject);
        checkNull("LEAVES", Payments[i].LEAVES, "No of leaves taken is required", validationObject);
        checkNull("DEDUCTIONS", Payments[i].DEDUCTIONS, "Deductible amount is required", validationObject);
        checkNull("PAID_AMOUNT", Payments[i].PAID_AMOUNT, "Paid amount is required", validationObject);
        checkNull("BALANCE_AMOUNT", Payments[i].BALANCE_AMOUNT, "Balance amount is required", validationObject);
        checkNull("REMARKS", Payments[i].REMARKS, "Remarks are required", validationObject);

        checkMaxLength("PAY_MONTH", Payments[i].PAY_MONTH, 6, "Length of Pay Month should be equal to 6 characters", validationObject);
        checkMaxLength("PAYMENT_DATE", Payments[i].PAYMENT_DATE, 8, "Length of Payments Date should be equal to 8 characters", validationObject);
        checkMaxLength("PAYMENT_TYPE", Payments[i].PAYMENT_TYPE?.CODE, 48, "Length of Payment Type should be less than or equal to 48 characters", validationObject);
        checkMaxLength("PAYMENT_MODE", Payments[i].PAYMENT_MODE?.CODE, 48, "Length of Payment Mode should be less than or equal to 48 characters", validationObject);
        checkMaxLength("EMPLOYEE_ID", Payments[i].EMPLOYEE_ID?.CODE, 200, "Length of Employee Id should be less than or equal to 200 characters", validationObject);
        checkMaxLength("TOTAL_AMOUNT", Payments[i].TOTAL_AMOUNT, 12, "Length of Total Amount should be less than or equal to 12 characters", validationObject);
        checkMaxLength("LEAVES", Payments[i].LEAVES, 12, "Length of No of Leaves Taken should be less than or equal to 12 characters", validationObject);
        checkMaxLength("DEDUCTIONS", Payments[i].DEDUCTIONS, 12, "Length of Deductible Amount should be less than or equal to 12 characters", validationObject);
        checkMaxLength("PAID_AMOUNT", Payments[i].PAID_AMOUNT, 12, "Length of Paid Amount should be less than or equal to 12 characters", validationObject);
        checkMaxLength("BALANCE_AMOUNT", Payments[i].BALANCE_AMOUNT, 12, "Length of Balance Amount should be less than or equal to 12 characters", validationObject);
        checkMaxLength("REMARKS", Payments[i].REMARKS, 100, "Length of Remarks should be less than or equal to 100 characters", validationObject);

        checkNumber("LEAVES", Payments[i].LEAVES, "No of leaves taken should be a number", validationObject);
        checkNumber("DEDUCTIONS", Payments[i].DEDUCTIONS, "Deductible amount should be a number", validationObject);
        checkNumber("TOTAL_AMOUNT", Payments[i].TOTAL_AMOUNT, "Total amount should be a number", validationObject);
        checkNumber("PAID_AMOUNT", Payments[i].PAID_AMOUNT, "Paid amount should be a number", validationObject);
        checkNumber("BALANCE_AMOUNT", Payments[i].BALANCE_AMOUNT, "Balance amount should be a number", validationObject);

        checkDate('PAYMENT_DATE', Payments[i].PAYMENT_DATE, 'YYYYMMDD', 'Invalid Payments Date', validationObject);
        checkDate('PAY_MONTH', Payments[i].PAY_MONTH, 'YYYYMM', 'Invalid Pay Month', validationObject);

        if (Object.keys(validationObject).length !== 0)
            validationObjects[i] = validationObject;
    }
    return validationObjects;
}

const PaymentForm = (props) => {
    const classes = useStyles();

    const { formDialogOpen, handleFormDialogClose, handleEmployeePaymentsCRUD, transaction, payment } = props;
    const [paymentState, setPaymentState] = useState(null);
    const [paymentErrors, setPaymentErrors] = useState(null);
    const [saving, setSaving] = useState(false);

    const { loading: ddlLoading, error: ddlError, data: ddlData } = useQuery(POPULATE_EMPLOYEE_PAYMENTS_DDL);
    const [PaymentsCRUD] = useMutation(EMPLOYEE_PAYMENTS_CRUD);

    useEffect(() => {
        setPaymentErrors(null);
        if (payment) {
            setPaymentState({
                transaction: transaction,
                PAYMENT_ID: payment.PAYMENT_ID,
                PAY_MONTH: moment(payment.PAY_MONTH, "YYYYMM"),
                PAYMENT_DATE: moment(payment.PAYMENT_DATE, "YYYYMMDD"),
                PAYMENT_TYPE: ddlData?.paymentTypes.filter(type => type.CODE === payment.PAYMENT_TYPE)[0] || {},
                PAYMENT_MODE: ddlData?.paymentModes.filter(mode => mode.CODE === payment.PAYMENT_MODE)[0] || {},
                EMPLOYEE_ID: ddlData?.employees.filter(employee => employee.CODE === payment.EMPLOYEE_ID)[0] || {},
                TOTAL_AMOUNT: payment.TOTAL_AMOUNT,
                LEAVES: payment.LEAVES,
                DEDUCTIONS: payment.DEDUCTIONS,
                PAID_AMOUNT: payment.PAID_AMOUNT,
                BALANCE_AMOUNT: payment.BALANCE_AMOUNT,
                REMARKS: payment.REMARKS,
            });
        }
        else {
            setPaymentState({
                transaction: transaction,
                PAY_MONTH: moment(),
                PAYMENT_DATE: moment(),
            });
        }
    }, [props]);

    const setPaymentValidationErrors = (errors) => {
        for (let key in errors) {
            // set Error state;
            setPaymentErrors({
                PAYMENT_ID: errors[key].errorPAYMENT_ID,
                PAY_MONTH: errors[key].errorPAY_MONTH,
                PAYMENT_DATE: errors[key].errorPAYMENT_DATE,
                PAYMENT_TYPE: errors[key].errorPAYMENT_TYPE,
                PAYMENT_MODE: errors[key].errorPAYMENT_MODE,
                EMPLOYEE_ID: errors[key].errorEMPLOYEE_ID,
                TOTAL_AMOUNT: errors[key].errorTOTAL_AMOUNT,
                LEAVES: errors[key].errorLEAVES,
                DEDUCTIONS: errors[key].errorDEDUCTIONS,
                PAID_AMOUNT: errors[key].errorPAID_AMOUNT,
                BALANCE_AMOUNT: errors[key].errorBALANCE_AMOUNT,
                REMARKS: errors[key].errorREMARKS,
            });
        }
    }

    const savePayment = async () => {
        // console.log('[PaymentForm] payment state: ', paymentState);

        setSaving(true);
        const checkPaymentState = {
            ...paymentState,
            PAY_MONTH: paymentState.PAY_MONTH.format("YYYYMM"),
            PAYMENT_DATE: paymentState.PAYMENT_DATE.format("YYYYMMDD")
        };
        const validationErrors = await validatePaymentsCRUDData([checkPaymentState]);

        if (Object.keys(validationErrors).length !== 0) {
            setPaymentValidationErrors(validationErrors);
            setSaving(false);
        }
        else
            PaymentsCRUD({
                variables: {
                    "transaction": paymentState.transaction,
                    "payments": [{
                        "PAYMENT_ID": paymentState.PAYMENT_ID,
                        "PAY_MONTH": paymentState.PAY_MONTH.format("YYYYMM"),
                        "PAYMENT_DATE": paymentState.PAYMENT_DATE.format("YYYYMMDD"),
                        "PAYMENT_TYPE": paymentState.PAYMENT_TYPE.CODE,
                        "PAYMENT_MODE": paymentState.PAYMENT_MODE.CODE,
                        "EMPLOYEE_ID": paymentState.EMPLOYEE_ID.CODE,
                        "TOTAL_AMOUNT": paymentState.TOTAL_AMOUNT,
                        "LEAVES": paymentState.LEAVES,
                        "DEDUCTIONS": paymentState.DEDUCTIONS,
                        "PAID_AMOUNT": paymentState.PAID_AMOUNT,
                        "BALANCE_AMOUNT": paymentState.BALANCE_AMOUNT,
                        "REMARKS": paymentState.REMARKS
                    }]
                }
            })
                .then(resp => {
                    //const result = resp.data.result;
                    //console.log('[PaymentsCRUD] Resp: ', result);
                    setSaving(false);
                    handleEmployeePaymentsCRUD();
                })
                .catch(error => {
                    console.log('[PaymentsCRUD] Error:', error.message);
                    setSaving(false);
                    setPaymentErrors({
                        PAYMENT_ID: error.message.split(":")[1],
                    });
                });
    }

    const calculateBalanceAmount = () => {
        const total = paymentState?.TOTAL_AMOUNT || 0;
        const deductions = paymentState?.DEDUCTIONS || 0;
        const paid = paymentState?.PAID_AMOUNT || 0;
        const balance = total - (deductions + paid);
        // setPaymentState({ ...paymentState, BALANCE_AMOUNT: balance });
    }

    return <div>
        <Dialog open={formDialogOpen} onClose={handleFormDialogClose} TransitionComponent={Transition}>
            <div style={{ position: "relative" }}>
                {(saving) && <Loader />}
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleFormDialogClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {transaction === 'CREATE' ? "Add New Payment" : "Update Payment"}
                        </Typography>
                        <Button autoFocus color="secondary" variant="contained" onClick={savePayment}>
                            save
                </Button>
                    </Toolbar>
                </AppBar>

                <Paper variant="elevation" elevation={3} className={classes.paper}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    required
                                    fullWidth
                                    id="pay-month"
                                    label="Pay month"
                                    // format="DD/MM/yyyy"
                                    views={["year", "month"]}
                                    minDate={moment().subtract(6, 'months')}
                                    maxDate={moment()}
                                    value={paymentState?.PAY_MONTH}
                                    error={paymentErrors?.PAY_MONTH ? true : false}
                                    helperText={paymentErrors?.PAY_MONTH}
                                    onChange={date => {
                                        setPaymentState({ ...paymentState, PAY_MONTH: date });
                                        if (date)
                                            setPaymentErrors({ ...paymentErrors, PAY_MONTH: null })
                                        else
                                            setPaymentErrors({ ...paymentErrors, PAY_MONTH: "Pay month is required" })
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    required
                                    fullWidth
                                    id="payment-date"
                                    label="Payment date"
                                    format="DD/MM/yyyy"
                                    value={paymentState?.PAYMENT_DATE}
                                    error={paymentErrors?.PAYMENT_DATE ? true : false}
                                    helperText={paymentErrors?.PAYMENT_DATE}
                                    onChange={date => {
                                        setPaymentState({ ...paymentState, PAYMENT_DATE: date });
                                        if (date)
                                            setPaymentErrors({ ...paymentErrors, PAYMENT_DATE: null })
                                        else
                                            setPaymentErrors({ ...paymentErrors, PAYMENT_DATE: "Payment date is required" })
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <FormControl className={classes.formControl} error={paymentErrors?.EMPLOYEE_ID ? true : false}>
                                <Autocomplete
                                    style={{ marginTop: "-16px" }}
                                    id="EMPLOYEE_ID"
                                    autoComplete
                                    fullWidth
                                    loading={ddlLoading}
                                    options={ddlData?.employees}
                                    getOptionLabel={option => option.DESC ? option.DESC : ""}
                                    renderInput={(params) => (
                                        <TextField required {...params} label="Employee" margin="normal" />
                                    )}
                                    renderOption={(option, { inputValue }) => {
                                        const matches = match(option.DESC, inputValue);
                                        const parts = parse(option.DESC, matches);
                                        return (
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        );
                                    }}
                                    value={paymentState?.EMPLOYEE_ID || {}}
                                    onChange={(event, newValue) => {
                                        setPaymentState({ ...paymentState, EMPLOYEE_ID: newValue });
                                        if (newValue)
                                            setPaymentErrors({ ...paymentErrors, EMPLOYEE_ID: null })
                                        else
                                            setPaymentErrors({ ...paymentErrors, EMPLOYEE_ID: "Please select employee" })
                                    }}

                                />
                                <FormHelperText>{paymentErrors?.EMPLOYEE_ID}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl} error={paymentErrors?.PAYMENT_TYPE ? true : false}>
                                <Autocomplete
                                    style={{ marginTop: "-16px" }}
                                    id="PAYMENT_TYPE"
                                    autoComplete
                                    fullWidth
                                    loading={ddlLoading}
                                    options={ddlData?.paymentTypes}
                                    getOptionLabel={option => option.DESC ? option.DESC : ""}
                                    renderInput={(params) => (
                                        <TextField required {...params} label="Payment type" margin="normal" />
                                    )}
                                    renderOption={(option, { inputValue }) => {
                                        const matches = match(option.DESC, inputValue);
                                        const parts = parse(option.DESC, matches);
                                        return (
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        );
                                    }}
                                    value={paymentState?.PAYMENT_TYPE || {}}
                                    onChange={(event, newValue) => {
                                        setPaymentState({ ...paymentState, PAYMENT_TYPE: newValue });
                                        if (newValue)
                                            setPaymentErrors({ ...paymentErrors, PAYMENT_TYPE: null })
                                        else
                                            setPaymentErrors({ ...paymentErrors, PAYMENT_TYPE: "Please select payment type" })
                                    }}
                                />
                                <FormHelperText>{paymentErrors?.PAYMENT_TYPE}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl} error={paymentErrors?.PAYMENT_MODE ? true : false}>
                                <Autocomplete
                                    style={{ marginTop: "-16px" }}
                                    id="PAYMENT_MODE"
                                    autoComplete
                                    fullWidth
                                    loading={ddlLoading}
                                    options={ddlData?.paymentModes}
                                    getOptionLabel={option => option.DESC ? option.DESC : ""}
                                    renderInput={(params) => (
                                        <TextField required {...params} label="Payment mode" margin="normal" />
                                    )}
                                    renderOption={(option, { inputValue }) => {
                                        const matches = match(option.DESC, inputValue);
                                        const parts = parse(option.DESC, matches);
                                        return (
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        );
                                    }}
                                    value={paymentState?.PAYMENT_MODE || {}}
                                    onChange={(event, newValue) => {
                                        setPaymentState({ ...paymentState, PAYMENT_MODE: newValue });
                                        if (newValue)
                                            setPaymentErrors({ ...paymentErrors, PAYMENT_MODE: null })
                                        else
                                            setPaymentErrors({ ...paymentErrors, PAYMENT_MODE: "Please select payment mode" })
                                    }}
                                />
                                <FormHelperText>{paymentErrors?.PAYMENT_MODE}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="TOTAL_AMOUNT"
                                name="TOTAL_AMOUNT"
                                label="Total amount"
                                fullWidth
                                autoComplete="total-amount"
                                error={paymentErrors?.TOTAL_AMOUNT ? true : false}
                                helperText={paymentErrors?.TOTAL_AMOUNT}
                                value={paymentState?.TOTAL_AMOUNT}
                                onChange={(event) => {
                                    setPaymentState({ ...paymentState, TOTAL_AMOUNT: event.target.value });
                                    if (event.target.value.trim().length !== 0)
                                        setPaymentErrors({ ...paymentErrors, TOTAL_AMOUNT: null });
                                    else
                                        setPaymentErrors({ ...paymentErrors, TOTAL_AMOUNT: "Total amount is required" });

                                    calculateBalanceAmount();
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="LEAVES"
                                name="LEAVES"
                                label="Leaves"
                                fullWidth
                                autoComplete="LEAVES"
                                error={paymentErrors?.LEAVES ? true : false}
                                helperText={paymentErrors?.LEAVES}
                                value={paymentState?.LEAVES}
                                onChange={(event) => {
                                    setPaymentState({ ...paymentState, LEAVES: event.target.value });
                                    if (event.target.value.trim().length !== 0)
                                        setPaymentErrors({ ...paymentErrors, LEAVES: null })
                                    else
                                        setPaymentErrors({ ...paymentErrors, LEAVES: "Leaves are required" })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="DEDUCTIONS"
                                name="DEDUCTIONS"
                                label="Deductible amount"
                                fullWidth
                                autoComplete="DEDUCTIONS"
                                error={paymentErrors?.DEDUCTIONS ? true : false}
                                helperText={paymentErrors?.DEDUCTIONS}
                                value={paymentState?.DEDUCTIONS}
                                onChange={(event) => {
                                    setPaymentState({ ...paymentState, DEDUCTIONS: event.target.value });
                                    if (event.target.value.trim().length !== 0)
                                        setPaymentErrors({ ...paymentErrors, DEDUCTIONS: null });
                                    else
                                        setPaymentErrors({ ...paymentErrors, DEDUCTIONS: "Deductible amount is required" });

                                    calculateBalanceAmount();
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="PAID_AMOUNT"
                                name="PAID_AMOUNT"
                                label="Paid amount"
                                fullWidth
                                autoComplete="PAID_AMOUNT"
                                error={paymentErrors?.PAID_AMOUNT ? true : false}
                                helperText={paymentErrors?.PAID_AMOUNT}
                                value={paymentState?.PAID_AMOUNT}
                                onChange={(event) => {
                                    setPaymentState({ ...paymentState, PAID_AMOUNT: event.target.value });
                                    if (event.target.value.trim().length !== 0)
                                        setPaymentErrors({ ...paymentErrors, PAID_AMOUNT: null });
                                    else
                                        setPaymentErrors({ ...paymentErrors, PAID_AMOUNT: "Paid amount is required" });

                                    calculateBalanceAmount();
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="BALANCE_AMOUNT"
                                name="BALANCE_AMOUNT"
                                label="Balance amount"
                                fullWidth
                                autoComplete="BALANCE_AMOUNT"
                                error={paymentErrors?.BALANCE_AMOUNT ? true : false}
                                helperText={paymentErrors?.BALANCE_AMOUNT}
                                value={paymentState?.BALANCE_AMOUNT}
                                onChange={(event) => {
                                    setPaymentState({ ...paymentState, BALANCE_AMOUNT: event.target.value });
                                    if (event.target.value.trim().length !== 0)
                                        setPaymentErrors({ ...paymentErrors, BALANCE_AMOUNT: null })
                                    else
                                        setPaymentErrors({ ...paymentErrors, BALANCE_AMOUNT: "Balance amount is required" })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                required
                                id="REMARKS"
                                name="REMARKS"
                                label="Remarks"
                                fullWidth
                                autoComplete="REMARKS"
                                error={paymentErrors?.REMARKS ? true : false}
                                helperText={paymentErrors?.REMARKS}
                                value={paymentState?.REMARKS}
                                onChange={(event) => {
                                    setPaymentState({ ...paymentState, REMARKS: event.target.value });
                                    if (event.target.value.trim().length !== 0)
                                        setPaymentErrors({ ...paymentErrors, REMARKS: null })
                                    else
                                        setPaymentErrors({ ...paymentErrors, REMARKS: "Remarks are required" })
                                }}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </Dialog>
    </div>
}

export default PaymentForm;