import React, { useEffect, useState } from "react";
import { useMutation } from '@apollo/client';
import { IconButton, Paper } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { UOM_CRUD } from "../../../../graphql/mutations";
import { checkNull, checkMaxLength } from '../../../../util/validations';
import Loader from "../../../../components/Loader";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        margin: '10px'
    },
    formControl: {
        // margin: theme.spacing(1),
        display: 'flex',
        minWidth: 120,
        margin: 0
    },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validateUOMCRUDData = async (UOM) => {
    let validationObjects = {};
    for (let i = 0; i < UOM.length; i++) {
        let validationObject = {};

        if (UOM[i].transaction !== "CREATE")
            checkNull("UOM_ID", UOM[i].UOM_ID, "UOM Id is required", validationObject);

        checkNull("UOM_NAME", UOM[i].UOM_NAME, "UOM Name is required", validationObject);

        checkMaxLength("UOM_NAME", UOM[i].UOM_NAME, 48, "Length of UOM Name should be less than or equal to 48 characters", validationObject);
        checkMaxLength("UOM_DESC", UOM[i].UOM_DESC, 100, "Length of UOM Description should be less than or equal to 100 characters", validationObject);
        checkMaxLength("UQC", UOM[i].UQC, 48, "Length of UQC should be less than or equal to 48 characters", validationObject);

        if (Object.keys(validationObject).length !== 0)
            validationObjects[i] = validationObject;
    }
    return validationObjects;
}

const UOMForm = (props) => {
    const classes = useStyles();

    const { formDialogOpen, handleFormDialogClose, handleUOMCRUD, transaction, uom } = props;
    const [uomState, setUOMState] = useState(null);
    const [uomErrors, setUOMErrors] = useState(null);
    const [saving, setSaving] = useState(false);

    const [UOMCRUD] = useMutation(UOM_CRUD);

    useEffect(() => {
        setUOMErrors(null);
        if (uom) {
            setUOMState({
                transaction: transaction,
                UOM_ID: uom.UOM_ID,
                UOM_NAME: uom.UOM_NAME,
                UOM_DESC: uom.UOM_DESC,
                UQC: uom.UQC,
            });
        }
        else {
            setUOMState({
                transaction: transaction,
            });
        }
    }, [props]);


    const setUOMValidationErrors = (errors) => {
        for (let key in errors) {
            // set Error state;
            setUOMErrors({
                UOM_ID: errors[key].errorUOM_ID,
                UOM_NAME: errors[key].errorUOM_NAME,
                UOM_DESC: errors[key].errorUOM_DESC,
                UQC: errors[key].errorUQC,
            });
        }
    }

    const saveUOM = async () => {
        // console.log('[UOMForm] uom state: ', uomState);
        setSaving(true);
        const validationErrors = await validateUOMCRUDData([uomState]);
        if (Object.keys(validationErrors).length !== 0) {
            setUOMValidationErrors(validationErrors);
            setSaving(false);
        }
        else
            UOMCRUD({
                variables: {
                    "transaction": uomState.transaction,
                    "uom": [{
                        "UOM_ID": uomState.UOM_ID,
                        "UOM_NAME": uomState.UOM_NAME,
                        "UOM_DESC": uomState.UOM_DESC,
                        "UQC": uomState.UQC
                    }]
                }
            })
                .then(resp => {
                    //const result = resp.data.result;
                    //console.log('[UOMCRUD] Resp: ', result);
                    // setUOMState({});
                    setSaving(false);
                    setUOMErrors(null);
                    handleUOMCRUD();
                })
                .catch(error => {
                    console.log('[UOMCRUD] Error:', error);
                    setSaving(false);
                    setUOMErrors({
                        UOM_NAME: error.message.split(":")[1],
                    });
                });
    }

    return <Dialog open={formDialogOpen} onClose={handleFormDialogClose} TransitionComponent={Transition}>
        <div style={{ position: "relative" }}>
            {(saving) && <Loader />}
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleFormDialogClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {transaction === 'CREATE' ? "Add New UOM" : "Update UOM"}
                    </Typography>
                    <Button autoFocus color="secondary" variant="contained" onClick={saveUOM}>
                        save
                </Button>
                </Toolbar>
            </AppBar>

            <Paper variant="elevation" elevation={3} className={classes.paper}>
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="UOM_NAME"
                            name="UOM_NAME"
                            fullWidth
                            label="UOM name"
                            autoComplete="uom-name"
                            error={uomErrors?.UOM_NAME ? true : false}
                            helperText={uomErrors?.UOM_NAME}
                            value={uomState?.UOM_NAME}
                            onChange={(event) => {
                                setUOMState({ ...uomState, UOM_NAME: event.target.value });
                                if (event.target.value.trim().length !== 0)
                                    setUOMErrors({ ...uomErrors, UOM_NAME: null })
                                else
                                    setUOMErrors({ ...uomErrors, UOM_NAME: "UOM name is required" })
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="UOM_DESC"
                            name="UOM_DESC"
                            fullWidth
                            label="UOM description"
                            autoComplete="uom-description"
                            error={uomErrors?.UOM_DESC ? true : false}
                            helperText={uomErrors?.UOM_DESC}
                            value={uomState?.UOM_DESC}
                            onChange={(event) => setUOMState({ ...uomState, UOM_DESC: event.target.value })}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="UQC"
                            name="UQC"
                            fullWidth
                            label="UQC"
                            autoComplete="uqc"
                            error={uomErrors?.UQC ? true : false}
                            helperText={uomErrors?.UQC}
                            value={uomState?.UQC}
                            onChange={(event) => setUOMState({ ...uomState, UQC: event.target.value })}
                        />
                    </Grid>

                </Grid>
            </Paper>
        </div>
    </Dialog>
}

export default UOMForm;