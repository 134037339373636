import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../Title';

const Activities = ({ activities }) => {
    return (
        <div style={{ overflow: "hidden" }}>
            <Title>Recent Activities</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Site/Plant</TableCell>
                        <TableCell>Item</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell align="right">Rate</TableCell>
                        <TableCell align="right">Amount</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {activities.map((row) => (
                        <TableRow key={row.ENTRY_ID}>
                            <TableCell>{row.ENTRY_DATE}</TableCell>
                            <TableCell>{row.ENTRY_TYPE}</TableCell>
                            <TableCell>{row.SITE_NAME}</TableCell>
                            <TableCell>{row.ITEM_NAME}</TableCell>
                            <TableCell>{row.ITEM_QUANTITY}</TableCell>
                            <TableCell align="right">{row.ITEM_RATE}</TableCell>
                            <TableCell align="right">{row.TOTAL_AMOUNT}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}

export default Activities;