import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { useLazyQuery } from '@apollo/client';
import { Button, Grid, IconButton, Paper, Snackbar, Tooltip } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import moment from "moment";
import { MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { SEARCH_FDR_ENTRIES } from "../../../../graphql/queries";
import { globalTableOptions, globalTableTheme } from "../../../../theme";
import ImageGrid from "../../../../components/Images/ImagesGrid";
import Loader from "../../../../components/Loader";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginBottom: '10px'
    },
    fixedHeight: {
        height: 240,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FDRList = () => {
    const classes = useStyles();

    const [fdrFromDate, setFDRFromDate] = React.useState(moment().subtract(1, 'months'));
    const [fdrToDate, setFDRToDate] = React.useState(moment());
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [images, setImages] = useState([]);

    // apollo queries and mutations
    const [getFDRList, { loading, data, error }] = useLazyQuery(SEARCH_FDR_ENTRIES);

    // inital effect
    useEffect(() => {
        //console.log('Calling useEffect()....');
        getFDRList({
            variables: {
                fdrFromDate: fdrFromDate.format("YYYYMMDD"),
                fdrToDate: fdrToDate.format("YYYYMMDD")
            }
        });
    }, []);

    // search FDR entries
    const searchFDREntries = () => {
        //console.log('Calling searchFDREntries()....');
        getFDRList({
            variables: {
                fdrFromDate: fdrFromDate?.format("YYYYMMDD") || moment().subtract(1, 'months').format("YYYYMMDD"),
                fdrToDate: fdrToDate?.format("YYYYMMDD") || moment().format("YYYYMMDD"),
            }
        });
    };

    const handleImageDialogClose = () => {
        setImageDialogOpen(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    };

    if (error) {
        setSnackbarSeverity("error");
        setSnackbarMessage(error);
        setOpenSnackbar(true);
    };

    const tableColumns = [
        {
            name: "FDR_ID",
            label: "FDR ID",
            options: {
                filter: false,
                sort: true,
                display: false
            }
        }, {
            name: "FDR_DATE_FRMT",
            label: "FDR Date",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "SITE_NAME",
            label: "Site/Plant",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "NAME_OF_WORK",
            label: "Name of Work",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "AMOUNT",
            label: "Amount",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "MATURITY_DATE_FRMT",
            label: "Maturity Date",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "REMARKS",
            label: "Remarks",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "ACTIONS",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const tableData = tableMeta.tableData;
                    const rowIndex = tableMeta.rowIndex;
                    // console.log('Selcted tableMeta: ', tableMeta);
                    return <>
                        <Tooltip title="View Images">
                            <IconButton
                                style={{ color: "#17A2B8" }}
                                onClick={() => {
                                    setImages(JSON.parse(tableData[rowIndex].IMAGES));
                                    setImageDialogOpen(true);
                                }}
                            >
                                <PhotoLibraryIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            }
        },
    ];

    let tableData = [];
    if (data) {
        //console.log('FDR entries: ', data.result);
        tableData = data.result;
    }

    const tableOptions = {
        ...globalTableOptions,
        downloadOptions: {
            filename: "fdr-list.csv"
        },
        customTableBodyFooterRender: (opts) => {
            // console.log('[Arrival] customTableBodyFooterRender:>> ', opts?.data);
            return <TableFooter>
                <TableRow style={{ whiteSpace: "nowrap",}}>
                    <TableCell colSpan={2} >
                    </TableCell>
                    <TableCell
                        style={{
                            position: "sticky",
                            left: "0",
                            zIndex: 101,
                        }}
                    >
                        <Typography variant="subtitle2" >
                            TOTAL
                        </Typography>
                    </TableCell>
                    <TableCell colSpan={4}>
                        <Typography variant="subtitle2">
                            {"₹ " + opts?.data.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.data[4]), 0).toFixed(2)}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableFooter>;
        }        
    };

    return <div style={{ position: "relative" }}>
        {/* laoder */}
        {(loading) && <Loader />}

        {/* search container */}
        <Paper variant="elevation" elevation={3} className={classes.paper}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            required
                            id="fdr-from-date"
                            // placeholder="05/11/2020"
                            label="From Date"
                            value={fdrFromDate}
                            onChange={date => setFDRFromDate(date)}
                            format="DD/MM/yyyy"
                        />
                    </MuiPickersUtilsProvider></Grid>
                <Grid item xs={12} md={4}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                            required
                            id="fdr-to-date"
                            //placeholder="05/11/2020"
                            label="To Date"
                            value={fdrToDate}
                            onChange={date => setFDRToDate(date)}
                            format="DD/MM/yyyy"
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={searchFDREntries}
                        style={{ marginTop: "10px" }}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>
        </Paper>

        {/* data table */}
        <MuiThemeProvider theme={globalTableTheme}>
            <MUIDataTable
                title={"FDR"}
                data={tableData}
                columns={tableColumns}
                options={tableOptions}
            />
        </MuiThemeProvider>

        {/* snackbar */}
        <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleSnackbarClose}
        >
            <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
                {snackbarMessage}
            </Alert>
        </Snackbar>

        {/* View Images Dialog */}
        <ImageGrid
            imageDialogOpen={imageDialogOpen}
            handleImageDialogClose={handleImageDialogClose}
            images={images}
        />
    </div>
}

export default FDRList;