import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useLazyQuery, useMutation } from '@apollo/client';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar, Tooltip } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { MuiThemeProvider } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { SEARCH_UOM } from "../../../../graphql/queries";
import { UOM_CRUD } from "../../../../graphql/mutations";
import { globalTableOptions, globalTableTheme } from "../../../../theme";
import Loader from "../../../../components/Loader";
import UOMForm from "../UOMForm";

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TypeList = () => {
    const [getUOMList, { loading, data, error, refetch: refetchUOMList }] = useLazyQuery(SEARCH_UOM);
    const [UOMCRUD] = useMutation(UOM_CRUD);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [formDialogOpen, setFormDialogOpen] = useState(false);
    const [transaction, setTransaction] = useState("");
    const [uom, setUOM] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [uomID, setUOMID] = useState("");
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        getUOMList();
    }, [getUOMList]);

    const handleFormDialogClose = () => {
        setFormDialogOpen(false);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    if (error) {
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        setSnackbarMessage(error);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    };

    const handleUOMCRUD = () => {
        setFormDialogOpen(false);
        //setMaterial(null);
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        setSnackbarMessage(`UOM ${transaction === 'CREATE' ? 'added' : 'updated'} successfully!`);
        refetchUOMList();
    };

    const deleteUOM = () => {
        setDeleting(true);
        UOMCRUD({
            variables: {
                "transaction": "LOGICAL_DELETE",
                "uom": [{
                    "UOM_ID": uomID,
                }]
            }
        })
            .then(resp => {
                // const result = resp.data.result;
                // console.log('[DELETE UOM] Resp: ', result);
                setDeleteDialogOpen(false);
                setSnackbarSeverity("success");
                setOpenSnackbar(true);
                setSnackbarMessage("UOM removed successfully!");
                refetchUOMList();
                setDeleting(false);
            })
            .catch(error => {
                console.log('[DELETE UOM] Error:', error.message);
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
                setSnackbarMessage(JSON.parse(error.message)["0"].errorUOM_ID);
                setDeleting(false);
            });
    };

    const tableColumns = [
        {
            name: "UOM_ID",
            label: "UOM ID",
            options: {
                filter: false,
                sort: true,
                display: false
            }
        }, {
            name: "UOM_NAME",
            label: "UOM",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "UOM_DESC",
            label: "UOM Description",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "UQC",
            label: "UQC",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "ACTIONS",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    //const tableData = tableMeta.tableData;
                    const tableData = data?.result;
                    const rowIndex = tableMeta.rowIndex;
                    return <>
                        <Tooltip title="Edit">
                            <IconButton
                                style={{ color: "#343a40" }}
                                onClick={() => {
                                    setUOM(tableData[rowIndex]);
                                    setTransaction('UPDATE');
                                    setFormDialogOpen(true);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton
                                style={{ color: "#dc3545" }}
                                onClick={() => {
                                    setUOMID(tableData[rowIndex].UOM_ID);
                                    setDeleteDialogOpen(true);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            }
        },
    ];


    let tableData = [];
    if (data) {
        //console.log('Usage uom list:', data.result);
        tableData = data.result;
    }

    const tableOptions = {
        ...globalTableOptions,
        downloadOptions: {
            filename: "uom-list.csv"
        },
    };

    return <div style={{ position: "relative" }}>
        {(loading) && <Loader />}

        {/* add new uom */}
        <Tooltip title="Add New">
            <Fab
                size="small"
                //color="#4caf50" 
                aria-label="add"
                onClick={() => {
                    setUOM(null);
                    setTransaction('CREATE');
                    setFormDialogOpen(true);
                }}
                style={{ float: "right", marginTop: "12px", marginRight: "12px", color: "#FFFFFF", backgroundColor: "#4caf50" }}
            >
                <AddIcon />
            </Fab>
        </Tooltip>

        {/* data table */}
        <MuiThemeProvider theme={globalTableTheme}>
            <MUIDataTable
                title={"Units of Measurement"}
                data={tableData}
                columns={tableColumns}
                options={tableOptions}
            />
        </MuiThemeProvider>

        {/* snackbar */}
        <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleSnackbarClose}
        >
            <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
                {snackbarMessage}
            </Alert>
        </Snackbar>

        {/* create/update form */}
        <UOMForm
            formDialogOpen={formDialogOpen}
            handleFormDialogClose={handleFormDialogClose}
            handleUOMCRUD={handleUOMCRUD}
            transaction={transaction}
            uom={uom}
        />

        {/* delete action dialog */}
        <Dialog
            open={deleteDialogOpen}
            onClose={handleDeleteDialogClose}
            aria-labelledby="delete-uom"
            aria-describedby="delete-dialog-description"
        >
            <div style={{ position: "relative" }}>
                {(deleting) && <Loader />}
                <DialogTitle id="delete-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        The uom will be deleted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={deleteUOM} variant="contained" color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </div>
        </Dialog>

    </div>
}

export default TypeList;