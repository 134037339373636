import axios from "axios";

export const imgbb = axios.create({
    baseURL: "https://api.imgbb.com/1/upload",
    headers: {
        'content-type': 'multipart/form-data',
    },
    params: {
        key: "d365a394ab24cc2705e08575f816365d"
    }
});