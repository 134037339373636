import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { FormControl, FormHelperText, IconButton, Paper } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { POPULATE_MATERIALS_DDL } from "../../../../graphql/queries";
import { MATERIAL_USAGE_TYPES_CRUD } from "../../../../graphql/mutations";
import { checkNull, checkMaxLength } from '../../../../util/validations';
import Loader from "../../../../components/Loader";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        margin: '10px'
    },
    formControl: {
        // margin: theme.spacing(1),
        display: 'flex',
        minWidth: 120,
        margin: 0
    },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validateTypesCRUDData = async (Types) => {
    let validationObjects = {};
    for (let i = 0; i < Types.length; i++) {
        let validationObject = {};

        if (Types[i].transaction !== "CREATE")
            checkNull("USAGE_TYPE_ID", Types[i].USAGE_TYPE_ID, "Type Id is required", validationObject);

        checkNull("USAGE_TYPE_DESC", Types[i].USAGE_TYPE_DESC, "Type description is required", validationObject);
        checkNull("MATERIAL_ID", Types[i].MATERIAL_ID?.CODE, "Please select material", validationObject);

        checkMaxLength("USAGE_TYPE_ID", Types[i].USAGE_TYPE_ID, 48, "Length of Type Id should be less than or equal to 48 characters", validationObject);
        checkMaxLength("USAGE_TYPE_DESC", Types[i].USAGE_TYPE_DESC, 100, "Length of Type Description should be less than or equal to 100 characters", validationObject);
        checkMaxLength("MATERIAL_ID", Types[i].MATERIAL_ID?.CODE, 48, "Length of Material Id should be less than or equal to 48 characters", validationObject);

        if (Object.keys(validationObject).length !== 0)
            validationObjects[i] = validationObject;
    }
    return validationObjects;
}

const TypeForm = (props) => {
    const classes = useStyles();

    const { formDialogOpen, handleFormDialogClose, handleMaterialUsageTypesCRUD, transaction, type } = props;
    const [typeState, setTypeState] = useState(null);
    const [typeErrors, setTypeErrors] = useState(null);
    const [saving, setSaving] = useState(false);

    const { loading: materialsLoading, error: materialsError, data: materials } = useQuery(POPULATE_MATERIALS_DDL, { variables: { "materialType": "%" } });
    const [MaterialUsageTypesCRUD] = useMutation(MATERIAL_USAGE_TYPES_CRUD);

    useEffect(() => {
        setTypeErrors(null);
        if (type) {
            setTypeState({
                transaction: transaction,
                USAGE_TYPE_ID: type.USAGE_TYPE_ID,
                USAGE_TYPE_DESC: type.USAGE_TYPE_DESC,
                MATERIAL_ID: materials.materials.filter(material => material.CODE === type.MATERIAL_ID)[0] || {},
            });
        }
        else {
            setTypeState({
                transaction: transaction,
            });
        }
    }, [props]);


    const setTypeValidationErrors = (errors) => {
        for (let key in errors) {
            // set Error state;
            setTypeErrors({
                USAGE_TYPE_ID: errors[key].errorUSAGE_TYPE_ID,
                USAGE_TYPE_DESC: errors[key].errorUSAGE_TYPE_DESC,
                MATERIAL_ID: errors[key].errorMATERIAL_ID,
            });
        }
    }

    const saveType = async () => {
        // console.log('[TypeForm] type state: ', typeState);
        setSaving(true);
        const validationErrors = await validateTypesCRUDData([typeState]);
        if (Object.keys(validationErrors).length !== 0) {
            setTypeValidationErrors(validationErrors);
            setSaving(false);
        }
        else
            MaterialUsageTypesCRUD({
                variables: {
                    "transaction": typeState.transaction,
                    "types": [{
                        "USAGE_TYPE_ID": typeState.USAGE_TYPE_ID,
                        "USAGE_TYPE_DESC": typeState.USAGE_TYPE_DESC,
                        "MATERIAL_ID": typeState.MATERIAL_ID?.CODE
                    }]
                }
            })
                .then(resp => {
                    //const result = resp.data.result;
                    //console.log('[MaterialUsageTypesCRUD] Resp: ', result);
                    // setTypeState({});
                    setSaving(false);
                    handleMaterialUsageTypesCRUD();
                })
                .catch(error => {
                    console.log('[MaterialUsageTypesCRUD] Error:', error.message);
                    setSaving(false);
                    setTypeValidationErrors(JSON.parse(error));
                });
    }

    return <Dialog open={formDialogOpen} onClose={handleFormDialogClose} TransitionComponent={Transition}>
        <div style={{ position: "relative" }}>
            {(saving) && <Loader />}
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleFormDialogClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {transaction === 'CREATE' ? "Add New Usage Type" : "Update Usage Type"}
                    </Typography>
                    <Button autoFocus color="secondary" variant="contained" onClick={saveType}>
                        save
                </Button>
                </Toolbar>
            </AppBar>

            <Paper variant="elevation" elevation={3} className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <FormControl className={classes.formControl} error={typeErrors?.MATERIAL_ID ? true : false}>
                            <Autocomplete
                                style={{ marginTop: "-16px" }}
                                id="MATERIAL_ID"
                                autoComplete
                                fullWidth
                                loading={materialsLoading}
                                options={materials?.materials}
                                getOptionLabel={option => option.DESC ? option.DESC : ""}
                                renderInput={(params) => (
                                    <TextField required {...params} label="Material" margin="normal" />
                                )}
                                renderOption={(option, { inputValue }) => {
                                    const matches = match(option.DESC, inputValue);
                                    const parts = parse(option.DESC, matches);
                                    return (
                                        <div>
                                            {parts.map((part, index) => (
                                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    );
                                }}
                                value={typeState?.MATERIAL_ID || {}}
                                onChange={(event, newValue) => {
                                    setTypeState({ ...typeState, MATERIAL_ID: newValue });
                                    if (newValue)
                                        setTypeErrors({ ...typeErrors, MATERIAL_ID: null })
                                    else
                                        setTypeErrors({ ...typeErrors, MATERIAL_ID: "Please select material" })
                                }}
                            />
                            <FormHelperText>{typeErrors?.MATERIAL_ID}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="USAGE_TYPE_DESC"
                            name="USAGE_TYPE_DESC"
                            fullWidth
                            label="Type Description"
                            autoComplete="type-description"
                            error={typeErrors?.USAGE_TYPE_DESC ? true : false}
                            helperText={typeErrors?.USAGE_TYPE_DESC}
                            value={typeState?.USAGE_TYPE_DESC}
                            onChange={(event) => {
                                setTypeState({ ...typeState, USAGE_TYPE_DESC: event.target.value });
                                if (event.target.value.trim().length !== 0)
                                    setTypeErrors({ ...typeErrors, USAGE_TYPE_DESC: null })
                                else
                                    setTypeErrors({ ...typeErrors, USAGE_TYPE_DESC: "Type description is required" })
                            }}
                        />
                    </Grid>

                </Grid>
            </Paper>
        </div>
    </Dialog>
}

export default TypeForm;