import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { isValidSession } from "../../util/auth";
import AmountSpentHeader from './components/AmountSpentHeader';
import AmountSpentDetails from './components/AmountSpentDetails';
import MaterialStock from './components/MaterialStock';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`analysis-tabpanel-${index}`}
            aria-labelledby={`analysis-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `analysis-tab-${index}`,
        'aria-controls': `analysis-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
    button: {
        margin: theme.spacing(1),
        marginTop: "-10px",
    },
}));

const Analysis = () => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [item, setItem] = useState(null);
    const ref = useRef(null);
    let history = useHistory();

    useEffect(() => {
        isValidSession()
            .then(result => {
                if (result)
                    ref.current.scrollIntoView({ behavior: 'smooth' });
                else
                    history.push("/signin");
            })
            .catch(error => {
                console.log('[Analysis] Valid session error: ', error);
                history.push("/signin");
            })
    }, []);

    const handleChange = (event, newValue, item) => {
        setValue(newValue);
        setItem(item);
    };

    return (
        <div ref={ref} className={classes.root}>
            <Tabs
                value={value}
                aria-label="sites"
                textColor="primary"
                onChange={handleChange}
            >
                <Tab label="Material Stock" {...a11yProps(0)} />
                <Tab disabled={value > 1} label="Total Amount Spent" {...a11yProps(1)} />
                {(value === 2) && <Tab wrapped disabled label="Details of Total Amount Spent" {...a11yProps(2)} />}
            </Tabs>

            <TabPanel value={value} index={0}>
                <MaterialStock handleTabChange={handleChange} item={item} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AmountSpentHeader handleTabChange={handleChange} item={item} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    startIcon={<ArrowBackIcon />}
                    onClick={(event) => handleChange(event, 1, item)}
                >
                    Back
                </Button>
                <AmountSpentDetails item={item} />
            </TabPanel>
        </div>
    );
}


export default Analysis;