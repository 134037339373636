import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { useMutation, useQuery } from '@apollo/client';
import { CardMedia, CircularProgress, FormControl, FormControlLabel, FormHelperText, IconButton, Paper, Switch } from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { POPULATE_UOM_DDL } from "../../../../graphql/queries";
import { MACHINERY_CRUD } from "../../../../graphql/mutations";
import { checkNull, checkMaxLength } from '../../../../util/validations';
import { green } from "@material-ui/core/colors";
import Loader from "../../../../components/Loader";
import { imgbb } from "../../../../util/axios";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        margin: '10px'
    },
    formControl: {
        // margin: theme.spacing(1),
        display: 'flex',
        minWidth: 120,
        margin: 0
    },
    input: {
        display: 'none',
    },
    button: {
        margin: theme.spacing(1),
    },
    media: {
        height: 140,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
    },
    uploadSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validateMachineryCRUDData = async (Machinery) => {
    let validationObjects = {};
    for (let i = 0; i < Machinery.length; i++) {
        let validationObject = {};

        if (Machinery[i].transaction !== "CREATE")
            checkNull("MACHINERY_ID", Machinery[i].MACHINERY_ID, "Machinery Id is required", validationObject);

        checkNull("MACHINERY_NAME", Machinery[i].MACHINERY_NAME, "Machinery Name is required", validationObject);
        checkNull("MACHINERY_IMAGE", Machinery[i].MACHINERY_IMAGE, "Machinery Image URL is required", validationObject);
        checkNull("MILEAGE_RANGE_FROM", Machinery[i].MILEAGE_RANGE_FROM, "Mileage range from is required", validationObject);
        checkNull("MILEAGE_RANGE_TO", Machinery[i].MILEAGE_RANGE_TO, "Mileage range to is required", validationObject);
        checkNull("MILEAGE_UOM", Machinery[i].MILEAGE_UOM?.CODE, "Mileage UOM is required", validationObject);
        checkNull("IS_ACTIVE", Machinery[i].IS_ACTIVE, "Please select active/inactive", validationObject);

        checkMaxLength("IS_ACTIVE", Machinery[i].IS_ACTIVE, 1, "Length of Active/Inactive should be equal to 1 character", validationObject);
        checkMaxLength("MACHINERY_NAME", Machinery[i].MACHINERY_NAME, 100, "Length of Machinery Name should be less than or equal to 100 characters", validationObject);
        checkMaxLength("MACHINERY_DESC", Machinery[i].MACHINERY_DESC, 500, "Length of Machinery Description should be less than or equal to 500 characters", validationObject);
        checkMaxLength("MACHINERY_IMAGE", Machinery[i].MACHINERY_IMAGE, 500, "Length of Machinery Image URL should be less than or equal to 500 characters", validationObject);
        checkMaxLength("MILEAGE_RANGE_FROM", Machinery[i].MILEAGE_RANGE_FROM, 12, "Length of Mileage Range From should be less than or equal to 12 characters", validationObject);
        checkMaxLength("MILEAGE_RANGE_TO", Machinery[i].MILEAGE_RANGE_TO, 12, "Length of Mileage Range To should be less than or equal to 12 characters", validationObject);
        checkMaxLength("MILEAGE_UOM", Machinery[i].MILEAGE_UOM?.CODE, 48, "Length of Mileage UOM should be less than or equal to 48 characters", validationObject);

        if (Object.keys(validationObject).length !== 0)
            validationObjects[i] = validationObject;
    }
    return validationObjects;
}

const MachineryForm = (props) => {
    const classes = useStyles();

    const { formDialogOpen, handleFormDialogClose, handleMachineryCRUD, transaction, machinery } = props;
    const [machineryState, setMachineryState] = useState(null);
    const [machineryErrors, setMachineryErrors] = useState(null);
    const [saving, setSaving] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const [MachineryCRUD] = useMutation(MACHINERY_CRUD);
    const { loading: uomLoading, error: uomError, data: uom } = useQuery(POPULATE_UOM_DDL);

    const uploadSuccessClass = clsx({
        [classes.uploadSuccess]: uploadSuccess,
    });

    useEffect(() => {
        setUploadSuccess(false);
        setMachineryErrors(false);
        if (machinery) {
            setMachineryState({
                transaction: transaction,
                MACHINERY_ID: machinery.MACHINERY_ID,
                MACHINERY_NAME: machinery.MACHINERY_NAME,
                MACHINERY_DESC: machinery.MACHINERY_DESC,
                MACHINERY_IMAGE: machinery.MACHINERY_IMAGE,
                MILEAGE_RANGE_FROM: machinery.MILEAGE_RANGE_FROM,
                MILEAGE_RANGE_TO: machinery.MILEAGE_RANGE_TO,
                MILEAGE_UOM: uom.uom.filter(uom => uom.CODE === machinery.MILEAGE_UOM)[0] || {},
                IS_ACTIVE: machinery.IS_ACTIVE,
            });
        }
        else {
            setMachineryState({
                transaction: transaction,
                IS_ACTIVE: "Y",
            });
        }
    }, [props]);

    const uploadImagetoImgBB = async (imageFile) => {
        try {
            setUploading(true);
            const formData = new FormData();
            formData.append('image', imageFile);

            const result = await imgbb({
                method: "POST",
                data: formData
            });

            //console.log("ImgBB Resp: ", result);
            setMachineryState({ ...machineryState, MACHINERY_IMAGE: result.data.data.display_url });
            setUploading(false);
            setUploadSuccess(true);
        } catch (error) {
            console.log("ImgBB error:", error);
            setUploading(false);
            setUploadSuccess(false);
        };
    }

    const setMachineryValidationErrors = (errors) => {
        for (let key in errors) {
            // set Error state;
            setMachineryErrors({
                MACHINERY_ID: errors[key].errorMACHINERY_ID,
                MACHINERY_NAME: errors[key].errorMACHINERY_NAME,
                MACHINERY_DESC: errors[key].errorMACHINERY_DESC,
                MACHINERY_IMAGE: errors[key].errorMACHINERY_IMAGE,
                MILEAGE_RANGE_FROM: errors[key].errorMILEAGE_RANGE_FROM,
                MILEAGE_RANGE_TO: errors[key].errorMILEAGE_RANGE_TO,
                MILEAGE_UOM: errors[key].errorMILEAGE_UOM,
                IS_ACTIVE: errors[key].errorIS_ACTIVE,
            });
        }
    }

    const saveMachinery = async () => {
        // console.log('[MachineryForm] machinery state: ', machineryState);
        setSaving(true);
        const validationErrors = await validateMachineryCRUDData([machineryState]);
        if (Object.keys(validationErrors).length !== 0) {
            setMachineryValidationErrors(validationErrors);
            setSaving(false);
        }
        else
            MachineryCRUD({
                variables: {
                    "transaction": machineryState.transaction,
                    "machinery": [{
                        "MACHINERY_ID": machineryState.MACHINERY_ID,
                        "MACHINERY_NAME": machineryState.MACHINERY_NAME,
                        "MACHINERY_DESC": machineryState.MACHINERY_DESC,
                        "MACHINERY_IMAGE": machineryState.MACHINERY_IMAGE,
                        "MILEAGE_RANGE_FROM": machineryState.MILEAGE_RANGE_FROM,
                        "MILEAGE_RANGE_TO": machineryState.MILEAGE_RANGE_TO,
                        "MILEAGE_UOM": machineryState.MILEAGE_UOM.CODE,
                        "IS_ACTIVE": machineryState.IS_ACTIVE
                    }]
                }
            })
                .then(resp => {
                    //const result = resp.data.result;
                    //console.log('[MachineryCRUD] Resp: ', result);
                    // setMachineryState({});
                    setSaving(false);
                    handleMachineryCRUD();
                })
                .catch(error => {
                    console.log('[MachineryCRUD] Error:', error.message);
                    setSaving(false);
                    setMachineryValidationErrors(JSON.parse(error));
                });
    }

    return <Dialog open={formDialogOpen} onClose={handleFormDialogClose} TransitionComponent={Transition}>
        <div style={{ position: "relative" }}>
            {(saving) && <Loader />}
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleFormDialogClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {transaction === 'CREATE' ? "Add New Machinery" : "Update Machinery"}
                    </Typography>
                    <Button autoFocus color="secondary" variant="contained" onClick={saveMachinery}>
                        save
                </Button>
                </Toolbar>
            </AppBar>

            <Paper variant="elevation" elevation={3} className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="MACHINERY_NAME"
                            name="MACHINERY_NAME"
                            label="Machinery name"
                            fullWidth
                            autoComplete="machinery-name"
                            error={machineryErrors?.MACHINERY_NAME ? true : false}
                            helperText={machineryErrors?.MACHINERY_NAME}
                            value={machineryState?.MACHINERY_NAME}
                            onChange={(event) => {
                                setMachineryState({ ...machineryState, MACHINERY_NAME: event.target.value });
                                if (event.target.value.trim().length !== 0)
                                    setMachineryErrors({ ...machineryErrors, MACHINERY_NAME: null })
                                else
                                    setMachineryErrors({ ...machineryErrors, MACHINERY_NAME: "Machinery Name is required" })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="MACHINERY_DESC"
                            name="MACHINERY_DESC"
                            fullWidth
                            label="Description"
                            autoComplete="machinery-description"
                            value={machineryState?.MACHINERY_DESC}
                            onChange={(event) => setMachineryState({ ...machineryState, MACHINERY_DESC: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="MILEAGE_RANGE_FROM"
                            name="MILEAGE_RANGE_FROM"
                            label="Mileage Range From"
                            fullWidth
                            autoComplete="machinery-mileage-from"
                            error={machineryErrors?.MILEAGE_RANGE_FROM ? true : false}
                            helperText={machineryErrors?.MILEAGE_RANGE_FROM}
                            value={machineryState?.MILEAGE_RANGE_FROM}
                            onChange={(event) => {
                                setMachineryState({ ...machineryState, MILEAGE_RANGE_FROM: event.target.value });
                                if (event.target.value.trim().length !== 0)
                                    setMachineryErrors({ ...machineryErrors, MILEAGE_RANGE_FROM: null })
                                else
                                    setMachineryErrors({ ...machineryErrors, MILEAGE_RANGE_FROM: "Machinery mileage range from is required" })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="MILEAGE_RANGE_TO"
                            name="MILEAGE_RANGE_TO"
                            label="Mileage Range To"
                            fullWidth
                            autoComplete="machinery-mileage-to"
                            error={machineryErrors?.MILEAGE_RANGE_TO ? true : false}
                            helperText={machineryErrors?.MILEAGE_RANGE_TO}
                            value={machineryState?.MILEAGE_RANGE_TO}
                            onChange={(event) => {
                                setMachineryState({ ...machineryState, MILEAGE_RANGE_TO: event.target.value });
                                if (event.target.value.trim().length !== 0)
                                    setMachineryErrors({ ...machineryErrors, MILEAGE_RANGE_TO: null })
                                else
                                    setMachineryErrors({ ...machineryErrors, MILEAGE_RANGE_TO: "Machinery mileage range to is required" })
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} error={machineryErrors?.MILEAGE_UOM ? true : false}>
                            <Autocomplete
                                style={{ marginTop: "-16px" }}
                                id="MILEAGE_UOM"
                                autoComplete
                                fullWidth
                                loading={uomLoading}
                                options={uom?.uom}
                                getOptionLabel={option => option.DESC ? option.DESC : ""}
                                renderInput={(params) => (
                                    <TextField required {...params} label="Mileage UOM" margin="normal" />
                                )}
                                renderOption={(option, { inputValue }) => {
                                    const matches = match(option.DESC, inputValue);
                                    const parts = parse(option.DESC, matches);
                                    return (
                                        <div>
                                            {parts.map((part, index) => (
                                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    );
                                }}
                                value={machineryState?.MILEAGE_UOM || {}}
                                onChange={(event, newValue) => {
                                    setMachineryState({ ...machineryState, MILEAGE_UOM: newValue });
                                    if (newValue)
                                        setMachineryErrors({ ...machineryErrors, MILEAGE_UOM: null })
                                    else
                                        setMachineryErrors({ ...machineryErrors, MILEAGE_UOM: "Mileage UOM is required" })
                                }}
                            />
                            <FormHelperText>{machineryErrors?.MILEAGE_UOM}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            label={machineryState?.IS_ACTIVE === 'Y' ? "Active" : "Inactive"}
                            control={
                                <Switch
                                    checked={machineryState?.IS_ACTIVE === "Y" ? true : false}
                                    name="IS_ACTIVE"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    onChange={(event) => setMachineryState({ ...machineryState, IS_ACTIVE: event.target.checked ? "Y" : "N" })}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="imageName"
                            name="ImageName"
                            label="Image"
                            required
                            fullWidth
                            disabled
                            value={machineryState?.MACHINERY_IMAGE}
                            error={machineryErrors?.MACHINERY_IMAGE ? true : false}
                            helperText={machineryErrors?.MACHINERY_IMAGE}
                            InputLabelProps={{ shrink: machineryState?.MACHINERY_IMAGE && true }}
                        />

                        <input
                            accept="image/*"
                            id="MACHINERY_IMAGE"
                            type="file"
                            className={classes.input}
                            onChange={(event) => {
                                setUploadSuccess(false);
                                uploadImagetoImgBB(event.target.files[0]);
                            }}
                        />

                        <label htmlFor="MACHINERY_IMAGE">
                            <div className={classes.wrapper}>
                                <Fab variant="extended" component="span" size="medium" className={uploadSuccessClass}>
                                    {uploadSuccess ? <CheckIcon className={classes.extendedIcon} /> : <PhotoCamera className={classes.extendedIcon} />}
                                    {uploadSuccess ? "Success" : "Browse"}
                                </Fab>
                                {uploading && <CircularProgress size={44} className={classes.fabProgress} />}
                            </div>
                        </label>
                    </Grid>

                    {machineryState?.MACHINERY_IMAGE && <Grid item xs={12} sm={6}>
                        <CardMedia
                            className={classes.media}
                            image={machineryState?.MACHINERY_IMAGE}
                            title="Machinery Image"
                        />
                    </Grid>
                    }

                </Grid>
            </Paper>
        </div>
    </Dialog>
}

export default MachineryForm;