import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { useLazyQuery } from '@apollo/client';
import { Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import { MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { SEARCH_TOTAL_AMOUNT_SPENT_DETAILS } from "../../../../graphql/queries";
import { globalTableOptions, globalTableTheme } from "../../../../theme";
import Loader from "../../../../components/Loader";

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AmountSpentDetails = ({ item }) => {
    const [siteID, setSiteID] = useState(item.siteID.CODE);
    const [itemID, setItemID] = useState(item.itemID);
    const [entryFromDate, setEntryFromDate] = useState(item.fromDate.format("YYYYMMDD"));
    const [entryToDate, setEntryToDate] = useState(item.toDate.format("YYYYMMDD"));
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");

    // apollo queries and mutations
    const [getEntryList, { loading, data, error }] = useLazyQuery(SEARCH_TOTAL_AMOUNT_SPENT_DETAILS);

    // inital effect
    useEffect(() => {
        //console.log('Calling useEffect()....');
        getEntryList({
            variables: {
                siteID: siteID,
                itemID: itemID,
                fromDate: entryFromDate,
                toDate: entryToDate
            }
        });
    }, []);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    };

    if (error) {
        setSnackbarSeverity("error");
        setSnackbarMessage(error);
        setOpenSnackbar(true);
    };

    const tableColumns = [
        {
            name: "SITE_ID",
            label: "Site ID",
            options: {
                filter: false,
                sort: true,
                display: false
            }
        }, {
            name: "EXECUTION_DATE_FRMT",
            label: "Execution Date",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "SITE_NAME",
            label: "Site Name",
            options: {
                filter: true,
                sort: true,
            }
        },{
            name: "ITEM_SECTION",
            label: "Execution Type",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "ITEM_NAME",
            label: "Item",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "ITEM_QUANTITY",
            label: "Quantity",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "UOM_NAME",
            label: "UOM",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "ITEM_RATE",
            label: "Rate",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "TOTAL_AMOUNT",
            label: "Total Amount",
            options: {
                filter: false,
                sort: true,
            }
        },
    ];

    let tableData = [];
    if (data) {
        //console.log('Entries: ', data.result);
        tableData = data.result;
    }

    const tableOptions = {
        ...globalTableOptions,
        downloadOptions: {
            filename: "total-amount-spent-details.csv"
        },
        customTableBodyFooterRender: (opts) => {
            // console.log('[Arrival] customTableBodyFooterRender:>> ', opts?.data);
            return <TableFooter>
                <TableRow style={{ whiteSpace: "nowrap",}}>
                    <TableCell colSpan={3} >
                    </TableCell>
                    <TableCell
                        style={{
                            position: "sticky",
                            left: "0",
                            zIndex: 101,
                        }}
                    >
                        <Typography variant="subtitle2" >
                            TOTAL
                        </Typography>
                    </TableCell>
                    <TableCell colSpan={2}>
                        <Typography variant="subtitle2">
                            {opts?.data.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.data[5]), 0).toFixed(2)}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="subtitle2">
                            {"Avg. " + (opts?.data.reduce((accumulator, currentValue) => accumulator + parseFloat((currentValue.data[7] === null || currentValue.data[7] === 'NA') ? 0 : currentValue.data[7]), 0) / opts.data?.length).toFixed(2)}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="subtitle2">
                            {"₹ " + opts?.data.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.data[8]), 0).toFixed(2)}
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableFooter>;
        }        
    };

    return <div style={{ position: "relative" }}>
        {/* laoder */}
        {(loading) && <Loader />}

        {/* data table */}
        <MuiThemeProvider theme={globalTableTheme}>
            <MUIDataTable
                title={"Details"}
                data={tableData}
                columns={tableColumns}
                options={tableOptions}
            />
        </MuiThemeProvider>

        {/* snackbar */}
        <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleSnackbarClose}
        >
            <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
                {snackbarMessage}
            </Alert>
        </Snackbar>
    </div>
}


export default AmountSpentDetails;