import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { useMutation, useQuery } from '@apollo/client';
import { CardMedia, CircularProgress, FormControl, FormControlLabel, FormHelperText, IconButton, Paper, Switch } from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

import { POPULATE_SITE_TYPES_DDL } from "../../../../graphql/queries";
import { SITES_CRUD } from "../../../../graphql/mutations";
import { checkNull, checkMaxLength, checkNumber } from '../../../../util/validations';
import { green } from "@material-ui/core/colors";
import Loader from "../../../../components/Loader";
import { imgbb } from "../../../../util/axios";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        margin: '10px'
    },
    formControl: {
        // margin: theme.spacing(1),
        display: 'flex',
        minWidth: 120,
        margin: 0
    },
    input: {
        display: 'none',
    },
    media: {
        height: 140,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
    },
    uploadSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const validateSitesCRUDData = async (Sites) => {
    let validationObjects = {};
    for (let i = 0; i < Sites.length; i++) {
        let validationObject = {};

        if (Sites[i].transaction !== "CREATE")
            checkNull("SITE_ID", Sites[i].siteID, "Site Id is required", validationObject);

        checkNull("SITE_NAME", Sites[i].siteName, "Site Name is required", validationObject);
        checkNull("SITE_TYPE", Sites[i].siteType?.CODE, "Site Type is required", validationObject);
        checkNull("DISPLAY_ORDER", Sites[i].displayOrder, "Display Order is required", validationObject);
        checkNull("SITE_IMAGE", Sites[i].siteImage, "Site Image URI is required", validationObject);
        checkNull("IS_ACTIVE", Sites[i].IS_ACTIVE, "Please select active/inactive", validationObject);

        checkMaxLength("SITE_DESC", Sites[i].siteDesc, 500, "Length of Site Description should be less than or equal to 500 characters", validationObject);
        checkMaxLength("SITE_NAME", Sites[i].siteName, 100, "Length of Site Name should be less than or equal to 100 characters", validationObject);
        checkMaxLength("SITE_TYPE", Sites[i].siteType?.CODE, 48, "Length of Site Type should be less than or equal to 48 characters", validationObject);
        checkMaxLength("DISPLAY_ORDER", Sites[i].displayOrder, 24, "Length of Display Order should be less than or equal to 24 characters", validationObject);
        checkMaxLength("SITE_IMAGE", Sites[i].siteImage, 1000, "Length of Site Image URI should be less than or equal to 1000 characters", validationObject);
        checkMaxLength("IS_ACTIVE", Sites[i].IS_ACTIVE, 1, "Length of Active/Inactive should be equal to 1 character", validationObject);

        checkNumber("DISPLAY_ORDER", Sites[i].displayOrder, "Display Order should be a number", validationObject);

        if (Object.keys(validationObject).length !== 0)
            validationObjects[i] = validationObject;
    }
    return validationObjects;
}

const SiteForm = (props) => {
    const classes = useStyles();

    const { formDialogOpen, handleFormDialogClose, handleSitesCRUD, transaction, site } = props;
    const [siteState, setSiteState] = useState(null);
    const [siteErrors, setSiteErrors] = useState(null);
    const [saving, setSaving] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const { loading: siteTypesLoading, error: siteTypesError, data: siteTypes } = useQuery(POPULATE_SITE_TYPES_DDL);
    const [SitesCRUD] = useMutation(SITES_CRUD);

    const uploadSuccessClass = clsx({
        [classes.uploadSuccess]: uploadSuccess,
    });

    useEffect(() => {
        setSiteErrors(null);
        setUploadSuccess(false);
        if (site) {
            setSiteState({
                transaction: transaction,
                siteID: site.SITE_ID,
                siteName: site.SITE_NAME,
                siteType: siteTypes.types.filter(type => type.CODE === site.SITE_TYPE)[0] || {},
                siteDesc: site.SITE_DESC,
                siteImage: site.SITE_IMAGE,
                displayOrder: site.DISPLAY_ORDER,
                IS_ACTIVE: site.IS_ACTIVE,
            });
        }
        else {
            setSiteState({
                transaction: transaction,
                siteType: { CODE: "SITE", DESC: "SITE" },
                IS_ACTIVE: "Y",
            });
        }
    }, [props]);

    const uploadImagetoImgBB = async (imageFile) => {
        try {
            setUploading(true);
            const formData = new FormData();
            formData.append('image', imageFile);

            const result = await imgbb({
                method: "POST",
                data: formData
            });

            //console.log("ImgBB Resp: ", result);
            setSiteState({ ...siteState, siteImage: result.data.data.display_url });
            setSiteErrors({ ...siteErrors, siteImage: null });
            setUploading(false);
            setUploadSuccess(true);
        } catch (error) {
            console.log("ImgBB error:", error);
            setUploading(false);
            setUploadSuccess(false);
        };
    }

    const setSiteValidationErrors = (errors) => {
        for (let key in errors) {
            // set Error state;
            setSiteErrors({
                siteID: errors[key].errorSITE_ID,
                siteName: errors[key].errorSITE_NAME,
                siteType: errors[key].errorSITE_TYPE,
                siteImage: errors[key].errorSITE_IMAGE,
                displayOrder: errors[key].errorDISPLAY_ORDER,
                isActive: errors[key].errorIS_ACTIVE,
            });
        }
    }

    const saveSite = async () => {
        // console.log('[SiteForm] site state: ', siteState);
        setSaving(true);
        const validationErrors = await validateSitesCRUDData([siteState]);
        if (Object.keys(validationErrors).length !== 0) {
            setSiteValidationErrors(validationErrors);
            setSaving(false);
        }
        else
            SitesCRUD({
                variables: {
                    "transaction": siteState.transaction,
                    "sites": [{
                        "SITE_ID": siteState.siteID,
                        "SITE_NAME": siteState.siteName,
                        "SITE_TYPE": siteState.siteType.CODE,
                        "SITE_DESC": siteState.siteDesc,
                        "SITE_IMAGE": siteState.siteImage,
                        "DISPLAY_ORDER": siteState.displayOrder,
                        "IS_ACTIVE": siteState.IS_ACTIVE
                    }]
                }
            })
                .then(resp => {
                    //const result = resp.data.result;
                    //console.log('[SitesCRUD] Resp: ', result);
                    // setSiteState({});
                    setSaving(false);
                    handleSitesCRUD();
                })
                .catch(error => {
                    console.log('[SitesCRUD] Error:', error.message);
                    setSaving(false);
                    setSiteValidationErrors(JSON.parse(error));
                });
    }

    return <Dialog open={formDialogOpen} onClose={handleFormDialogClose} TransitionComponent={Transition}>
        <div style={{ position: "relative" }}>
            {(saving) && <Loader />}
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={handleFormDialogClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {transaction === 'CREATE' ? "Add New Site/Plant" : "Update Site/Plant"}
                    </Typography>
                    <Button autoFocus color="secondary" variant="contained" onClick={saveSite}>
                        save
                </Button>
                </Toolbar>
            </AppBar>

            <Paper variant="elevation" elevation={3} className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="siteName"
                            name="siteName"
                            label="Site/Plant Name"
                            fullWidth
                            autoComplete="site-name"
                            error={siteErrors?.siteName ? true : false}
                            helperText={siteErrors?.siteName}
                            value={siteState?.siteName}
                            onChange={(event) => {
                                setSiteState({ ...siteState, siteName: event.target.value });
                                if (event.target.value.trim().length !== 0)
                                    setSiteErrors({ ...siteErrors, siteName: null })
                                else
                                    setSiteErrors({ ...siteErrors, siteName: "Site Name is required" })
                            }}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl} error={siteErrors?.siteType ? true : false}>
                            <Autocomplete
                                style={{ marginTop: "-16px" }}
                                id="siteType"
                                autoComplete
                                fullWidth
                                loading={siteTypesLoading}
                                options={siteTypes?.types}
                                getOptionLabel={option => option.DESC ? option.DESC : ""}
                                renderInput={(params) => (
                                    <TextField required {...params} label="Site Type" margin="normal" />
                                )}
                                renderOption={(option, { inputValue }) => {
                                    const matches = match(option.DESC, inputValue);
                                    const parts = parse(option.DESC, matches);
                                    return (
                                        <div>
                                            {parts.map((part, index) => (
                                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    );
                                }}
                                value={siteState?.siteType || {}}
                                onChange={(event, newValue) => {
                                    setSiteState({ ...siteState, siteType: newValue });
                                    if (newValue)
                                        setSiteErrors({ ...siteErrors, siteType: null })
                                    else
                                        setSiteErrors({ ...siteErrors, siteType: "Site Type is required" })
                                }}
                            />
                            <FormHelperText>{siteErrors?.siteType}</FormHelperText>
                        </FormControl>
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="siteDesc"
                            name="siteDesc"
                            fullWidth
                            label="Description"
                            autoComplete="site-description"
                            value={siteState?.siteDesc}
                            onChange={(event) => setSiteState({ ...siteState, siteDesc: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="displayOrder"
                            name="displayOrder"
                            fullWidth
                            label="Display Order"
                            autoComplete="display-order"
                            error={siteErrors?.displayOrder ? true : false}
                            helperText={siteErrors?.displayOrder}
                            value={siteState?.displayOrder}
                            onChange={(event) => {
                                setSiteState({ ...siteState, displayOrder: event.target.value });
                                if (event.target.value.trim().length !== 0)
                                    setSiteErrors({ ...siteErrors, displayOrder: null })
                                else
                                    setSiteErrors({ ...siteErrors, displayOrder: "Display Order is required" })
                            }}
                        />
                    </Grid>

                    {siteState?.siteType?.CODE !== 'PLANT' &&
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel
                                label={siteState?.IS_ACTIVE === 'Y' ? "Active" : "Inactive"}
                                control={
                                    <Switch
                                        checked={siteState?.IS_ACTIVE === "Y" ? true : false}
                                        name="IS_ACTIVE"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        onChange={(event) => setSiteState({ ...siteState, IS_ACTIVE: event.target.checked ? "Y" : "N" })}
                                    />
                                }
                            />
                        </Grid>
                    }

                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="imageName"
                            name="ImageName"
                            label="Image"
                            required
                            fullWidth
                            disabled
                            value={siteState?.siteImage}
                            error={siteErrors?.siteImage ? true : false}
                            helperText={siteErrors?.siteImage}
                            InputLabelProps={{ shrink: siteState?.siteImage && true }}
                        />
                        <input
                            accept="image/*"
                            id="siteImage"
                            type="file"
                            className={classes.input}
                            onChange={(event) => {
                                setUploadSuccess(false);
                                uploadImagetoImgBB(event.target.files[0]);
                            }}
                        />

                        <label htmlFor="siteImage">
                            <div className={classes.wrapper}>
                                <Fab variant="extended" component="span" size="medium" className={uploadSuccessClass}>
                                    {uploadSuccess ? <CheckIcon className={classes.extendedIcon} /> : <PhotoCamera className={classes.extendedIcon} />}
                                    {uploadSuccess ? "Success" : "Browse"}
                                </Fab>
                                {uploading && <CircularProgress size={44} className={classes.fabProgress} />}
                            </div>
                        </label>
                    </Grid>

                    {siteState?.siteImage && <Grid item xs={12} sm={6}>
                        <CardMedia
                            className={classes.media}
                            image={siteState?.siteImage}
                            title="Site Image"
                        />
                    </Grid>
                    }

                </Grid>
            </Paper>
        </div>
    </Dialog>
}


export default SiteForm;