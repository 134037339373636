import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CategoryIcon from '@material-ui/icons/Category';
import CommuteIcon from '@material-ui/icons/Commute';
import BuildIcon from '@material-ui/icons/Build';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import DomainDisabledIcon from '@material-ui/icons/DomainDisabled';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import SendIcon from '@material-ui/icons/Send';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import LayersIcon from '@material-ui/icons/Layers';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import { Tooltip } from '@material-ui/core';
import { NavLink } from "react-router-dom";
import { orange } from '@material-ui/core/colors';
import StyledBadge from '../../../components/StyledBadge';

const transformNotifications = data => data?.reduce((accumulator, currentValue) => {
  return { ...accumulator, [currentValue.ENTRY_TYPE]: currentValue.TOTAL_NOTIFICATIONS }
}, []);

export const mainMenu = (match, data) => {
  const notifications = transformNotifications(data?.result);

  return (
    <div>
      <NavLink
        to={`${match.url}/dashboard`}
        style={{ color: "inherit", textDecoration: "none" }}
        activeStyle={{
          color: orange[700]
        }}
      >
        <ListItem button >
          <ListItemIcon>
            <Tooltip title="Dashboard">
              <DashboardIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </NavLink>

      <NavLink
        to={`${match.url}/material-arrivals`}
        style={{ color: "inherit", textDecoration: "none" }}
        activeStyle={{
          color: orange[700],
          backgroundColor: "chartreuse"
        }}
      >
        <ListItem button>
          <ListItemIcon>
            <Tooltip title="Material Arrival">
              <StyledBadge overlap="circle" badgeContent={notifications?.MAT_ARV}>
                <ShoppingCartIcon />
              </StyledBadge>
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Material Arrival" />
        </ListItem>
      </NavLink>

      <NavLink
        to={`${match.url}/material-dispatches`}
        style={{ color: "inherit", textDecoration: "none" }}
        activeStyle={{
          color: orange[700]
        }}
      >
        <ListItem button>
          <ListItemIcon>
            <Tooltip title="Material Dispatch">
              <StyledBadge overlap="circle" badgeContent={notifications?.MAT_DSP}>
                <SendIcon />
              </StyledBadge>
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Material Dispatch" />
        </ListItem>
      </NavLink>

      <NavLink
        to={`${match.url}/material-usages`}
        style={{ color: "inherit", textDecoration: "none" }}
        activeStyle={{
          color: orange[700]
        }}
      >
        <ListItem button>
          <ListItemIcon>
            <Tooltip title="Material Usages">
              <StyledBadge overlap="circle" badgeContent={notifications?.MAT_USG}>
                <DonutSmallIcon />
              </StyledBadge>
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Material Usages" />
        </ListItem>
      </NavLink>

      <NavLink
        to={`${match.url}/fuel`}
        style={{ color: "inherit", textDecoration: "none" }}
        activeStyle={{
          color: orange[700]
        }}
      >
        <ListItem button>
          <ListItemIcon>
            <Tooltip title="Fuel">
              <StyledBadge overlap="circle" badgeContent={notifications?.FUEL}>
                <LocalGasStationIcon />
              </StyledBadge>
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Fuel" />
        </ListItem>
      </NavLink>
    </div>
  )
};

export const expensesMenu = (match, data) => {
  const notifications = transformNotifications(data?.result);

  return (
    <div>
      <ListSubheader inset>Expenses</ListSubheader>
      <NavLink
        to={`${match.url}/labour`}
        style={{ color: "inherit", textDecoration: "none" }}
        activeStyle={{
          color: orange[700]
        }}
      >
        <ListItem button>
          <ListItemIcon>
            <Tooltip title="Labour">
              <StyledBadge overlap="circle" badgeContent={notifications?.LABOUR}>
                <GroupWorkIcon />
              </StyledBadge>
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Labour" />
        </ListItem>
      </NavLink>

      <NavLink
        to={`${match.url}/maintenance`}
        style={{ color: "inherit", textDecoration: "none" }}
        activeStyle={{
          color: orange[700]
        }}
      >
        <ListItem button>
          <ListItemIcon>
            <Tooltip title="Maintenance">
              <StyledBadge overlap="circle" badgeContent={notifications?.MAINT}>
                <BuildIcon />
              </StyledBadge>
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Maintenance" />
        </ListItem>
      </NavLink>

      <NavLink
        to={`${match.url}/expenses`}
        style={{ color: "inherit", textDecoration: "none" }}
        activeStyle={{
          color: orange[700]
        }}
      >
        <ListItem button>
          <ListItemIcon>
            <Tooltip title="Other Expenses">
              <StyledBadge overlap="circle" badgeContent={notifications?.OTH_EXP}>
                <ExitToAppIcon />
              </StyledBadge>
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Other Expenses" />
        </ListItem>
      </NavLink>
      
      <NavLink
        to={`${match.url}/accounting`}
        style={{ color: "inherit", textDecoration: "none" }}
        activeStyle={{
          color: orange[700]
        }}
      >
        <ListItem button>
          <ListItemIcon>
            <Tooltip title="Accounting">
              <MenuBookIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Accounting" />
        </ListItem>
      </NavLink>
    </div >
  )
};

export const employeeMenu = (match) => (
  <div>
    <ListSubheader inset>Employees</ListSubheader>
    <NavLink
      to={`${match.url}/employees`}
      style={{ color: "inherit", textDecoration: "none" }}
      activeStyle={{
        color: orange[700]
      }}
    >
      <ListItem button>
        <ListItemIcon>
          <Tooltip title="Employees">
            <PeopleAltIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Employees" />
      </ListItem>
    </NavLink>
  </div>
);

export const analysisMenu = (match) => (
  <div>
    <ListSubheader inset>Analysis</ListSubheader>
    <NavLink
      to={`${match.url}/analysis`}
      style={{ color: "inherit", textDecoration: "none" }}
      activeStyle={{
        color: orange[700]
      }}
    >
      <ListItem button>
        <ListItemIcon>
          <Tooltip title="Analysis">
            <AssessmentIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Analysis" />
      </ListItem>
    </NavLink>
  </div>
);

export const mastersMenu = (match) => (
  <div>
    <ListSubheader inset>Masters</ListSubheader>
    <NavLink
      to={`${match.url}/sites`}
      style={{ color: "inherit", textDecoration: "none" }}
      activeStyle={{
        color: orange[700]
      }}
    >
      <ListItem button>
        <ListItemIcon>
          <Tooltip title="Sites/Plants">
            <DomainDisabledIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Sites/Plants" />
      </ListItem>
    </NavLink>
    <NavLink
      to={`${match.url}/parties`}
      style={{ color: "inherit", textDecoration: "none" }}
      activeStyle={{
        color: orange[700]
      }}
    >
      <ListItem button>
        <ListItemIcon>
          <Tooltip title="Parties">
            <ContactPhoneIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Parties" />
      </ListItem>
    </NavLink>
    <NavLink
      to={`${match.url}/materials`}
      style={{ color: "inherit", textDecoration: "none" }}
      activeStyle={{
        color: orange[700]
      }}
    >
      <ListItem button>
        <ListItemIcon>
          <Tooltip title="Materials">
            <CategoryIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Materials" />
      </ListItem>
    </NavLink>
    <NavLink
      to={`${match.url}/machinery`}
      style={{ color: "inherit", textDecoration: "none" }}
      activeStyle={{
        color: orange[700]
      }}
    >
      <ListItem button>
        <ListItemIcon>
          <Tooltip title="Machinery">
            <CommuteIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Machinery" />
      </ListItem>
    </NavLink>
    <NavLink
      to={`${match.url}/usage-types`}
      style={{ color: "inherit", textDecoration: "none" }}
      activeStyle={{
        color: orange[700]
      }}
    >
      <ListItem button>
        <ListItemIcon>
          <Tooltip title="Material Usage Types">
            <DynamicFeedIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Material Usage Types" />
      </ListItem>
    </NavLink>
    <NavLink
      to={`${match.url}/uom`}
      style={{ color: "inherit", textDecoration: "none" }}
      activeStyle={{
        color: orange[700]
      }}
    >
      <ListItem button>
        <ListItemIcon>
          <Tooltip title="Units of Measurement">
            <LayersIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Units of Measurement" />
      </ListItem>
    </NavLink>

    <NavLink
      to={`${match.url}/app-users`}
      style={{ color: "inherit", textDecoration: "none" }}
      activeStyle={{
        color: orange[700]
      }}
    >
      <ListItem button>
        <ListItemIcon>
          <Tooltip title="Application Users">
            <VerifiedUserIcon />
          </Tooltip>
        </ListItemIcon>
        <ListItemText primary="Application Users" />
      </ListItem>
    </NavLink>
  </div>
);