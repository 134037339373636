import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import { useMutation, useQuery } from '@apollo/client';
import { CardMedia, CircularProgress, FormControl, FormControlLabel, FormHelperText, GridList, GridListTile, GridListTileBar, IconButton, Paper, Switch } from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CheckIcon from '@material-ui/icons/Check';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { green } from "@material-ui/core/colors";
import MomentUtils from '@date-io/moment';
import moment from "moment";

import { POPULATE_SITES_DDL } from "../../../../graphql/queries";
import { EMPLOYEES_CRUD } from "../../../../graphql/mutations";
import { checkNull, checkMaxLength, checkDate, checkEmail, checkNumber, checkMobileNumber } from '../../../../util/validations';
import Loader from "../../../../components/Loader";
import { imgbb } from "../../../../util/axios";
import { employmentTypes, maritalStatus, gender } from "../../../../util/data";
import ImgbbUpload from "../../../../components/ImgbbUpload";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        margin: '10px'
    },
    formControl: {
        // margin: theme.spacing(1),
        display: 'flex',
        minWidth: 120,
        margin: 0
    },
    input: {
        display: 'none',
    },
    button: {
        margin: theme.spacing(1),
    },
    media: {
        height: 140,
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
    },
    uploadSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },

    documents: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    docTitle: {
        // color: theme.palette.primary.light,
        color: "white",
    },
    docTitleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },

}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validateEmployeesCRUDData = async (Employees) => {
    let validationObjects = {};
    for (let i = 0; i < Employees.length; i++) {
        let validationObject = {};

        if (Employees[i].transaction !== "CREATE")
            checkNull("EMPLOYEE_ID", Employees[i].EMPLOYEE_ID, "Employee Id is required", validationObject);

        checkNull("FIRST_NAME", Employees[i].FIRST_NAME, "First Name is required", validationObject);
        checkNull("LAST_NAME", Employees[i].LAST_NAME, "Last Name is required", validationObject);
        checkNull("HIRE_DATE", Employees[i].HIRE_DATE.format("YYYYMMDD"), "Hire Date is required", validationObject);
        checkNull("EMPLOYEE_STATUS", Employees[i].EMPLOYEE_STATUS, "Please select Employee Status", validationObject);
        checkNull("EMPLOYMENT_TYPE", Employees[i].EMPLOYMENT_TYPE?.CODE, "Please select Employment Type", validationObject);
        checkNull("SALARY", Employees[i].SALARY, "Salary is required", validationObject);
        checkNull("EMAIL", Employees[i].EMAIL, "Email is required", validationObject);
        checkNull("MOBILE_NUMBER", Employees[i].MOBILE_NUMBER, "Mobile Number is required", validationObject);
        checkNull("BIRTH_DATE", Employees[i].BIRTH_DATE.format("YYYYMMDD"), "Birth Date is required", validationObject);
        checkNull("GENDER", Employees[i].GENDER?.CODE, "Please select Gender", validationObject);
        checkNull("PHOTO_URL", Employees[i].PHOTO_URL, "Photo URL is required", validationObject);

        checkMaxLength("EMPLOYEE_ID", Employees[i].EMPLOYEE_ID, 48, "Length of Employee Id should be less than or equal to 48 characters", validationObject);
        checkMaxLength("FIRST_NAME", Employees[i].FIRST_NAME, 100, "Length of First Name should be less than or equal to 100 characters", validationObject);
        checkMaxLength("LAST_NAME", Employees[i].LAST_NAME, 100, "Length of Last Name should be less than or equal to 100 characters", validationObject);
        checkMaxLength("HIRE_DATE", Employees[i].HIRE_DATE.format("YYYYMMDD"), 8, "Length of Hire Date should be equal to 8 characters", validationObject);
        checkMaxLength("EMPLOYEE_STATUS", Employees[i].EMPLOYEE_STATUS, 12, "Length of Employee Status should be less than or equal to 12 characters", validationObject);
        checkMaxLength("EMPLOYMENT_TYPE", Employees[i].EMPLOYMENT_TYPE?.CODE, 24, "Length of Employment Type should be less than or equal to 24 characters", validationObject);
        checkMaxLength("EMPLOYEE_ROLE", Employees[i].EMPLOYEE_ROLE, 36, "Length of Employee Role should be less than or equal to 36 characters", validationObject);
        checkMaxLength("SEATING_LOCATION", Employees[i].SEATING_LOCATION?.CODE, 36, "Length of Sitting Location should be less than or equal to 36 characters", validationObject);
        checkMaxLength("EXPERIENCE_IN_YEARS", Employees[i].EXPERIENCE_IN_YEARS, 4, "Length of Years of Experience should be less than or equal to 4 characters", validationObject);
        checkMaxLength("SALARY", Employees[i].SALARY, 12, "Length of Salary should be less than or equal to 12 characters", validationObject);
        checkMaxLength("EMAIL", Employees[i].EMAIL, 200, "Length of Email should be less than or equal to 200 characters", validationObject);
        checkMaxLength("MOBILE_NUMBER", Employees[i].MOBILE_NUMBER, 15, "Length of Mobile Number should be less than or equal to 15 characters", validationObject);
        checkMaxLength("BIRTH_DATE", Employees[i].BIRTH_DATE.format("YYYYMMDD"), 8, "Length of Birth Date should be equal to 8 characters", validationObject);
        checkMaxLength("MARITAL_STATUS", Employees[i].MARITAL_STATUS?.CODE, 12, "Length of Marrital Status should be less than or equal to 12 characters", validationObject);
        checkMaxLength("PERMANENT_ADDRESS", Employees[i].PERMANENT_ADDRESS, 500, "Length of Permanent Address should be less than or equal to 500 characters", validationObject);
        checkMaxLength("CORRESPONDANCE_ADDRESS", Employees[i].CORRESPONDANCE_ADDRESS, 500, "Length of Correspondance Address should be less than or equal to 500 characters", validationObject);
        checkMaxLength("GENDER", Employees[i].GENDER?.CODE, 12, "Length of Gender should be less than or equal to 12 characters", validationObject);
        checkMaxLength("ENTITLED_LEAVES", Employees[i].ENTITLED_LEAVES, 12, "Length of Entitled Leaves should be less than or equal to 12 characters", validationObject);
        checkMaxLength("PHOTO_URL", Employees[i].PHOTO_URL, 200, "Length of Photo URL should be less than or equal to 200 characters", validationObject);

        Employees[i].DOCUMENTS = JSON.stringify(Employees[i].DOCUMENTS);
        checkMaxLength("DOCUMENTS", Employees[i].DOCUMENTS, 1000, "Length of Documents should be less than or equal to 1000 characters", validationObject);

        checkNumber("EXPERIENCE_IN_YEARS", Employees[i].EXPERIENCE_IN_YEARS, "Years of Experience should be a number", validationObject);
        checkNumber("SALARY", Employees[i].SALARY, "Salary should be a number", validationObject);
        checkNumber("ENTITLED_LEAVES", Employees[i].ENTITLED_LEAVES, "Entitled Leaves should be a number", validationObject);

        checkEmail("EMAIL", Employees[i].EMAIL, "Email is not valid", validationObject);
        checkMobileNumber("MOBILE_NUMBER", Employees[i].MOBILE_NUMBER, "Mobile number is not valid", validationObject);

        checkDate('HIRE_DATE', Employees[i].HIRE_DATE.format("YYYYMMDD"), 'YYYYMMDD', 'Invalid Hire Date', validationObject);
        checkDate('BIRTH_DATE', Employees[i].BIRTH_DATE.format("YYYYMMDD"), 'YYYYMMDD', 'Invalid Birth Date', validationObject);

        if (Employees[i].EMPLOYEE_STATUS !== 'Active') {
            checkMaxLength("RELIEVING_DATE", Employees[i].RELIEVING_DATE.format("YYYYMMDD"), 8, "Length of Relieving Date should be equal to 8 characters", validationObject);
            checkDate('RELIEVING_DATE', Employees[i].RELIEVING_DATE.format("YYYYMMDD"), 'YYYYMMDD', 'Invalid Relieving Date', validationObject);
        }

        if (Object.keys(validationObject).length !== 0)
            validationObjects[i] = validationObject;
    }
    return validationObjects;
}

const EmployeeForm = (props) => {
    const classes = useStyles();

    const { formDialogOpen, handleFormDialogClose, handleEmployeesCRUD, transaction, employee } = props;
    const [employeeState, setEmployeeState] = useState(null);
    const [employeeErrors, setEmployeeErrors] = useState(null);
    const [saving, setSaving] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [openDocumentsDialog, setOpenDocumentsDialog] = useState(false);

    const { loading: sitesLoading, error: sitesError, data: sites } = useQuery(POPULATE_SITES_DDL, { variables: { siteType: "%" } });
    const [EmployeesCRUD] = useMutation(EMPLOYEES_CRUD);

    const uploadSuccessClass = clsx({
        [classes.uploadSuccess]: uploadSuccess,
    });

    useEffect(() => {
        setUploadSuccess(false);
        setEmployeeErrors(null);
        if (employee) {
            setEmployeeState({
                transaction: transaction,
                EMPLOYEE_ID: employee.EMPLOYEE_ID,
                FIRST_NAME: employee.FIRST_NAME,
                LAST_NAME: employee.LAST_NAME,
                HIRE_DATE: moment(employee.HIRE_DATE, "YYYYMMDD"),
                EMPLOYEE_STATUS: employee.EMPLOYEE_STATUS,
                EMPLOYMENT_TYPE: employmentTypes.filter(type => type.CODE === employee.EMPLOYMENT_TYPE)[0] || {},
                EMPLOYEE_ROLE: employee.EMPLOYEE_ROLE,
                SEATING_LOCATION: sites?.sites.filter(type => type.CODE === employee.SEATING_LOCATION)[0] || {},
                EXPERIENCE_IN_YEARS: employee.EXPERIENCE_IN_YEARS,
                SALARY: employee.SALARY,
                EMAIL: employee.EMAIL,
                MOBILE_NUMBER: employee.MOBILE_NUMBER,
                BIRTH_DATE: moment(employee.BIRTH_DATE, "YYYYMMDD"),
                MARITAL_STATUS: maritalStatus.filter(status => status.CODE === employee.MARITAL_STATUS)[0] || {},
                PERMANENT_ADDRESS: employee.PERMANENT_ADDRESS,
                CORRESPONDANCE_ADDRESS: employee.CORRESPONDANCE_ADDRESS,
                GENDER: gender.filter(gend => gend.CODE === employee.GENDER)[0] || {},
                ENTITLED_LEAVES: employee.ENTITLED_LEAVES,
                RELIEVING_DATE: moment(employee.RELIEVING_DATE, "YYYYMMDD"),
                PHOTO_URL: employee.PHOTO_URL,
                DOCUMENTS: JSON.parse(employee.DOCUMENTS),
            });
        }
        else {
            setEmployeeState({
                transaction: transaction,
                HIRE_DATE: moment(),
                BIRTH_DATE: moment(),
                EMPLOYEE_STATUS: "Active",
                DOCUMENTS: [],
            });
        }
    }, [props]);

    const uploadImagetoImgBB = async (imageFile) => {
        try {
            setUploading(true);

            const formData = new FormData();
            formData.append('image', imageFile);

            const result = await imgbb({
                method: "POST",
                data: formData
            });

            //console.log("ImgBB Resp: ", result);
            setEmployeeState({ ...employeeState, PHOTO_URL: result.data.data.display_url });
            setUploading(false);
            setUploadSuccess(true);
        } catch (error) {
            console.log("ImgBB error:", error);
            setUploading(false);
            setUploadSuccess(false);
        };
    }

    const setEmployeeValidationErrors = (errors) => {
        for (let key in errors) {
            // set Error state;
            setEmployeeErrors({
                EMPLOYEE_ID: errors[key].errorEMPLOYEE_ID,
                FIRST_NAME: errors[key].errorFIRST_NAME,
                LAST_NAME: errors[key].errorLAST_NAME,
                HIRE_DATE: errors[key].errorHIRE_DATE,
                EMPLOYEE_STATUS: errors[key].errorEMPLOYEE_STATUS,
                EMPLOYMENT_TYPE: errors[key].errorEMPLOYMENT_TYPE,
                EMPLOYEE_ROLE: errors[key].errorEMPLOYEE_ROLE,
                SEATING_LOCATION: errors[key].errorSEATING_LOCATION,
                EXPERIENCE_IN_YEARS: errors[key].errorEXPERIENCE_IN_YEARS,
                SALARY: errors[key].errorSALARY,
                EMAIL: errors[key].errorEMAIL,
                MOBILE_NUMBER: errors[key].errorMOBILE_NUMBER,
                BIRTH_DATE: errors[key].errorBIRTH_DATE,
                MARITAL_STATUS: errors[key].errorMARITAL_STATUS,
                PERMANENT_ADDRESS: errors[key].errorPERMANENT_ADDRESS,
                CORRESPONDANCE_ADDRESS: errors[key].errorCORRESPONDANCE_ADDRESS,
                GENDER: errors[key].errorGENDER,
                ENTITLED_LEAVES: errors[key].errorENTITLED_LEAVES,
                RELIEVING_DATE: errors[key].errorRELIEVING_DATE,
                PHOTO_URL: errors[key].errorPHOTO_URL,
                DOCUMENTS: errors[key].errorDOCUMENTS,
            });
        }
    }

    const saveEmployee = async () => {
        // console.log('[EmployeeForm] employee state: ', employeeState);
        setSaving(true);
        const checkEmployeeState = { ...employeeState };
        const validationErrors = await validateEmployeesCRUDData([checkEmployeeState]);
        if (Object.keys(validationErrors).length !== 0) {
            setEmployeeValidationErrors(validationErrors);
            setSaving(false);
        }
        else
            EmployeesCRUD({
                variables: {
                    "transaction": employeeState.transaction,
                    "employees": [{
                        "EMPLOYEE_ID": employeeState.EMPLOYEE_ID,
                        "FIRST_NAME": employeeState.FIRST_NAME,
                        "LAST_NAME": employeeState.LAST_NAME,
                        "HIRE_DATE": employeeState.HIRE_DATE.format("YYYYMMDD"),
                        "EMPLOYEE_STATUS": employeeState.EMPLOYEE_STATUS,
                        "EMPLOYMENT_TYPE": employeeState.EMPLOYMENT_TYPE.CODE,
                        "EMPLOYEE_ROLE": employeeState.EMPLOYEE_ROLE,
                        "SEATING_LOCATION": employeeState.SEATING_LOCATION.CODE,
                        "EXPERIENCE_IN_YEARS": employeeState.EXPERIENCE_IN_YEARS,
                        "SALARY": employeeState.SALARY,
                        "EMAIL": employeeState.EMAIL,
                        "MOBILE_NUMBER": employeeState.MOBILE_NUMBER,
                        "BIRTH_DATE": employeeState.BIRTH_DATE.format("YYYYMMDD"),
                        "MARITAL_STATUS": employeeState.MARITAL_STATUS.CODE,
                        "PERMANENT_ADDRESS": employeeState.PERMANENT_ADDRESS,
                        "CORRESPONDANCE_ADDRESS": employeeState.CORRESPONDANCE_ADDRESS,
                        "GENDER": employeeState.GENDER.CODE,
                        "ENTITLED_LEAVES": employeeState.ENTITLED_LEAVES,
                        "RELIEVING_DATE": employeeState.EMPLOYEE_STATUS === 'Active' ? "" : employeeState.RELIEVING_DATE.format("YYYYMMDD"),
                        "PHOTO_URL": employeeState.PHOTO_URL,
                        "DOCUMENTS": employeeState.DOCUMENTS
                    }]
                }
            })
                .then(resp => {
                    //const result = resp.data.result;
                    //console.log('[EmployeesCRUD] Resp: ', result);
                    // setEmployeeState({});
                    setSaving(false);
                    handleEmployeesCRUD();
                })
                .catch(error => {
                    console.log('[EmployeesCRUD] Error:', error.message);
                    setSaving(false);
                    setEmployeeErrors({
                        EMPLOYEE_ID: error.message.split(":")[1],
                    });
                });
    }

    const handleCloseDocumentsDialog = () => {
        setOpenDocumentsDialog(false);
    };

    const uploadDocuments = (documents) => {
        setOpenDocumentsDialog(false);
        setEmployeeState({ ...employeeState, DOCUMENTS: documents });
    }

    return <div>
        <Dialog fullScreen open={formDialogOpen} onClose={handleFormDialogClose} TransitionComponent={Transition}>
            <div style={{ position: "relative" }}>
                {(saving) && <Loader />}
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleFormDialogClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {transaction === 'CREATE' ? "Add New Employee" : "Update Employee"}
                        </Typography>
                        <Button autoFocus color="secondary" variant="contained" onClick={saveEmployee}>
                            save
                </Button>
                    </Toolbar>
                </AppBar>

                <Paper variant="elevation" elevation={3} className={classes.paper}>
                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="FIRST_NAME"
                                name="FIRST_NAME"
                                label="First name"
                                fullWidth
                                autoComplete="first-name"
                                error={employeeErrors?.FIRST_NAME ? true : false}
                                helperText={employeeErrors?.FIRST_NAME}
                                value={employeeState?.FIRST_NAME}
                                onChange={(event) => {
                                    setEmployeeState({ ...employeeState, FIRST_NAME: event.target.value });
                                    if (event.target.value.trim().length !== 0)
                                        setEmployeeErrors({ ...employeeErrors, FIRST_NAME: null })
                                    else
                                        setEmployeeErrors({ ...employeeErrors, FIRST_NAME: "First Name is required" })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="LAST_NAME"
                                name="LAST_NAME"
                                label="Last name"
                                fullWidth
                                autoComplete="last-name"
                                error={employeeErrors?.LAST_NAME ? true : false}
                                helperText={employeeErrors?.LAST_NAME}
                                value={employeeState?.LAST_NAME}
                                onChange={(event) => {
                                    setEmployeeState({ ...employeeState, LAST_NAME: event.target.value });
                                    if (event.target.value.trim().length !== 0)
                                        setEmployeeErrors({ ...employeeErrors, LAST_NAME: null })
                                    else
                                        setEmployeeErrors({ ...employeeErrors, LAST_NAME: "Last name is required" })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    required
                                    fullWidth
                                    id="hire-date"
                                    label="Hire date"
                                    format="DD/MM/yyyy"
                                    value={employeeState?.HIRE_DATE}
                                    error={employeeErrors?.HIRE_DATE ? true : false}
                                    helperText={employeeErrors?.HIRE_DATE}
                                    onChange={date => {
                                        setEmployeeState({ ...employeeState, HIRE_DATE: date });
                                        if (date)
                                            setEmployeeErrors({ ...employeeErrors, HIRE_DATE: null })
                                        else
                                            setEmployeeErrors({ ...employeeErrors, HIRE_DATE: "Hire date is required" })
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    required
                                    fullWidth
                                    id="birth-date"
                                    label="Birth date"
                                    format="DD/MM/yyyy"
                                    value={employeeState?.BIRTH_DATE}
                                    error={employeeErrors?.BIRTH_DATE ? true : false}
                                    helperText={employeeErrors?.BIRTH_DATE}
                                    onChange={date => {
                                        setEmployeeState({ ...employeeState, BIRTH_DATE: date });
                                        if (date)
                                            setEmployeeErrors({ ...employeeErrors, BIRTH_DATE: null })
                                        else
                                            setEmployeeErrors({ ...employeeErrors, BIRTH_DATE: "Birth date is required" })
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl className={classes.formControl} error={employeeErrors?.EMPLOYMENT_TYPE ? true : false}>
                                <Autocomplete
                                    style={{ marginTop: "-16px" }}
                                    id="EMPLOYMENT_TYPE"
                                    autoComplete
                                    fullWidth
                                    options={employmentTypes}
                                    getOptionLabel={option => option.DESC ? option.DESC : ""}
                                    renderInput={(params) => (
                                        <TextField required {...params} label="Employment type" margin="normal" />
                                    )}
                                    renderOption={(option, { inputValue }) => {
                                        const matches = match(option.DESC, inputValue);
                                        const parts = parse(option.DESC, matches);
                                        return (
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        );
                                    }}
                                    value={employeeState?.EMPLOYMENT_TYPE || {}}
                                    onChange={(event, newValue) => {
                                        setEmployeeState({ ...employeeState, EMPLOYMENT_TYPE: newValue });
                                        if (newValue)
                                            setEmployeeErrors({ ...employeeErrors, EMPLOYMENT_TYPE: null })
                                        else
                                            setEmployeeErrors({ ...employeeErrors, EMPLOYMENT_TYPE: "Please select employment type" })
                                    }}
                                />
                                <FormHelperText>{employeeErrors?.EMPLOYMENT_TYPE}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl className={classes.formControl} error={employeeErrors?.SEATING_LOCATION ? true : false}>
                                <Autocomplete
                                    style={{ marginTop: "-16px" }}
                                    id="SEATING_LOCATION"
                                    autoComplete
                                    fullWidth
                                    loading={sitesLoading}
                                    options={sites?.sites}
                                    getOptionLabel={option => option.DESC ? option.DESC : ""}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Seating location" margin="normal" />
                                    )}
                                    renderOption={(option, { inputValue }) => {
                                        const matches = match(option.DESC, inputValue);
                                        const parts = parse(option.DESC, matches);
                                        return (
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        );
                                    }}
                                    value={employeeState?.SEATING_LOCATION || {}}
                                    onChange={(event, newValue) => {
                                        setEmployeeState({ ...employeeState, SEATING_LOCATION: newValue });
                                        if (newValue)
                                            setEmployeeErrors({ ...employeeErrors, SEATING_LOCATION: null })
                                        else
                                            setEmployeeErrors({ ...employeeErrors, SEATING_LOCATION: "Please select seating location" })
                                    }}
                                />
                                <FormHelperText>{employeeErrors?.SEATING_LOCATION}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl className={classes.formControl} error={employeeErrors?.MARITAL_STATUS ? true : false}>
                                <Autocomplete
                                    style={{ marginTop: "-16px" }}
                                    id="MARITAL_STATUS"
                                    autoComplete
                                    fullWidth
                                    options={maritalStatus}
                                    getOptionLabel={option => option.DESC ? option.DESC : ""}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Marital status" margin="normal" />
                                    )}
                                    renderOption={(option, { inputValue }) => {
                                        const matches = match(option.DESC, inputValue);
                                        const parts = parse(option.DESC, matches);
                                        return (
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        );
                                    }}
                                    value={employeeState?.MARITAL_STATUS || {}}
                                    onChange={(event, newValue) => setEmployeeState({ ...employeeState, MARITAL_STATUS: newValue })}
                                />
                                <FormHelperText>{employeeErrors?.MARITAL_STATUS}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControl className={classes.formControl} error={employeeErrors?.GENDER ? true : false}>
                                <Autocomplete
                                    style={{ marginTop: "-16px" }}
                                    id="GENDER"
                                    autoComplete
                                    fullWidth
                                    options={gender}
                                    getOptionLabel={option => option.DESC ? option.DESC : ""}
                                    renderInput={(params) => (
                                        <TextField required {...params} label="Gender" margin="normal" />
                                    )}
                                    renderOption={(option, { inputValue }) => {
                                        const matches = match(option.DESC, inputValue);
                                        const parts = parse(option.DESC, matches);
                                        return (
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        );
                                    }}
                                    value={employeeState?.GENDER || {}}
                                    onChange={(event, newValue) => {
                                        setEmployeeState({ ...employeeState, GENDER: newValue });
                                        if (newValue)
                                            setEmployeeErrors({ ...employeeErrors, GENDER: null })
                                        else
                                            setEmployeeErrors({ ...employeeErrors, GENDER: "Please select gender" })
                                    }}
                                />
                                <FormHelperText>{employeeErrors?.GENDER}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="SALARY"
                                name="SALARY"
                                label="Salary"
                                fullWidth
                                autoComplete="salary"
                                error={employeeErrors?.SALARY ? true : false}
                                helperText={employeeErrors?.SALARY}
                                value={employeeState?.SALARY}
                                onChange={(event) => {
                                    setEmployeeState({ ...employeeState, SALARY: event.target.value });
                                    if (event.target.value.trim().length !== 0)
                                        setEmployeeErrors({ ...employeeErrors, SALARY: null })
                                    else
                                        setEmployeeErrors({ ...employeeErrors, SALARY: "Salary is required" })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="EMAIL"
                                name="EMAIL"
                                label="Email"
                                fullWidth
                                autoComplete="email"
                                error={employeeErrors?.EMAIL ? true : false}
                                helperText={employeeErrors?.EMAIL}
                                value={employeeState?.EMAIL}
                                onChange={(event) => {
                                    setEmployeeState({ ...employeeState, EMAIL: event.target.value });
                                    if (event.target.value.trim().length !== 0)
                                        setEmployeeErrors({ ...employeeErrors, EMAIL: null })
                                    else
                                        setEmployeeErrors({ ...employeeErrors, EMAIL: "Email is required" })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField
                                required
                                id="MOBILE_NUMBER"
                                name="MOBILE_NUMBER"
                                label="Mobile number"
                                fullWidth
                                autoComplete="mobile-number"
                                error={employeeErrors?.MOBILE_NUMBER ? true : false}
                                helperText={employeeErrors?.MOBILE_NUMBER}
                                value={employeeState?.MOBILE_NUMBER}
                                onChange={(event) => {
                                    setEmployeeState({ ...employeeState, MOBILE_NUMBER: event.target.value });
                                    if (event.target.value.trim().length !== 0)
                                        setEmployeeErrors({ ...employeeErrors, MOBILE_NUMBER: null })
                                    else
                                        setEmployeeErrors({ ...employeeErrors, MOBILE_NUMBER: "Mobile number is required" })
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField
                                id="ENTITLED_LEAVES"
                                name="ENTITLED_LEAVES"
                                fullWidth
                                label="Entitled Leaves"
                                autoComplete="entitled-leaves"
                                value={employeeState?.ENTITLED_LEAVES}
                                onChange={(event) => setEmployeeState({ ...employeeState, ENTITLED_LEAVES: event.target.value })}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField
                                id="EMPLOYEE_ROLE"
                                name="EMPLOYEE_ROLE"
                                fullWidth
                                label="Employee Role"
                                autoComplete="employee-role"
                                value={employeeState?.EMPLOYEE_ROLE}
                                onChange={(event) => setEmployeeState({ ...employeeState, EMPLOYEE_ROLE: event.target.value })}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <TextField
                                id="EXPERIENCE_IN_YEARS"
                                name="EXPERIENCE_IN_YEARS"
                                fullWidth
                                label="Experience(in years)"
                                autoComplete="employee-experience"
                                value={employeeState?.EXPERIENCE_IN_YEARS}
                                onChange={(event) => setEmployeeState({ ...employeeState, EXPERIENCE_IN_YEARS: event.target.value })}
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <FormControlLabel
                                label={employeeState?.EMPLOYEE_STATUS === 'Active' ? 'Active' : 'Inactive'}
                                control={
                                    <Switch
                                        checked={employeeState?.EMPLOYEE_STATUS === "Active" ? true : false}
                                        name="EMPLOYEE_STATUS"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        onChange={(event) => setEmployeeState({ ...employeeState, EMPLOYEE_STATUS: event.target.checked ? "Active" : "Inactive" })}
                                    />
                                }
                            />
                        </Grid>

                        {employeeState?.EMPLOYEE_STATUS !== 'Active' &&
                            <Grid item xs={12} md={3}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        id="relieving-date"
                                        label="Relieving date"
                                        format="DD/MM/yyyy"
                                        value={employeeState?.RELIEVING_DATE}
                                        error={employeeErrors?.RELIEVING_DATE ? true : false}
                                        helperText={employeeErrors?.RELIEVING_DATE}
                                        onChange={date => setEmployeeState({ ...employeeState, RELIEVING_DATE: date })}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        }

                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="PERMANENT_ADDRESS"
                                name="PERMANENT_ADDRESS"
                                fullWidth
                                label="Permanent Address"
                                autoComplete="permanent-address"
                                value={employeeState?.PERMANENT_ADDRESS}
                                onChange={(event) => setEmployeeState({ ...employeeState, PERMANENT_ADDRESS: event.target.value })}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                id="CORRESPONDANCE_ADDRESS"
                                name="CORRESPONDANCE_ADDRESS"
                                fullWidth
                                label="Correspondance Address"
                                autoComplete="correspondance-address"
                                value={employeeState?.CORRESPONDANCE_ADDRESS}
                                onChange={(event) => setEmployeeState({ ...employeeState, CORRESPONDANCE_ADDRESS: event.target.value })}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} style={{ display: "flex" }}>
                            <TextField
                                id="imageName"
                                name="ImageName"
                                label="Photo"
                                required
                                fullWidth
                                disabled
                                value={employeeState?.PHOTO_URL}
                                error={employeeErrors?.PHOTO_URL ? true : false}
                                helperText={employeeErrors?.PHOTO_URL}
                                InputLabelProps={{ shrink: employeeState?.PHOTO_URL && true }}
                            />

                            <input
                                accept="image/*"
                                id="PHOTO_URL"
                                type="file"
                                className={classes.input}
                                onChange={(event) => {
                                    setUploadSuccess(false);
                                    uploadImagetoImgBB(event.target.files[0]);
                                }}
                            />

                            <label htmlFor="PHOTO_URL">
                                <div className={classes.wrapper}>
                                    <Fab variant="extended" component="span" size="small" className={uploadSuccessClass}>
                                        {uploadSuccess ? <CheckIcon className={classes.extendedIcon} /> : <PhotoCamera className={classes.extendedIcon} />}
                                        {uploadSuccess ? "Success" : "Browse"}
                                    </Fab>
                                    {uploading && <CircularProgress size={40} className={classes.fabProgress} />}
                                </div>
                            </label>
                        </Grid>

                        {employeeState?.PHOTO_URL && <Grid item xs={12} sm={6}>
                            <CardMedia
                                className={classes.media}
                                image={employeeState?.PHOTO_URL}
                                title="Employee Image"
                            />
                        </Grid>
                        }

                        <Grid item xs={12} sm={12}>
                            <Fab
                                variant="extended"
                                component="span"
                                size="small"
                                onClick={() => setOpenDocumentsDialog(true)}
                            >
                                <BurstModeIcon className={classes.extendedIcon} />
                            Documents
                        </Fab>
                        </Grid>

                        {/* loop through all the documents */}
                        <Grid item xs={12} sm={12}>
                            <div className={classes.documents}>
                                <GridList className={classes.gridList} cols={2.5}>
                                    {employeeState?.DOCUMENTS.map((document, index) => (
                                        <GridListTile key={index}>
                                            <img src={document.URL} alt={document.TYPE} />
                                            <GridListTileBar
                                                title={document.TYPE}
                                                classes={{
                                                    root: classes.docTitleBar,
                                                    title: classes.docTitle,
                                                }}
                                            />
                                        </GridListTile>
                                    ))}
                                </GridList>
                            </div>
                        </Grid>

                    </Grid>
                </Paper>
            </div>
        </Dialog>


        {/* documents dialog */}
        <ImgbbUpload
            openDocumentsDialog={openDocumentsDialog}
            handleCloseDocumentsDialog={handleCloseDocumentsDialog}
            transaction={transaction}
            docs={employeeState?.DOCUMENTS}
            uploadDocuments={uploadDocuments}
        />

    </div>
}

export default EmployeeForm;