import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { NavLink } from 'react-router-dom';
import moment from "moment";

import Title from '../Title';

const useStyles = makeStyles({
    depositContext: {
        flex: 1,
    },
});

const Amount = ({ amount }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Title>Total Amount Spent</Title>
            <Typography component="p" variant="h4">
                ₹ {amount[0]?.TOTAL_AMOUNT}
            </Typography>
            <Typography color="textSecondary" className={classes.depositContext}>
                in {moment().format("MMMM YYYY")}
            </Typography>

            <div style={{ marginTop: "30%" }}>
                <NavLink
                    to={`/home/analysis`}
                    style={{ color: "primary", textDecoration: "none" }}
                >
                    View details
                </NavLink>
            </div>
        </React.Fragment>
    );
}

export default Amount;