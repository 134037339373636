import moment from "moment";

// get the production server api based on day
// Render.com servers
export const getAPI = () => {
    const day = moment().format("DD");
    if (day <= 10)
        return "https://chintamani-infra-prod-api-1.onrender.com/chintamani-infra/api";
    else if (day > 10 && day <= 20)
        return "https://chintamani-infra-prod-api-2.onrender.com/chintamani-infra/api";
    else
        return "https://chintamani-infra-prod-api-3.onrender.com/chintamani-infra/api";
}

/* Heroku Servers
export const getAPI = () => {
    const day = moment().format("DD");
    if (day <= 10)
        return "https://chintamani-infra-prod-api-11.herokuapp.com/chintamani-infra/api";
    else if (day > 10 && day <= 20)
        return "https://chintamani-infra-prod-api-22.herokuapp.com/chintamani-infra/api";
    else
        return "https://chintamani-infra-prod-api-33.herokuapp.com/chintamani-infra/api";
}
 */
/* // get the development server api based on day
export const getAPI = () => {
    const day = moment().format("DD");
    if (day <= 10)
        return "https://chintamani-infra-qa-api.herokuapp.com/chintamani-infra/api";
    else if (day > 10 && day <= 20)
        return "https://chintamani-infra-qa-api.herokuapp.com/chintamani-infra/api";
    else
        return "https://chintamani-infra-qa-api.herokuapp.com/chintamani-infra/api";
} */