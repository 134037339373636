import jwt from "jsonwebtoken";

export const isValidSession = async () => {
    let valid = false;
    const authToken = localStorage.getItem('AUTH_TOKEN');
    // console.log('authToken::', authToken);
    if (authToken) {
        const decodedToken = jwt.decode(authToken, { complete: true });
        // console.log('decodedToken::', decodedToken);
        if (decodedToken) {
            // send a request to server to validate the token
            // ...
            valid = true
        }
    }

    return valid;
};
