import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useLazyQuery, useMutation } from '@apollo/client';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemText, Snackbar, Tooltip } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreIcon from '@material-ui/icons/More';
import { MuiThemeProvider } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { SEARCH_EMPLOYEES } from "../../../../graphql/queries";
import { EMPLOYEES_CRUD } from "../../../../graphql/mutations";
import { globalTableOptions, globalTableTheme } from "../../../../theme";
import Loader from "../../../../components/Loader";
import EmployeeForm from "../EmployeeForm";
import ImageGrid from "../../../../components/Images/ImagesGrid";

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const EmployeeList = () => {
    // apollo queries and mutations
    const [getEmployeeList, { loading, data, error, refetch: refetchEmployeeList }] = useLazyQuery(SEARCH_EMPLOYEES);
    const [EmployeesCRUD] = useMutation(EMPLOYEES_CRUD);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [imageDialogOpen, setImageDialogOpen] = useState(false);
    const [images, setImages] = useState([]);
    const [formDialogOpen, setFormDialogOpen] = useState(false);
    const [transaction, setTransaction] = useState("");
    const [employee, setEmployee] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [employeeID, setEmployeeID] = useState("");
    const [deleting, setDeleting] = useState(false);
    const [viewDialogOpen, setViewDialogOpen] = useState(false);

    // inital effect
    useEffect(() => {
        getEmployeeList();
    }, [getEmployeeList]);

    const handleImageDialogClose = () => {
        setImageDialogOpen(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    };

    const handleFormDialogClose = () => {
        setFormDialogOpen(false);
    };

    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    const handleViewDialogClose = () => {
        setViewDialogOpen(false);
    };

    if (error) {
        setSnackbarSeverity("error");
        setSnackbarMessage(error);
        setOpenSnackbar(true);
    };

    const handleEmployeesCRUD = () => {
        setFormDialogOpen(false);
        //setEmployee(null);
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
        setSnackbarMessage(`Employee ${transaction === 'CREATE' ? 'added' : 'updated'} successfully!`);
        refetchEmployeeList();
    };

    const deleteEmployee = () => {
        setDeleting(true);
        EmployeesCRUD({
            variables: {
                "transaction": "LOGICAL_DELETE",
                "employees": [{
                    "EMPLOYEE_ID": employeeID,
                }]
            }
        })
            .then(resp => {
                // const result = resp.data.result;
                // console.log('[DELETE EMPLOYEE] Resp: ', result);
                setDeleteDialogOpen(false);
                setSnackbarSeverity("success");
                setOpenSnackbar(true);
                setSnackbarMessage("Employee removed successfully!");
                refetchEmployeeList();
                setDeleting(false);
            })
            .catch(error => {
                console.log('[DELETE EMPLOYEE] Error:', error.message);
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
                setSnackbarMessage(JSON.parse(error.message)["0"].errorEMPLOYEE_ID);
                setDeleting(false);
            });
    };

    const tableColumns = [
        {
            name: "EMPLOYEE_ID",
            label: "Employee ID",
            options: {
                filter: false,
                sort: true,
                display: false
            }
        }, {
            name: "FIRST_NAME",
            label: "First Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "LAST_NAME",
            label: "Last Name",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "HIRE_DATE_FRMT",
            label: "Joining Date",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "EMPLOYEE_STATUS",
            label: "Employee Status",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "EMPLOYMENT_TYPE",
            label: "Employment Type",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "EMPLOYEE_ROLE",
            label: "Employee Role",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "SEATING_LOCATION_DESC",
            label: "Seating Location",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "EXPERIENCE_IN_YEARS",
            label: "Experience(in years)",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "SALARY",
            label: "Salary",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "EMAIL",
            label: "Email",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "MOBILE_NUMBER",
            label: "Mobile Number",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "BIRTH_DATE_FRMT",
            label: "Birth Date",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "MARITAL_STATUS",
            label: "Marital Status",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "GENDER",
            label: "Gender",
            options: {
                filter: false,
                sort: true,
            }
        }, {
            name: "ACTIONS",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                print: false,
                download: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    //const tableData = tableMeta.tableData;
                    const tableData = data?.result;
                    const rowIndex = tableMeta.rowIndex;
                    // console.log('Selcted tableMeta: ', tableMeta);
                    return <>
                        <Tooltip title="View Photo">
                            <IconButton
                                style={{ color: "#17A2B8" }}
                                onClick={() => {
                                    setImages([{ URL: tableData[rowIndex].PHOTO_URL, TYPE: "Photo" }]);
                                    setImageDialogOpen(true);
                                }}
                            >
                                <PhotoLibraryIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View Documents">
                            <IconButton
                                style={{ color: "#ffc107" }}
                                onClick={() => {
                                    setImages(JSON.parse(tableData[rowIndex].DOCUMENTS));
                                    setImageDialogOpen(true);
                                }}
                            >
                                <FileCopyIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit">
                            <IconButton
                                style={{ color: "#343a40" }}
                                onClick={() => {
                                    setEmployee(tableData[rowIndex]);
                                    setTransaction('UPDATE');
                                    setFormDialogOpen(true);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton
                                style={{ color: "#dc3545" }}
                                onClick={() => {
                                    setEmployeeID(tableData[rowIndex].EMPLOYEE_ID);
                                    setDeleteDialogOpen(true);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="View More">
                            <IconButton
                                style={{ color: "#28a745" }}
                                onClick={() => {
                                    setEmployee(tableData[rowIndex]);
                                    setViewDialogOpen(true);
                                }}
                            >
                                <MoreIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            }
        },
    ];

    let tableData = [];
    if (data) {
        //console.log('Empoyee list', data.result);
        tableData = data.result;
    }

    const tableOptions = {
        ...globalTableOptions,
        downloadOptions: {
            filename: "employee-list.csv"
        },
    };

    return <div style={{ position: "relative" }}>
        {/* loader */}
        {(loading) && <Loader />}

        {/* add new employee */}
        <Tooltip title="Add New">
            <Fab
                size="small"
                //color="#4caf50" 
                aria-label="add"
                onClick={() => {
                    setEmployee(null);
                    setTransaction('CREATE');
                    setFormDialogOpen(true);
                }}
                style={{ float: "right", marginTop: "12px", marginRight: "12px", color: "#FFFFFF", backgroundColor: "#4caf50" }}
            >
                <AddIcon />
            </Fab>
        </Tooltip>

        {/* data table */}
        <MuiThemeProvider theme={globalTableTheme}>
            <MUIDataTable
                title={"Employees"}
                data={tableData}
                columns={tableColumns}
                options={tableOptions}
            />
        </MuiThemeProvider>

        {/* snackbar */}
        <Snackbar
            open={openSnackbar}
            autoHideDuration={5000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleSnackbarClose}
        >
            <Alert severity={snackbarSeverity} onClose={handleSnackbarClose}>
                {snackbarMessage}
            </Alert>
        </Snackbar>

        {/* View Images Dialog */}
        <ImageGrid
            imageDialogOpen={imageDialogOpen}
            handleImageDialogClose={handleImageDialogClose}
            images={images}
        />

        {/* create/update form */}
        <EmployeeForm
            formDialogOpen={formDialogOpen}
            handleFormDialogClose={handleFormDialogClose}
            handleEmployeesCRUD={handleEmployeesCRUD}
            transaction={transaction}
            employee={employee}
        />

        {/* delete action dialog */}
        <Dialog
            open={deleteDialogOpen}
            onClose={handleDeleteDialogClose}
            aria-labelledby="delete-employee"
            aria-describedby="delete-dialog-description"
        >
            <div style={{ position: "relative" }}>
                {(deleting) && <Loader />}
                <DialogTitle id="delete-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        The employee will be deleted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} variant="outlined" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={deleteEmployee} variant="contained" color="secondary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </div>
        </Dialog>

        {/* view dialog */}
        {viewDialogOpen && <Dialog
            open={viewDialogOpen}
            onClose={handleViewDialogClose}
            aria-labelledby="view-employee"
            aria-describedby="view-dialog-description"
        >
            <DialogTitle id="view-dialog-title">{"More details"}</DialogTitle>
            <DialogContent>
                <List component="div" role="list">
                    <ListItem divider role="listitem">
                        <ListItemText primary={employee?.PERMANENT_ADDRESS} secondary="Permanent address" />
                    </ListItem>
                    <ListItem divider role="listitem">
                        <ListItemText primary={employee?.CORRESPONDANCE_ADDRESS} secondary="Correspondance address" />
                    </ListItem>
                    <ListItem divider role="listitem">
                        <ListItemText primary={employee?.ENTITLED_LEAVES || 0} secondary="Entitled leaves" />
                    </ListItem>
                    {employee?.EMPLOYEE_STATUS !== 'Active' ? <ListItem divider role="listitem">
                        <ListItemText primary={employee?.RELIEVING_DATE_FRMT} secondary="Relieving Date" />
                    </ListItem> : null}
                    <ListItem divider role="listitem">
                        <ListItemText primary={employee?.CREATED_BY} secondary="Added by" />
                    </ListItem>
                    <ListItem divider role="listitem">
                        <ListItemText primary={employee?.CREATE_DATE_FRMT} secondary="Added on" />
                    </ListItem>
                </List>
            </DialogContent>
        </Dialog>}

    </div>
}

export default EmployeeList;